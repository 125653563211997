import React from "react";
import { Box, Button } from "@material-ui/core";

interface NavigationButtonsProps {
    prevDisabled: boolean,
    nextDisabled: boolean,
    onPrevClick: () => void,
    onNextClick: () => void,
}

export const NavigationButtons = ({ prevDisabled, nextDisabled, onPrevClick, onNextClick }: NavigationButtonsProps) => {
    return (
        <Box width='100%' marginTop={2} marginBottom={2} display='flex' justifyContent='space-between'>
            <Box marginRight={2}>
                <Button
                    size='small'
                    disabled={prevDisabled}
                    onClick={onPrevClick}
                >Prev</Button>
            </Box>
            <Button
                size='small'
                disabled={nextDisabled}
                onClick={onNextClick}
            >Next</Button>
        </Box>
    )
}
