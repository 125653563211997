import { Theme, createStyles, makeStyles } from "@material-ui/core";
import React, { ForwardedRef, ReactNode } from "react";
import cx from "classnames"

export interface CellProps {
    children?: ReactNode,
    isLarge?: boolean,
    width?: number,
    isContentCentered?: boolean,
    isColumn?: boolean,
}

export const Cell = React.forwardRef(({
    children,
    isLarge,
    width,
    isContentCentered,
    isColumn,
}: CellProps, ref: ForwardedRef<any>) => {
    const { cell, large, flexible, notFlexible, center, column } = useStyles(width)()
    const classes = cx(cell , {
        [large]: isLarge,
        [flexible]: !width,
        [notFlexible]: !!width,
        [center]: isContentCentered,
        [column]: isColumn,
    })

    return <div ref={ref} style={{ fontSize: '0.875rem' }} className={classes}>{children}</div>
})


const useStyles = (size?: number) => makeStyles((_theme: Theme) =>
    createStyles({
        cell: {
            display: 'flex',
            alignItems: 'center',
            padding: '6px 16px',
            wordBreak: 'break-word'
        },
        large: {
            padding: '16px'
        },
        flexible: {
            flex: '1 0'
        },
        notFlexible: {
            minWidth: size+'%',
            maxWidth: size+'%'
        },
        center: {
            justifyContent: 'center'
        },
        column: {
            flexDirection: 'column'
        }
    }),
);
