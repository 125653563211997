import React, { ForwardedRef, ReactNode, useContext } from "react";
import { HalFormsProperty, HalFormsTemplate } from "./api";
import { ValidatedTextInputProps } from "./ValidatedTextInput";

const HalFormContext = React.createContext<HalFormsTemplate<any> | null>(null);

interface FormProps {
    readonly template: HalFormsTemplate<any> | null;
    readonly children: ReactNode;
}

export function Form(props: FormProps) {
    return <HalFormContext.Provider value={props.template} children={props.children} />
}

export function useHalProperty(propertyName: string): HalFormsProperty {
        const halForm = useContext(HalFormContext);
        if(halForm == null) {
            throw new Error("No HAL Form in context");
        }
        return halForm.property(propertyName);
}

interface ExpectedHalInputProps {
    readonly disabled?: boolean;
}

interface WrappedHalInputProps extends ExpectedHalInputProps {
    readonly name: string;
}

type WrappedInputComponent<T> = React.ComponentType<WrappedHalInputProps & T>

export function withHalProperty<T extends {}>(Component: React.ComponentType<ExpectedHalInputProps & T>): WrappedInputComponent<T> {
    const WrappedInputComponent = React.forwardRef((props: WrappedHalInputProps & T, ref: ForwardedRef<any>) => {
        const halProp = useHalProperty(props.name);
        return <Component ref={ref} {...props} disabled={props.disabled || !halProp.enabled} />
    })

    return WrappedInputComponent as unknown as WrappedInputComponent<T>;
}

type WrappedExtraProps = {
    name: string;
}
type WrappedValidatedTextInputProps = Omit<ValidatedTextInputProps, 'halProperty'> & WrappedExtraProps;

export function inheritHalProperty(Component: React.ComponentType<ValidatedTextInputProps>): React.ComponentType<WrappedValidatedTextInputProps> {
    const WrappedComponent = React.forwardRef((props: WrappedValidatedTextInputProps, ref: ForwardedRef<any>) => {
        const { name, ...rest } = props;
        const halProperty = useHalProperty(name);
        return <Component ref={ref} {...rest} halProperty={halProperty} />
    });
    return WrappedComponent;
}

interface ExpectedHalOptionsProps {
    readonly children?: ReactNode
}

interface ExpectedOptionComponentProps {
    value: string;
    prompt: string;
}

interface WrappedHalOptionsProps {
    readonly name: string;
}

export function withHalOptions<T extends {}>(
    Component: React.ComponentType<ExpectedHalOptionsProps & T>,
    OptionComponent: React.ComponentType<ExpectedOptionComponentProps>,
    withEmptyOption?: boolean
): React.ComponentType<WrappedHalOptionsProps & T> {
    const WrappedOptionsComponent = React.forwardRef((props: WrappedHalOptionsProps & T, ref: ForwardedRef<any>) => {
        const halProp = useHalProperty(props.name);

        return <Component ref={ref} {...props}>
            {withEmptyOption ? <OptionComponent value='' prompt="" /> : null}
            {halProp.options.map((option) => <OptionComponent key={option.value} value={option.value} prompt={option.prompt} />)}
        </Component>

    })
    return WrappedOptionsComponent as unknown as React.ComponentType<WrappedHalOptionsProps & T>;
}
