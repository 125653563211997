import { itemTags, tags, wrapPromise } from "../../repository/rtk-query";
import fetcher from "../../repository";
import { Zone } from "../../repository/models/Zone";
import { IamRealm } from "../../repository/models/IamRealm";
import { IamCreateUser, IamUser, IamUserGroup, IamUserGroupList, IamUserList, IamEditUser } from "../../repository/models/IamUser";
import api from "../../store/api";
import { IamAttribute, IamAttributeList, IamCreateAttribute } from "../../repository/models/IamAttribute";
import { IamCreateGroup, IamGroup, IamGroupList, IamGroupMember, IamGroupMemberList } from "../../repository/models/IamGroup";
import { TypedLink } from "../../hal";

const iamApi = api.injectEndpoints({
    endpoints: build => ({
        getRealms: build.query({
            queryFn: (zone: Zone) => wrapPromise(fetcher.getRealms(zone)),
            providesTags: (realms, _error, zone) => tags("IamRealm")
                .listFor(zone)
                .withItems(realms)
                .toArray()

        }),
        getRealmById: build.query({
            queryFn: (realmId: string) => wrapPromise(fetcher.getRealm(realmId)),
            providesTags: (realm) => tags("IamRealm")
                .item(realm)
                .toArray()
        }),
        getRealmOverview: build.query({
            queryFn: (realm: IamRealm) => wrapPromise(fetcher.getRealmOverview(realm)),
            providesTags: [] // deliberately no tags, should update whenever we view the page.
        }),
        getRealmUsers: build.query({
            queryFn: (link: TypedLink<IamUserList>) => wrapPromise(fetcher.getRealmUsers(link)),
            providesTags: (users, _error, _link) => [
                ...users ? tags("IamUsers")
                .listFor(users._links.self.href)
                .withItems(users?._embedded?.users)
                .toArray() : []
            ]
        }),
        getRealmUserById: build.query({
            queryFn: (args: {realm: IamRealm, userId: string}) => wrapPromise(fetcher.getRealmUser(args.realm, args.userId)),
            providesTags: (user) => [
                ...itemTags("IamUsers", user)
            ]
        }),

        getRealmUserGroups: build.query({
            queryFn: (user: IamUser) => wrapPromise(fetcher.getRealmUserGroups(user)),
            providesTags: (groups, _error) => [
            ...groups ? tags("IamUserGroups")
                .listFor(groups?._links.self.href)
                .withItems(groups?._embedded?.groups)
                .toArray() : []
            ]
        }),
        joinRealmGroups: build.mutation<void, {groupList: IamUserGroupList, groups: TypedLink<IamGroup>[]}>({
            queryFn: ({groupList, groups}) => wrapPromise(fetcher.joinRealmGroups(groupList, groups)),
            invalidatesTags: (_list, _error, {groupList}) => tags("IamUserGroups")
                .listFor(groupList._links.self.href)
                .toArray()
        }),
        createRealmUser: build.mutation<void, {templateUser: IamUserList, user: IamCreateUser}>({
            queryFn: ({templateUser, user}) => wrapPromise(fetcher.createRealmUser(templateUser, user)),
            invalidatesTags: (_list, _error, {templateUser}) => tags("IamUsers")
                .listFor(templateUser._links.self.href)
                .toArray()
        }),
        removeRealmGroupMember: build.mutation<void, IamGroupMember>({
            queryFn: (member) => wrapPromise(fetcher.removeRealmGroupMember(member)),
            invalidatesTags: (_group, _error, member) => tags("IamGroupMembers")
                .item(member)
                .toArray()
        }),
        leaveRealmGroup: build.mutation<void, IamUserGroup>({
            queryFn: (group) => wrapPromise(fetcher.leaveRealmGroup(group)),
            invalidatesTags: (_user, _error, group) => tags("IamUserGroups")
                .item(group)
            .toArray()
        }),
        editRealmUser: build.mutation<void, {templateUser: IamUser, user: IamEditUser}>({
            queryFn: ({templateUser, user}) => wrapPromise(fetcher.editRealmUser(templateUser, user)),
            invalidatesTags: (_void, _error, {templateUser}) => [
            ...itemTags("IamUsers", templateUser)
            ]
        }),
        getRealmGroups: build.query({
            queryFn: (link: TypedLink<IamGroupList>) => wrapPromise(fetcher.getRealmGroups(link)),
            providesTags: (groups) => [
                ...groups ? tags("IamGroups")
                .listFor(groups._links.self.href)
                .withItems(groups?._embedded?.groups)
                .toArray() : []
            ]
        }),
        getRealmGroupById: build.query({
            queryFn: (args: {realm: IamRealm, groupId: string}) => wrapPromise(fetcher.getRealmGroup(args.realm, args.groupId)),
            providesTags: (group) => [
                ...itemTags("IamGroups", group)
            ]
        }),
        getRealmGroupMembers: build.query({
            queryFn: (group: IamGroup) => wrapPromise(fetcher.getRealmGroupMembers(group)),
            providesTags: (members, _error) => [
            ...members ? tags("IamGroupMembers")
                .listFor(members?._links.self.href)
                .withItems(members?._embedded?.members)
                .toArray() : []
            ]
        }),
        addRealmGroupMembers: build.mutation<void, {memberList: IamGroupMemberList, users: IamUser[]}>({
            queryFn: ({memberList, users}) => wrapPromise(fetcher.addRealmGroupMembers(memberList, users)),
            invalidatesTags: (_list, _error, {memberList}) => tags("IamGroupMembers")
                .listFor(memberList._links.self.href)
                .toArray()
        }),
        createRealmGroup: build.mutation<void, {templateGroup: IamGroupList, group: IamCreateGroup}>({
            queryFn: ({templateGroup, group}) => wrapPromise(fetcher.createRealmGroup(templateGroup, group)),
            invalidatesTags: (_list, _error, {templateGroup}) => tags("IamGroups")
                .listFor(templateGroup._links.self.href)
                .toArray()

        }),
        deleteRealmGroup: build.mutation<void, IamGroup>({
            queryFn: (group) => wrapPromise(fetcher.deleteRealmGroup(group)),
            invalidatesTags: (_list, _error, group) => tags("IamGroups")
                .item(group)
                .toArray()
        }),
        editRealmGroup: build.mutation<void, {templateGroup: IamGroup, group: IamCreateGroup}>({
            queryFn: ({templateGroup, group}) => wrapPromise(fetcher.editRealmGroup(templateGroup, group)),
            invalidatesTags: (_void, _error, {templateGroup}) => [
                ...itemTags("IamGroups", templateGroup)
            ]
        }),
        getRealmAttributes: build.query({
            queryFn: (realm: IamRealm) => wrapPromise(fetcher.getRealmAttributes(realm)),
            providesTags: (attributes, _error, realm) => [ 
                ...tags("IamAttributes")
                .listFor(realm)
                .withItems(attributes?._embedded?.attributes)
                .toArray(),
                ...attributes ? tags("IamAttributes")
                .listFor(attributes._links.self.href)
                .toArray() : []
            ]
        }),
        createRealmAttribute: build.mutation<void, {templateAttribute: IamAttributeList, attribute: IamCreateAttribute}>({
            queryFn: ({templateAttribute, attribute}) => wrapPromise(fetcher.createRealmAttribute(templateAttribute, attribute)),
            invalidatesTags: (_list, _error, {templateAttribute}) => [
                { type: "IamGroups" },
                ...tags("IamAttributes")
                    .listFor(templateAttribute._links.self.href)
                    .toArray()
            ]
        }),
        deleteRealmAttribute: build.mutation<void, IamAttribute>({
            queryFn: (attr) => wrapPromise(fetcher.deleteRealmAttribute(attr)),
            invalidatesTags: (_list, _error, attribute) => [
                { type: "IamGroups" },
                ...tags("IamAttributes")
                    .item(attribute)
                    .toArray()
            ]
        }),
        manageRealm: build.mutation<IamRealm, IamRealm>({
            queryFn: (realm) => wrapPromise(fetcher.manageRealm(realm))
        }),
        resetRealmUserPassword: build.mutation<void, IamUser>({
            queryFn: (user) => wrapPromise(fetcher.resetIamRealmUserPassword(user))
        }),
        renameIamRealm: build.mutation<void, {realm: IamRealm, newName: string}>({
            queryFn: ({realm, newName}) => wrapPromise(fetcher.renameIamRealm(realm, newName)),
            invalidatesTags: (_list, _error, {realm}) => tags("IamRealm")
                .item(realm)
                .toArray()
        })
    })
})

export const {
    useGetRealmsQuery,
    useGetRealmByIdQuery,
    useGetRealmOverviewQuery,
    useGetRealmUsersQuery,
    useCreateRealmUserMutation,
    useRemoveRealmGroupMemberMutation,
    useLeaveRealmGroupMutation,
    useEditRealmUserMutation,
    useGetRealmGroupsQuery,
    useGetRealmUserByIdQuery,
    useGetRealmUserGroupsQuery,
    useJoinRealmGroupsMutation,
    useGetRealmGroupByIdQuery,
    useGetRealmGroupMembersQuery,
    useAddRealmGroupMembersMutation,
    useCreateRealmGroupMutation,
    useDeleteRealmGroupMutation,
    useEditRealmGroupMutation,
    useGetRealmAttributesQuery,
    useCreateRealmAttributeMutation,
    useDeleteRealmAttributeMutation,
    useManageRealmMutation,
    useResetRealmUserPasswordMutation,
    useRenameIamRealmMutation,
} = iamApi;
