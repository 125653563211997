import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Blueprint } from "../../repository/models/Blueprint";
import { Organization } from "../../repository/models/Organization";
import { Project } from "../../repository/models/Project";
import { AppState } from "../../store";
import Breadcrumbs from "../../ui/Breadcrumbs";
import Link from "../../ui/Link";
import { selectCurrentOrganization } from "../account/accountSlice";
import { selectCurrentBlueprint } from "../blueprint/blueprintSlice";
import { selectCurrentProject } from "../project/projectSlice";
import { BLUEPRINT_ROOT, IAM_ROOT, ORGANIZATION_DASHBOARD, ORGANIZATION_ROOT, PROJECT_BLUEPRINTS, PROJECT_ROOT, RELEASES_ROOT, RouteData } from "./routes";
import { Route } from "./_internal";
import { ChildrenRoutesOption, PathMatchRequiredVariables } from "./_internal/types";

type AutoBreadcrumbsProps = {
    currentOrg: Organization|null,
    currentProject: Project|null,
    currentBlueprint: Blueprint|null,
    children: ReactNode,
    subtitle?: ReactNode,
    collapseDefaults?: boolean,
}

function useSubMatch<V extends string>(route: Route<string, V, RouteData>): PathMatchRequiredVariables<V> | null {
    const location = useLocation();
    const wildcardMatch = route.wildcard.match(location.pathname);
    const directMatch = route.match(location.pathname);

    if(directMatch) {
        return null;
    }

    const hasChildInTab = route.routes({ children: ChildrenRoutesOption.ALL })
        // Check the child routes that are tabs of our requested route
        .filter(childRoute => childRoute.data.isTabIn === route)
        // If any of those routes match our location, we are in a tab
        .some(childRoute => childRoute.match(location.pathname))

    if (hasChildInTab) {
        // If we are in a tab, we don't want to expose our current breadcrumb, as breadcrumbs are above the tabs
        return null;
    }

    return wildcardMatch;

}

function AutoBreadcrumbs(props: AutoBreadcrumbsProps) {
    const location = useLocation();
    const orgMatch = useSubMatch(ORGANIZATION_ROOT);
    const projectMatch = useSubMatch(PROJECT_ROOT);
    const blueprintMatch = useSubMatch(BLUEPRINT_ROOT);
    const releaseMatch = useSubMatch(RELEASES_ROOT);
    const iamMatch = useSubMatch(IAM_ROOT);

    const numChildren = React.Children.count(props.children);

    return <Breadcrumbs maxItems={props.collapseDefaults?numChildren:undefined} itemsBeforeCollapse={0} itemsAfterCollapse={numChildren} subtitle={props.subtitle}>
        {orgMatch &&
                <Link to={ORGANIZATION_ROOT.wildcard.navigate(location.pathname, ORGANIZATION_DASHBOARD)!}>
                    <strong>{props.currentOrg?.display_name ?? orgMatch.params.org}</strong>
                </Link>}
        {projectMatch &&
            <Link to={PROJECT_ROOT.wildcard.navigate(location.pathname, PROJECT_BLUEPRINTS)!}>
                <strong>{props.currentProject?.name ?? projectMatch.params.project}</strong>
            </Link>
        }
        {blueprintMatch &&
            <Link to={BLUEPRINT_ROOT.wildcard.navigate(location.pathname, BLUEPRINT_ROOT)!}>
                <strong>{props.currentBlueprint?.name ?? blueprintMatch.params.blueprint}</strong>
            </Link>}
        {releaseMatch &&
            <Link to={RELEASES_ROOT.wildcard.navigate(location.pathname, RELEASES_ROOT)!}>
                <strong>Releases</strong>
            </Link>}
        {iamMatch &&
            <Link to={IAM_ROOT.wildcard.navigate(location.pathname, IAM_ROOT)!}>
                <strong>IAM</strong>
            </Link>}
        {props.children}
    </Breadcrumbs>
}

export default connect((state: AppState) => ({
    currentOrg: selectCurrentOrganization(state),
    currentProject: selectCurrentProject(state),
    currentBlueprint: selectCurrentBlueprint(state)
}))(AutoBreadcrumbs);
