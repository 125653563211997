import React, { ReactNode } from "react";
import { Button, Typography, Box } from "@material-ui/core";

import { Variant } from "@material-ui/core/styles/createTypography";

interface ModifiableListProps {
    children: ReactNode,
    title?: string,
    customTitle?: ReactNode,
    titleVariant?: Variant,
    buttonText?: string,
    onButtonClick?: () => void,
}

export const ModifiableList = ({ children, title, customTitle, titleVariant = 'h6', buttonText, onButtonClick }: ModifiableListProps) => {
    return (
        <Box mb={4}>
            <Box mb={2}>
                {!!title ? <Typography variant={titleVariant}>{title}</Typography> : <></>}

                {!!customTitle ? customTitle : <></>}
            </Box>

            {children}

            {!!buttonText && (
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onButtonClick}
                >
                    {buttonText}
                </Button>
            )}
        </Box>
    )
}
