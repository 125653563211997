import { itemTags, listTags, wrapPromise } from "../../repository/rtk-query";
import { Blueprint } from "../../repository/models/Blueprint";
import { Project } from "../../repository/models/Project";
import fetcher from "../../repository";
import { Release } from "../../repository/models/Release";
import { Changeset } from "../../repository/models/Changeset";
import { TypedLink } from "../../hal";
import api from "../../store/api";

const releasesApi = api.injectEndpoints({
    endpoints: build => ({
        getReleasesForProject: build.query<ReadonlyArray<Release>, Project>({
            queryFn: (project) => wrapPromise(fetcher.getReleasesForProject(project)),
            providesTags: (releases) => listTags("Release", releases)
        }),
        getReleasesForBlueprint: build.query<ReadonlyArray<Release>, Blueprint>({
            queryFn: (blueprint) => wrapPromise(fetcher.getReleasesForBlueprint(blueprint)),
            providesTags: (releases) => listTags("Release", releases)
        }),
        getOpenApiSpecForRelease: build.query<string, Release>({
            queryFn: (release) => wrapPromise(fetcher.getOpenApiSpecForRelease(release)),
            providesTags: (_response, _error, release) => [
                { type: "Release", id: release._links.self.href }
            ],
        }),
        getDownloadUrlForChangeset: build.query<string, TypedLink<Changeset>>({
            queryFn: (changeset) => wrapPromise(fetcher.getDownloadUrlForChangeset(changeset)),
            providesTags: (_url, _error, changeset) => [
                { type: "Changeset", id: changeset }
            ]
        }),
        addRelease: build.mutation<Release, {blueprint: Blueprint, changeset: TypedLink<Changeset>, label: string, message?:string}>({
            queryFn: ({blueprint, changeset, label, message}) => wrapPromise(fetcher.addRelease(blueprint, changeset, label, message)),
            invalidatesTags: (_release, _error, {blueprint}) => [
                { type: "Release", id: "LIST" },
                ...itemTags("Blueprint", blueprint)
            ]
        })
    }),
})

export const {
    useGetReleasesForBlueprintQuery,
    useGetReleasesForProjectQuery,
    useGetOpenApiSpecForReleaseQuery,
    useGetDownloadUrlForChangesetQuery,
    useAddReleaseMutation,
} = releasesApi;
