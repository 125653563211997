import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { TypedLink } from "../../../hal";
import { Changeset } from "../../../repository/models/Changeset";
import { Release } from "../../../repository/models/Release";
import { RequestStateHandler } from "../../../ui/RequestStateHandler";
import { useGetChangesetAncestryQuery, useGetChangesetQuery } from "../../application/api";
import { Box } from "@material-ui/core";
import { Row } from "../../../ui/table/Row";
import { Cell } from "../../../ui/table/Cell";
import { TableHeader } from "../../../ui/typography/TableHeader";

interface ReleaseChangesProps {
    readonly release: Release;
    /**
     * The base changeset to look until for determining changes in this release
     *
     * Note that there is a difference between null and undefined here:
     * `undefined` (or property absent) means that just the changes in this release will be shown.
     * `null` means that all changes from this release until the initial changeset will be shown.
     */

    readonly baseChangeset?: Changeset | TypedLink<Changeset> | null;
    isTable?: boolean
}

export default function ReleaseChanges({ release, baseChangeset, isTable }: ReleaseChangesProps) {
    const { data: changeset, isLoading, error } = useGetChangesetQuery(release._links.changeset.href);
    const isCurrentReleaseOnly = baseChangeset === undefined;
    const { currentData: ancestry, isFetching: ancestryIsLoading, error: ancestryError } = useGetChangesetAncestryQuery(!isCurrentReleaseOnly ? {
        changeset: changeset ?? release._links.changeset.href,
        untilAncestor: typeof baseChangeset === "string" ? baseChangeset : baseChangeset?._links.self.href
    } : skipToken);

    const { currentData: reverseAncestry, isFetching: reverseAncestryIsLoading, error: reverseAncestryError } = useGetChangesetAncestryQuery(baseChangeset ? {
        changeset: typeof baseChangeset === "string" ? baseChangeset : baseChangeset._links.self.href,
        untilAncestor: release._links.changeset.href
    } : skipToken);

    if(isLoading || ancestryIsLoading || reverseAncestryIsLoading || !!error || !!ancestryError || !!reverseAncestryError){
        return (
            <RequestStateHandler
                isLoading={isLoading || ancestryIsLoading || reverseAncestryIsLoading}
                error={error ?? ancestryError ?? reverseAncestryError}
            />
        )
    }

    if(isCurrentReleaseOnly && isTable && !!changeset){
        return <ChangesTable changeset={changeset} />
    }

    return <>
        {(isCurrentReleaseOnly ? [changeset] : (ancestry ?? [])).map(cs =>
            <ul key={cs?._links.self.href}>
                {(cs?.operations ?? []).map((operation, i) => <li key={i}>{operation.description}</li>)}
            </ul>
        )}
        {(reverseAncestry ?? []).map(cs =>
            <ul key={cs?._links.self.href}>
                {(cs?.operations ?? []).map((operation, i) => <li key={i}><b>Revert</b>{" "+operation.description}</li>)}
            </ul>
        )}
    </>
}

const ChangesTable = ({changeset}: {changeset: Changeset}) => {
    return (
        <Box>
            <Row borders='horizontal'>
                <TableHeader>Description</TableHeader>
            </Row>
            {(changeset?.operations ?? []).map((operation, i) => {
                return !operation.properties?.hidden ? (
                    <Row key={i}>
                        <Cell>&#x25CF; {operation.description}</Cell>
                    </Row>
                ) : null
            })}
        </Box>
    )
}
