const typeSymbol = Symbol("linkType");

export type TypedLink<T> = string & {
    readonly [typeSymbol]: T,
}

export type LinkType<L extends TypedLink<any>> = L[typeof typeSymbol];

export function unsafeCast<T>(url: string): TypedLink<T> {
    return url as TypedLink<T>;
}
