import { DependencyList, useDebugValue, useEffect, useMemo } from "react";
import { TourLocation } from "./TourLocation";
import { useSelector, useDispatch } from "react-redux";
import { visitTourLocation, selectCurrentLocations, selectVisitedLocations } from "./tourSlice";


type LocationHook = [boolean, () => void];

export function useTourLocation(location: TourLocation | readonly TourLocation[]): LocationHook {
    const activeLocationSet = useSelector(selectCurrentLocations);
    const dispatch = useDispatch();
    const locations = Array.isArray(location)?location:[location];
    const visibleLocations = locations.filter(loc => activeLocationSet.has(loc));
    const visible = visibleLocations.length > 0;

    useDebugValue(locations.join(' ') +  ':'+ visible?"Visible":"Hidden");

    const markVisited = useMemo(() => () => {
        if(visibleLocations.length > 0) {
            console.log("Marking locations "+visibleLocations.join(' ')+" as visited");
            dispatch(visitTourLocation(visibleLocations));
        }
    }, [dispatch, visibleLocations]);


    return [visible, markVisited];
}

export function useVisitedTourLocation(location: TourLocation, isVisited: () => boolean, deps: DependencyList = []): void {
    const visitedLocations = useSelector(selectVisitedLocations);
    const dispatch = useDispatch();

    useEffect(() => {
        if (visitedLocations.indexOf(location) === -1 && isVisited()) {
            console.log("Marking location "+location+" as visited")
            dispatch(visitTourLocation([location]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, isVisited, visitedLocations, dispatch, ...deps])
}
