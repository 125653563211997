import { IamGroupAttributes } from "../../../repository/models/IamGroup";

export function emptyOfType(type: string): IamGroupAttributes[""] {
  if(type.endsWith("[]")) {
      return [emptyOfType(type.substring(0, type.length - 2)) as any];
  }
  switch (type) {
      case "text":
          return "";
      case "number":
          return 0
      case "checkbox":
          return false;
  }
  throw new Error("Unsupported attribute type");
}