import { itemTags, listTags, tags, wrapPromise } from "../../repository/rtk-query";
import { Blueprint } from "../../repository/models/Blueprint";
import { Project } from "../../repository/models/Project";
import fetcher from "../../repository";
import { BlueprintOverview } from "../../repository/models/BlueprintOverview";
import { Changeset } from "../../repository/models/Changeset";
import api from "../../store/api";
import { Feature } from "../../repository/models/Feature";
import { TypedLink } from "../../hal";

const blueprintsApi = api.injectEndpoints({
    endpoints: build => ({
        getBlueprints: build.query<ReadonlyArray<Blueprint>, Project>({
            queryFn: (project) => wrapPromise(fetcher.getBlueprints(project)),
            providesTags: (blueprints, _error, project) => tags("Blueprint")
                .listFor(project)
                .withItems(blueprints)
                .toArray()
        }),
        getBlueprint: build.query<Blueprint, BlueprintOverview>({
            queryFn: (overview)  => wrapPromise(fetcher.getBlueprint(overview)),
            providesTags: (blueprint) => itemTags("Blueprint", blueprint)
        }),
        getBlueprintStagedHistory: build.query<readonly Changeset[], Blueprint>({
            queryFn: (blueprint) => wrapPromise(fetcher.getStagedHistory(blueprint)),
            providesTags: (_history, _error, blueprint) => itemTags("Blueprint", blueprint)
        }),
        getBlueprintOverviews: build.query<ReadonlyArray<BlueprintOverview>, Project>({
            queryFn: (project) => wrapPromise(fetcher.getBlueprintOverview(project)),
            providesTags: (blueprints, _error, project) => tags("Blueprint")
                .listFor(project)
                .withItems(blueprints, bp => bp._links.self.href)
                .toArray()
        }),
        getBlueprintFeatures: build.query<Feature[], Blueprint>({
            queryFn: (blueprint) => wrapPromise(fetcher.getBlueprintFeatures(blueprint)),
            providesTags: (features, _error, _blueprint) => listTags("Blueprint", features)
        }),
        recommendedFeatureAction: build.mutation<void, Feature>({
            queryFn: (feature) => wrapPromise(fetcher.recommendedFeatureAction(feature)),
            invalidatesTags: (_response, _error, _feature) => [
                { type: "Blueprint" }
            ]
        }),
        getChangeset: build.query<Changeset, TypedLink<Changeset>>({
            queryFn: (link) => wrapPromise(fetcher.getChangeset(link))
        }),
    }),

})

export const {
    useGetBlueprintsQuery,
    useGetBlueprintQuery,
    useGetBlueprintStagedHistoryQuery,
    useGetBlueprintOverviewsQuery,
    useGetBlueprintFeaturesQuery,
    useRecommendedFeatureActionMutation,
    useGetChangesetQuery,
} = blueprintsApi;

export default blueprintsApi;
