import { PathMatch, RouteObject } from "react-router-dom";

export type ExtractVar<Pattern extends string> = Pattern extends `:${infer Var}` ? Var : never;

export type PathMatchRequiredVariables<Variables extends string> = Omit<PathMatch<Variables>, "params"> & { params: Readonly<Record<Variables, string>> };

export interface BaseRoute<Pattern extends string = string, Variables extends string = never, AncillaryData = unknown> {
    readonly pattern: Pattern;
    readonly wildcard: WildcardRoute<Pattern, Variables, AncillaryData>;
    readonly data: AncillaryData;

    match(path: string | null | undefined): PathMatchRequiredVariables<Variables> | null;
    navigate<NewPattern extends string, DroppedVariables extends string>(path: string | null | undefined, newRoute: Route<NewPattern, Exclude<Variables, DroppedVariables>>): string | null;

}

export enum ChildrenRoutesOption {
    NONE,
    STATIC,
    DYNAMIC,
    ALL,
}

export type RoutesOptions = {
    readonly self?: boolean,
    readonly wildcard?: boolean,
    readonly children?: ChildrenRoutesOption,
}

export type RoutesReturn<Pattern extends string, Variables extends string, Opts extends RoutesOptions, AncillaryData> =
    (Opts["self"] extends true ? Route<Pattern, Variables, AncillaryData> : never) |
    (Opts["wildcard"] extends true ? WildcardRoute<Pattern, Variables, AncillaryData> : never) |
    (Opts["children"] extends (ChildrenRoutesOption.STATIC | ChildrenRoutesOption.ALL) ? ChildRoute<Pattern, Variables, string, never, AncillaryData> : never) |
    (Opts["children"] extends (ChildrenRoutesOption.DYNAMIC | ChildrenRoutesOption.ALL) ? ChildRoute<Pattern, Variables, string, ExtractVar<string>, AncillaryData> : never);

export interface Route<Pattern extends string, Variables extends string = never, AncillaryData = unknown> extends BaseRoute<Pattern, Variables, AncillaryData> {
    routes<Opts extends RoutesOptions>(opts: Opts): ReadonlyArray<RoutesReturn<Pattern, Variables, Opts, AncillaryData>>;
    child<ChildPattern extends string>(childPattern: ChildPattern): ChildRoute<Pattern, Variables, ChildPattern, ExtractVar<ChildPattern>, AncillaryData>;
    child<ChildPattern extends string>(childPattern: ChildPattern, data: AncillaryData): ChildRoute<Pattern, Variables, ChildPattern, ExtractVar<ChildPattern>, AncillaryData>;

    generate(params: Record<Variables, string>): string;

    replace(path: string | null | undefined, params: Record<Variables, string>): string | null;
}

export type WildcardOnce<Pattern extends string> = Pattern extends `${string}/*`? Pattern: `${Pattern}/*`

export interface WildcardRoute<Pattern extends string, Variables extends string = never, AncillaryData = unknown> extends BaseRoute<WildcardOnce<Pattern>, Variables, AncillaryData> {
    relativePatternFor<ChildPattern extends string>(route: ChildBaseRoute<Pattern, Variables, ChildPattern, any>): string;
}

export type ChildRoute<
    ParentPattern extends string,
    ParentVariables extends string,
    ChildPattern extends string,
    ChildVariable extends string = ExtractVar<ChildPattern>,
    AncillaryData = unknown
    > = Route<`${ParentPattern}/${ChildPattern}`, ParentVariables | ChildVariable, AncillaryData>;
export interface ChildBaseRoute<
    ParentPattern extends string,
    ParentVariables extends string,
    ChildPattern extends string,
    ChildVariable extends string = ExtractVar<ChildPattern>,
    AncillaryData = unknown
    > extends BaseRoute<`${ParentPattern}/${ChildPattern}`, ParentVariables | ChildVariable, AncillaryData> {
}


export interface RootRoute<AncillaryData = unknown> extends Route<"", never, AncillaryData> {
    createRouteObjects(): RouteObject[];
}
