import { Typography, Breadcrumbs as MuiBreadcrumbs, BreadcrumbsProps as MuiBreadcrumbsProps, TypographyProps, Box } from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import React, {Children} from "react"
import { ReactNode } from "react"

function NavTypography(props: TypographyProps<"nav">) {
    return <Typography component="nav" variant="h6" {...props} />
}

type BreadcrumbsProps = {
    children: ReactNode,
    subtitle?: ReactNode,
} & Omit<MuiBreadcrumbsProps<typeof NavTypography>, "component"|"separator">;

export default function Breadcrumbs({children, ...props}: BreadcrumbsProps) {
    const c = Children.toArray(children);

    const sub = typeof props.subtitle === "string"?<Typography variant="subtitle1">{props.subtitle}</Typography>:props.subtitle;

    return (
        <Box mb={4}>
            <MuiBreadcrumbs component={NavTypography} separator={<NavigateNextIcon />} {...props}>
                {c.slice(0, c.length-1)}
                <Typography color="textPrimary" variant="inherit">{c[c.length-1]}</Typography>
            </MuiBreadcrumbs>
            {sub ?? null}
        </Box>
    )
}
