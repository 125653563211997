import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { connect } from "react-redux";
import { Box, Button, Link, Typography } from "@material-ui/core";
import { Link as RLink, useLocation, useSearchParams } from "react-router-dom";

import { Blueprint } from "../../repository/models/Blueprint";
import { AppState } from "../../store";
import { selectCurrentBlueprint } from "../blueprint/blueprintSlice";
import AutoBreadcrumbs from "../routes/AutoBreadcrumbs";
import { useGetEntityListQuery } from "../datamodel/api";
import { BLUEPRINT_EVENTHANDLERS, EVENTHANDLERS_EDIT, EVENTHANDLERS_NEW } from "../routes";
import { EventHandler } from "./types";
import { useGetEventHandlersQuery } from "./api";
import { EventHandlerDto } from "../../repository/models/EventHandlerDto";
import { EventHandlerCard } from "./components/EventHandlerCard";
import { getEventHandlers } from "./helpers";
import { TextLoader } from "../../ui/TextLoader";
import DataModelRequired from "../datamodel/DataModelRequired";
import { Alert, AlertTitle } from "@material-ui/lab";
import ChangesReminder from "../blueprint/components/ChangesReminder";
import { webhooksUrl } from "../../documentation";
import { RequestStateHandler } from "../../ui/RequestStateHandler";

interface EventHandlersProps {
    currentBlueprint: Blueprint;
}

function EventHandlersWrapper(props: EventHandlersProps) {
    return (
        <>
            <AutoBreadcrumbs>Webhooks</AutoBreadcrumbs>
            <EventHandlers currentBlueprint={props.currentBlueprint} />
        </>
    )
}

function EventHandlers(props: EventHandlersProps) {
    const { data: eventHandlersData, isLoading: eventHandlersLoading, error: eventHandlersError } = useGetEventHandlersQuery(props.currentBlueprint);
    const { data: entityList, isLoading, error } = useGetEntityListQuery(props.currentBlueprint ?? skipToken);
    const entities = entityList?._embedded?.entities;

    const path = useLocation().pathname;

    const [searchParams, setSearchParams] = useSearchParams();

    const urlEntity = searchParams.get("entity");

    React.useEffect(() => {
        if (entities !== undefined && entities.length > 0) {
            let firstEntity = entities[0];
            if (firstEntity !== undefined) {
                setSearchParams({ entity: firstEntity.name }, { replace: true });
            }
        }
    }, [urlEntity, entities, setSearchParams])


    const newEventHandlerUrl = BLUEPRINT_EVENTHANDLERS.navigate(path, EVENTHANDLERS_NEW)!;

    const eventHandlers = getEventHandlers(eventHandlersData as EventHandlerDto[])

    if(isLoading || !!error || eventHandlersLoading || !!eventHandlersError){
        return <RequestStateHandler isLoading={isLoading || eventHandlersLoading} error={error || eventHandlersError} />
    }

    if((entities?.length ?? 0) === 0) {
        return <DataModelRequired systemName="Webhooks" />
    }

    if(!entities){
        return <></>
    }

    return (
        <>
            <ChangesReminder />

            <Box mb={2}>
                <EventHandlersContent eventHandlers={eventHandlers} />
            </Box>

            <Button
                variant='contained'
                component={RLink}
                color='primary'
                to={newEventHandlerUrl}
            >
                Create Webhook
            </Button>
        </>
    );
}

export default connect((state: AppState) => ({
    currentBlueprint: selectCurrentBlueprint(state)!,
}))(EventHandlersWrapper);

interface EventHandlersContentProps {
    eventHandlers: EventHandler[] | null,
}
export const EventHandlersContent = ({ eventHandlers }: EventHandlersContentProps) => {
    const path = useLocation().pathname;
    const match = BLUEPRINT_EVENTHANDLERS.match(path)!.params;

    if(!!eventHandlers?.length){
        return (
            <>
                {eventHandlers.map((eventHandler, i) => {
                    const url = EVENTHANDLERS_EDIT.generate({...match, eventHandler: eventHandler.id })
                    return (
                        <EventHandlerCard url={url} eventHandler={eventHandler} key={i} />
                    )
                })}
            </>
        );
    }

    return <TextLoader isLoading={!eventHandlers}>
        <Alert component='div' severity="info">
            <AlertTitle>No webhooks set up</AlertTitle>
            <Typography variant="body2">A ContentGrid Webhook notifies an external system when data stored in your ContentGrid Application is created, updated, or deleted.</Typography>
            <Typography variant="body2">You can create webhooks for sending emails, anti-virus scanning, automatic document classification, and any other desired action.
            Learn more about webhooks in the <Link href={webhooksUrl}>ContentGrid documentation</Link></Typography>
        </Alert>
    </TextLoader>
}
