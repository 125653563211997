import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import {
  Edge,
  Handle,
  Node,
  NodeProps,
  Position
} from 'reactflow';
import { Typography } from '@material-ui/core';
import 'reactflow/dist/style.css';
import FingerPrintIcon from '@material-ui/icons/Fingerprint';
import SearchIcon from '@material-ui/icons/Search';
import cx from 'classnames'
import { Box } from '@material-ui/core';

import { Relation } from '../../../repository/models/Entity';
import { RequireIcon } from '../../../ui/icons/RequireIcon';
import { getDotPosition, getEntityModelHeight } from './helpers';
import { EntityNode } from './types';
import { useSearchParams } from 'react-router-dom';

export const TextUpdaterNode = (props: NodeProps<EntityNode>) => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const selectedEntityName = searchParams.get("entity") || '';
  const selectedNode = props.data.nodes.find(item => item.data.name === selectedEntityName) ?? null

  const sourceEdges = props.data.edges.filter(item => item.source === props.id)
  const targetEdges = props.data.edges.filter(item => item.target === props.id)

  const entityModelHeight = getEntityModelHeight(props.data)

  const manyTargetPerSource = !!targetEdges.length && !!targetEdges.find(item => item.data.manyTargetPerSource)

  const wrapperClasses = cx(classes.wrapper, {
    [classes.wrapperMany]: manyTargetPerSource
  })

  const headerClasses = cx(classes.header, {
    [classes.isSelectedHeader]: selectedNode?.data.name === props.data.name
  })

  return (
    <>
      <div className={wrapperClasses} id={props.id}>
        <div className={headerClasses}>
          <Typography className={classes.headerText}><b>{props.data.name}</b></Typography>
        </div>

        <div className={classes.attributes}>
          {(props.data._embedded?.attributes ?? []).map((attr, index) => (
            <div className={classes.attribute} key={attr.name+index}>
              <div className={classes.icons}>
                <FingerPrintIcon style={{opacity: attr.unique ? 1 : 0}} fontSize="inherit" color='action' />
                <RequireIcon style={{opacity: attr.required ? 1 : 0}} fontSize="inherit" color='action' />
                <SearchIcon style={{opacity: attr.indexed ? 1 : 0}} fontSize="inherit" color='action' />
              </div>

              <Box display='flex' justifyContent='space-between' width='100%'>
                <Typography className={classes.attrName} component='span' variant='caption'>{attr.name}</Typography>

                <div className={classes.attrInfo}>
                  <Typography className={classes.type} component='span' variant='caption' color='textSecondary'>{attr.type}</Typography>
                </div>
              </Box>
            </div>
          ))}
        </div>

        <div className={classes.relations}>
          {(props.data._embedded?.relations ?? []).map((rel, index) => (
            <RelationItem relation={rel} key={rel.name+index} selectedNode={selectedNode} />
          ))}
        </div>
      </div>

      {sourceEdges.reverse().map((edge, index) => {
        if(edge.targetHandle && selectedNode){
          const isSelectedHandle = edge.source === selectedNode.id || edge.target === selectedNode.id;

          return (
            <SourceHandle
              className={cx(classes.handle, { [classes.selectedHandle] : isSelectedHandle })}
              key={edge.source+index}
              height={entityModelHeight}
              edge={edge}
              index={index}
            />
          )
        }

        return null
      })}

      {targetEdges.map((edge, index) => {
        if(edge.targetHandle && selectedNode){
          const isSelectedHandle = edge.source === selectedNode.id || edge.target === selectedNode.id;

          return(
            <Handle
              className={cx(classes.handle, { [classes.selectedHandle] : isSelectedHandle })}
              key={edge.target+edge.source+index}
              type="target"
              position={Position.Left}
              id={edge.targetHandle}
            />
          )
        }
        
        return null
      })}
    </>
  );
}

const RelationItem = ({relation, selectedNode}: {relation: Relation, selectedNode: Node<EntityNode> | null}) => {
  const classes = useStyles();
  const description = (relation.many_source_per_target ? 'many' : 'one') + ' to ' + (relation.many_target_per_source ? 'many' : 'one');

  const relationClasses = cx(classes.relation, {
    [classes.multipleRelation]: relation.many_source_per_target
  })

  const relationTextClasses = cx(classes.relationText, {
    [classes.boldText]: relation.target === selectedNode?.data.name
  })

  return (
    <div className={relationClasses}>
      {relation.required ? <Box component='span' display='inline-flex' marginRight={0.5}><RequireIcon fontSize="inherit" color='action' /></Box> : null}

      <Typography className={relationTextClasses} component='span' variant='caption'>{relation.name}: </Typography>

      <Typography className={classes.relationType} noWrap component='span' variant='caption' color='textSecondary'>{description}</Typography>
    </div>
  )
}

const SourceHandle = ({className, edge, index, height}: {className: string, edge: Edge, index: number, height: number}) => {
  const dotPosition = getDotPosition(index);
  const topPositionValue = height-dotPosition+'px';

  if(edge.sourceHandle){
    return (
      <Handle
        className={className}
        type="source"
        position={Position.Right}
        id={edge.sourceHandle}
        style={{top: topPositionValue}}
      />
    )
  }

  return null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      border: '1px solid '+theme.palette.divider,
      minWidth: theme.spacing(26),
      borderRadius: theme.shape.borderRadius,
      boxSizing: 'border-box',
      position: 'relative',
      marginBottom: theme.spacing(2),
      '& *': {
        boxSizing: 'border-box'
      }
    },
    wrapperMany: { 
      '&::before': {
        content: '""',
        width: '12px',
        height: '1px',
        display: 'block',
        position: 'absolute',
        backgroundColor: theme.palette.text.primary,
        transform: 'rotate(-30deg)',
        top: '11px',
        left: '-12px',
      },
      '&::after': {
        content: '""',
        width: '12px',
        height: '1px',
        display: 'block',
        position: 'absolute',
        backgroundColor: theme.palette.text.primary,
        transform: 'rotate(30deg)',
        top: '18px',
        left: '-12px',
      }
    },
    header: {
      borderBottom: '1px solid '+theme.palette.divider,
      background: theme.palette.primary.light,
      textAlign: 'center',
      height: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    isSelectedHeader: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white
    },
    headerText: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    },
    attributes: {
      padding: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      background: theme.palette.common.white,
    },
    attribute: {
      height: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1)
      }
    },
    attrName: {
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    attrInfo: {
      display: 'flex',
      alignItems: 'center'
    },
    icons: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1)
    },
    type: {
      textTransform: 'lowercase',
      marginLeft: theme.spacing(1)
    },
    relations: {
      display: 'flex',
      flexDirection: 'column',
      background: theme.palette.common.white,
    },
    relation: {
      textAlign: 'center',
      height: theme.spacing(4),
      display: 'flex',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.grey[50],
      position: 'relative',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1)
      }
    },
    multipleRelation: {
      '&::before': {
        content: '""',
        width: '12px',
        height: '1px',
        display: 'block',
        position: 'absolute',
        backgroundColor: theme.palette.text.primary,
        transform: 'rotate(30deg)',
        top: 'calc(50% - 3px)',
        right: '-12px',
      },
      '&::after': {
        content: '""',
        width: '12px',
        height: '1px',
        display: 'block',
        position: 'absolute',
        backgroundColor: theme.palette.text.primary,
        transform: 'rotate(-30deg)',
        top: 'calc(50% + 4px)',
        right: '-12px',
      }
    },
    relationText: {
      marginRight: theme.spacing(0.5),
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    boldText: {
      fontWeight: 600
    },
    relationType: {
      overflow: 'visible',
    },
    button: {
      width: theme.spacing(21),
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    handle: {
      minWidth: '4px',
      minHeight: '0px',
      width: '4px',
      height: '1px',
      border: 'none',
      borderRadius: 0,
      top: theme.spacing(2),
      '&.selected': {
        height: '2px'
      },
    },
    selectedHandle: {
      height: '2px'
    }
  })
);
