import { AppBar, makeStyles, Theme, createStyles, Toolbar, Typography, Grid, IconButton, Drawer, Hidden } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';

import AuthLogin from "./features/authentication/AuthLogin";
import CreateMenu from "./CreateMenu";
import AccountMenu from "./features/account/AccountMenu";
import ProjectSelector from "./features/project/ProjectSelector";
import { ROOT } from "./features/routes";
import NavLayout from "./NavLayout";
import Link from "./ui/Link";
import IfAuthenticated from "./features/authentication/IfAuthenticated";
import { Box } from "@material-ui/core";

export default function AppLayout() {
    const classes = useStyles();

    const [navOpen, setNavOpen] = useState(false);

    return (
        <div className={classes.layout}>
            <AppBar className={classes.appBar} position="static">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={() => setNavOpen(true)}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography className={classes.logoText} variant="h6" component={Link} to={ROOT.generate({})} underline="none">
                        <img className={classes.logo} src="/logo-white.png" alt="ContentGrid Console logo" />
                        ContentGrid Console
                    </Typography>
                    <ProjectSelector />
                    <div className={classes.spacer}></div>
                    <IfAuthenticated>
                        <Box marginRight={2}><CreateMenu color="inherit" /></Box>
                    </IfAuthenticated>
                    <AccountMenu color="inherit" />
                </Toolbar>
            </AppBar>
            <AuthLogin>
                <Grid container direction="row" wrap="nowrap" className={classes.layoutRow}>
                    <Drawer
                        variant="temporary"
                        open={navOpen}
                        onClose={() => setNavOpen(false)}
                    >
                        <Grid item className={classes.navLayout}>
                            <NavLayout />
                        </Grid>
                    </Drawer>
                    <Hidden smDown>
                        <Grid item className={classes.navLayout}>
                            <NavLayout />
                        </Grid>
                    </Hidden>
                    <Grid item className={classes.contentLayout}>
                        <Outlet />
                    </Grid>
                </Grid>
            </AuthLogin>

            <ToastContainer
                position="bottom-right"
                autoClose={6000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                theme="colored"
                closeButton={false}
            />
        </div>
    );
}

const navDrawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        layout: {
            height: "100vh",
            display: "flex",
            flexDirection: "column"
        },
        layoutRow: {
            flexGrow: 1,
        },
        navLayout: {
            [theme.breakpoints.up('md')]: {
                width: navDrawerWidth,
                flexShrink: 0
            },
            flex: 1,
            maxWidth: 240,
            display: "flex",
            alignItems: "stretch",
            backgroundColor: '#fff',
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
            '& > *': {
                width: '100%',
                paddingRight: '1rem',
            },
        },
        contentLayout: {
            flex: 2,
            margin: "0 auto",
            maxWidth: "960px",
            padding: theme.spacing(2),
        },
        spacer: {
            flexGrow: 1,
        },
        appBar: {
            '& .MuiToolbar-root > .MuiTypography-root': {
                marginRight: theme.spacing(2)
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                display: "none",
            }
        },
        logo: {
            width: '40px',
            height: '40px',
            marginRight: '1.5rem',
        },
        logoText: {
            display: 'flex',
            alignItems: 'center',
        }
    }),
);
