import { Button, MenuItem, MenuList, PropTypes, } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { Organization } from "./repository/models/Organization";
import { selectCurrentOrganization, selectOrganizations } from "./features/account/accountSlice";
import { AppDispatch, AppState } from "./store";
import { projectSetCurrentProject } from "./features/project/projectSlice";
import { Project } from "./repository/models/Project";
import ArrowMenu from "./ArrowMenu";
import { CREATE_ORGANIZATION, CREATE_PROJECT } from "./features/routes";

type CreateMenuProps = {
    color?: PropTypes.Color,
    currentOrg: Organization|null,
    orgs: readonly Organization[] | null,
    handleSetCurrentProject: (project: Project) => void,
}

function CreateMenu(props: CreateMenuProps) {
    const [menuAnchor, setMenuAnchor] = React.useState<Element|null>(null);
    const open = Boolean(menuAnchor);
    const navigate = useNavigate()

    const anchorRef = React.useRef(null);

    const handleOpen = useCallback((event: React.MouseEvent) => {
        setMenuAnchor(event.currentTarget);
    }, [setMenuAnchor]);
    const handleClose = useCallback(() => {
        setMenuAnchor(null);
    }, [setMenuAnchor]);

    const handleListKeyDown = (event: React.KeyboardEvent<HTMLUListElement>) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            handleClose();
        }
    }

    const createProjectTargetOrg = props.currentOrg ?? props.orgs?.[0] ?? null

    const handleCreateProject = () => {
        if(createProjectTargetOrg) {
            navigate(CREATE_PROJECT.generate({org: createProjectTargetOrg.name}));
        }
    }

    const handleCreateOrganization = () => {
        navigate(CREATE_ORGANIZATION.generate({}))
        handleClose();
    }

    return <>
        <Button
            color={props.color} variant="outlined" startIcon={<AddIcon />} ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true"
            onClick={handleOpen}
        >
            Create
        </Button>
        <ArrowMenu open={open} anchorRef={anchorRef} handleClose={handleClose}>
            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={(e) => handleListKeyDown(e)} dense>
                <MenuItem onClick={handleCreateOrganization}>Create Organization</MenuItem>
                <MenuItem disabled={createProjectTargetOrg === null} onClick={handleCreateProject}>Create Project</MenuItem>
            </MenuList>
        </ArrowMenu>
    </>
}


export default connect((state: AppState) => ({
    currentOrg: selectCurrentOrganization(state),
    orgs: selectOrganizations(state),
}),
    (dispatch: AppDispatch) => ({
        handleSetCurrentProject: (project: Project) => {
            dispatch(projectSetCurrentProject(project))
        }
    })
)(CreateMenu);

