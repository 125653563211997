import { Params } from "react-router-dom";

export function paramsToRecord<ParamKey extends string = string>(params: Params<ParamKey>): Record<ParamKey, string>|null {
  let record: any = {};
  for (let key in params) {
      if (params[key] === undefined || params[key] === null) {
          return null;
      }
      record[key] = params[key];
  }
  return record as Record<ParamKey, string>;
}
