import { styled, CardActionArea } from "@material-ui/core"
import React, { ReactNode } from "react"
import cx from 'classnames'

import Link from "./Link";
import { StyledCard } from "./styles/StyledCard";

export interface CardLinkProps {
    link: string,
    children: ReactNode,
    minHeight?: boolean,
}

export const CardLink = ({ minHeight = true, link, children }: CardLinkProps) => {
    const contentClasses = cx({ 'min-height': minHeight })

    return (
        <StyledWrapper>
            <CardActionArea>
                <StyledLink to={link} underline="none" className={contentClasses}>
                    {children}
                </StyledLink>
            </CardActionArea>
        </StyledWrapper>
    )
}

const StyledLink = styled(Link)({
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
    flexDirection: 'column',
    width: '100%',
    '& .MuiChip-sizeSmall': {
        width: '24px',
        marginRight: '0.25rem'
    },
    '&.min-height': {
        minHeight: '6rem',
    }
})

const StyledWrapper = styled(StyledCard)({
    '&:hover': {
        backgroundColor: 'none',
        '&::before' : {
            opacity: '1',
            transition: 'opacity 100ms ease-out'
        },
        '&::after' : {
            opacity: '0',
            transition: 'opacity 100ms ease-out'
        }
    }
})
