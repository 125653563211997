import { skipToken } from "@reduxjs/toolkit/query/react";
import React from "react";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import LinkTab from "../../ui/tabs/LinkTab";
import LinkTabs from "../../ui/tabs/LinkTabs";
import { IAM_REALM, IAM_REALM_ATTRIBUTES, IAM_REALM_CLIENTS, IAM_REALM_CREATE_ATTRIBUTE, IAM_REALM_CREATE_GROUP, IAM_REALM_CREATE_USER, IAM_REALM_EDIT_GROUP, IAM_REALM_EDIT_USER, IAM_REALM_GROUP, IAM_REALM_GROUPS, IAM_REALM_SSO, IAM_REALM_USER, IAM_REALM_USERS } from "../routes";
import AutoBreadcrumbs from "../routes/AutoBreadcrumbs";
import { useGetRealmByIdQuery } from "./api";
import { IamRealmOverview } from "./IamRealmOverview";
import { IamRealmUsers } from "./IamRealmUsers/IamRealmUsers";
import { IamRealmUser } from "./IamRealmUsers/IamRealmUser";
import { IamRealmGroups } from "./IamRealmGroups/IamRealmGroups";
import { IamRealmGroup } from "./IamRealmGroups/IamRealmGroup";
import { RequestStateHandler } from "../../ui/RequestStateHandler";
import { AppState } from "../../store";
import { selectCurrentOrganization } from "../account/accountSlice";
import { connect } from "react-redux";
import { Organization } from "../../repository/models/Organization";
import { IamRealmCreateUser } from "./IamRealmUsers/IamRealmCreateUser";
import { HighlightedComponent, iamCreateUserLocation } from "../tour";
import { IamRealmAttributes } from "./IamRealmAttributes";
import { IamRealmCreateAttribute } from "./IamRealmCreateAttribute";
import { IamRealmCreateGroup } from "./IamRealmGroups/IamRealmCreateGroup";
import IamRealmEditUser from "./IamRealmUsers/IamRealmEditUser";
import { IamRealmEditGroup } from "./IamRealmGroups/IamRealmEditGroup";
import { IamRealmClients } from "./IamRealmClients";
import { IamRealmSSO } from "./IamRealmSSO";

interface IamRealmPageProps {
    org: Organization | null
}

const IamRealmPage = ({ org }: IamRealmPageProps) => {
    const { realm: realmId } = useParams()
    const { data: realm, isLoading, error } = useGetRealmByIdQuery(realmId ?? skipToken);

    const location = useLocation();

    return (<>
        <AutoBreadcrumbs subtitle='Manage access to your ContentGrid applications'>
            <>{realm?.name}</>
        </AutoBreadcrumbs>

        {isLoading || !!error || org === null ? (
            <RequestStateHandler isLoading={isLoading || org === null} error={error} />
        ) : (
            <>
                <LinkTabs indicatorColor="primary">
                    <LinkTab label="Overview" from={IAM_REALM} to={IAM_REALM} />
                        <LinkTab label={
                            <HighlightedComponent
                                location={iamCreateUserLocation}
                                attachToParent={1}
                                disabled={
                                    [IAM_REALM_USERS, IAM_REALM_CREATE_USER].some(r => r.match(location.pathname))
                                }
                            >
                                <span>Users</span>
                            </HighlightedComponent>
                        } from={IAM_REALM} to={IAM_REALM_USERS} activeRoutes={[
                        IAM_REALM_USER, IAM_REALM_CREATE_USER, IAM_REALM_EDIT_USER
                    ]} />
                    <LinkTab label="Groups" from={IAM_REALM} to={IAM_REALM_GROUPS} activeRoutes={[
                        IAM_REALM_GROUP, IAM_REALM_CREATE_GROUP, IAM_REALM_EDIT_GROUP
                    ]} />
                    <LinkTab label="Attributes" from={IAM_REALM} to={IAM_REALM_ATTRIBUTES} activeRoutes={[
                        IAM_REALM_CREATE_ATTRIBUTE
                    ]} />
                    <LinkTab label="Clients" from={IAM_REALM} to={IAM_REALM_CLIENTS} />
                    <LinkTab label="SSO" from={IAM_REALM} to={IAM_REALM_SSO} />
                </LinkTabs>

                <Routes>
                    <Route index element={<IamRealmOverview realm={realm} />} />
                    <Route path={IAM_REALM.wildcard.relativePatternFor(IAM_REALM_USERS)} element={<IamRealmUsers org={org} realm={realm} />} />
                    <Route path={IAM_REALM.wildcard.relativePatternFor(IAM_REALM_USER)} element={<IamRealmUser org={org} realm={realm} />} />
                    <Route path={IAM_REALM.wildcard.relativePatternFor(IAM_REALM_CREATE_USER)} element={<IamRealmCreateUser org={org} realm={realm} />} />
                    <Route path={IAM_REALM.wildcard.relativePatternFor(IAM_REALM_EDIT_USER)} element={<IamRealmEditUser org={org} realm={realm} />} />
                    <Route path={IAM_REALM.wildcard.relativePatternFor(IAM_REALM_GROUPS)} element={<IamRealmGroups org={org} realm={realm} />} />
                    <Route path={IAM_REALM.wildcard.relativePatternFor(IAM_REALM_GROUP)} element={<IamRealmGroup org={org} realm={realm} />} />
                    <Route path={IAM_REALM.wildcard.relativePatternFor(IAM_REALM_CREATE_GROUP)} element={<IamRealmCreateGroup org={org} realm={realm} />} />
                    <Route path={IAM_REALM.wildcard.relativePatternFor(IAM_REALM_EDIT_GROUP)} element={<IamRealmEditGroup org={org} realm={realm} />} />
                    <Route path={IAM_REALM.wildcard.relativePatternFor(IAM_REALM_ATTRIBUTES)} element={<IamRealmAttributes org={org} realm={realm} />} />
                    <Route path={IAM_REALM.wildcard.relativePatternFor(IAM_REALM_CREATE_ATTRIBUTE)} element={<IamRealmCreateAttribute org={org} realm={realm} />} />
                    <Route path={IAM_REALM.wildcard.relativePatternFor(IAM_REALM_CLIENTS)} element={<IamRealmClients />} />
                    <Route path={IAM_REALM.wildcard.relativePatternFor(IAM_REALM_SSO)} element={<IamRealmSSO />} />
                </Routes>
            </>
        )}
    </>);
}

export default connect((state: AppState) => ({
    org: selectCurrentOrganization(state),
  })
)(IamRealmPage)
