import { skipToken } from "@reduxjs/toolkit/query";
import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';

import { Blueprint } from "../../repository/models/Blueprint";
import { AppState } from "../../store";
import Link from "../../ui/Link";
import { selectCurrentBlueprint } from "../blueprint/blueprintSlice";
import { useGetEntityListQuery } from "../datamodel/api";
import { BLUEPRINT_EVENTHANDLERS, EVENTHANDLERS_EDIT } from "../routes";
import AutoBreadcrumbs from "../routes/AutoBreadcrumbs";
import { EventHandlersWrapper } from "./components/EventHandlersWrapper";
import { paramsToRecord } from "../routes/helpers";
import { useDeleteEventHandlerMutation, useGetEventHandlersQuery, useUpdateEventHandlerMutation } from "./api";
import { EventHandlerCreationDto, EventHandlerDto } from "../../repository/models/EventHandlerDto";
import { EventHandlerForm } from "./components/EventHandlerForm";
import { getEventHandlers } from "./helpers";
import { RequestStateHandler } from "../../ui/RequestStateHandler";
import { Button, Dialog, DialogTitle, Typography } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { ServerErrorMessage } from "../../ui/ServerErrorMessage";
import { DialogActions } from "@material-ui/core";
import BusyButton from "../../BusyButton";
import RemapPalette from "../../ui/theme/RemapPalette";

interface EventHandlerEditProps {
    currentBlueprint: Blueprint;
}

function EventHandlerEdit(props: EventHandlerEditProps) {
    const { data: entityList, isLoading, error } = useGetEntityListQuery(props.currentBlueprint ?? skipToken);
    const entities = entityList?._embedded?.entities;
    const [ removeDialogOpened, setRemoveDialogOpened ] = useState(false)
    const navigate = useNavigate();

    const path = useLocation().pathname;

    const match = EVENTHANDLERS_EDIT.match(path);

    const { data: eventHandlersData } = useGetEventHandlersQuery(props.currentBlueprint);
    const eventHandlers = getEventHandlers(eventHandlersData as EventHandlerDto[])
    const eventHandler = eventHandlers?.find(handler => handler.id === match?.params.eventHandler)!;

    const [updateEventHandler, {isLoading: editLoading, error: editError}] = useUpdateEventHandlerMutation();

    const handleSave = useCallback(async (data: EventHandlerCreationDto) => {
        const currentEventHandler = eventHandlersData!.find(handler => handler.id === match?.params.eventHandler)!;

        await updateEventHandler({eventhandler: currentEventHandler!, update: data})
    }, [updateEventHandler, match, eventHandlersData]);

    const [deleteEventHandler, {isLoading: deleteLoading, error: deleteError}] = useDeleteEventHandlerMutation();

    if(isLoading || !!error) {
        return <RequestStateHandler isLoading={isLoading} error={error} />
    }

    if(!!entities && !!eventHandler) {
        const parentUrl = BLUEPRINT_EVENTHANDLERS.generate(paramsToRecord(match!.params)!) + '?entity'+eventHandler?.id || ''
        
        const handleRemove = async () => {
            const currentEventHandler = eventHandlersData!.find(handler => handler.id === match?.params.eventHandler)!;
            await deleteEventHandler(currentEventHandler).unwrap()
            navigate(parentUrl)
        }

        return (
            <>
                <AutoBreadcrumbs collapseDefaults>
                    <Link to={parentUrl}>Webhooks</Link>
                    <span>{eventHandler.name}</span>
                </AutoBreadcrumbs>

                <EventHandlersWrapper>
                    <EventHandlerForm
                        entities={entities}
                        eventHandler={eventHandler}
                        parentUrl={parentUrl}
                        loading={editLoading}
                        error={editError}
                        onSave={handleSave}
                        onRemove={() => setRemoveDialogOpened(true)}
                    />
                </EventHandlersWrapper>

                <Dialog open={removeDialogOpened} onClose={() => setRemoveDialogOpened(false)}>
                    <DialogTitle>Delete webhook {eventHandler.name}?</DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you want to delete this webhook?</Typography>
                        <ServerErrorMessage error={deleteError}/>
                    </DialogContent>
                    <DialogActions>
                        <RemapPalette from="danger" to="primary">
                            <Button color="secondary" onClick={() => setRemoveDialogOpened(false)}>Cancel</Button>
                            <BusyButton color="primary" busy={deleteLoading} variant="contained" disableElevation onClick={handleRemove}>Delete</BusyButton>
                        </RemapPalette>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return null

}

export default connect((state: AppState) => ({
    currentBlueprint: selectCurrentBlueprint(state)!,
}))(EventHandlerEdit);
