import { Dialog, DialogActions, DialogTitle, Button, DialogContentText, DialogContent  } from "@material-ui/core";
import React from "react";

import { IamGroup, IamGroupMember } from "../../../../repository/models/IamGroup";
import BusyButton from "../../../../BusyButton";
import { resolveTemplate } from "../../../../hal";
import { useRemoveRealmGroupMemberMutation } from "../../api";
import { RequestStateHandler } from "../../../../ui/RequestStateHandler";
import { IamUserName } from "../../components/IamUserName";

interface ConfirmationDialogProps {
    group: IamGroup,
    member: IamGroupMember,
    opened: boolean,
    onClose: () => void
}

export const ConfirmationDialog = ({ group, member, opened, onClose }: ConfirmationDialogProps) => {
    const [removeRealmGroupMember, {isLoading, error}] = useRemoveRealmGroupMemberMutation()

    const memberTemplate = resolveTemplate(member, 'remove-member');

    const handleClick = async () => {
        await removeRealmGroupMember(member).unwrap()
        onClose()
    }

    return (
        <Dialog open={opened} maxWidth='lg' onClose={onClose}>
            <DialogTitle>Leave group</DialogTitle>
           
            <DialogContent>
                <DialogContentText>Are you sure you want to remove user <b><IamUserName firstName={member.first_name} lastName={member.last_name} emptyValue={<></>} /></b> from group <b>{group.name}</b>?</DialogContentText>
                {!!error ? (
                    <RequestStateHandler isLoading={false} error={error} />
                ) : null}
            </DialogContent>
         
            <DialogActions>
                <Button color="secondary" onClick={onClose}>Cancel</Button>
                <BusyButton
                    color="primary"
                    busy={isLoading}
                    variant="contained"
                    disableElevation
                    disabled={!memberTemplate}
                    onClick={handleClick}
                >Leave</BusyButton>
            </DialogActions>
        </Dialog>
    )
}
