import { AllowedValuesAttributeConstraint, Attribute, AttributeConstraint, Entity, EntityList, Relation } from "../../repository/models/Entity";
import fetcher from "../../repository";
import { Blueprint } from "../../repository/models/Blueprint";
import { HalForm, WithoutHal } from "../../hal";
import { listTags, itemTags, tags, wrapPromise } from "../../repository/rtk-query";
import api from "../../store/api";
import { ReactFlowJsonObject } from "reactflow";

const datamodelApi = api.injectEndpoints({
    endpoints: build => ({
        getEntityList: build.query<EntityList, Blueprint>({
            queryFn: (blueprint) => wrapPromise(fetcher.getEntityList(blueprint)),
            providesTags: (entities, _error, blueprint) => tags("Entity")
                .listFor(blueprint)
                .withItems(entities?._embedded?.entities, e => e._links.self.href)
                .toArray()
        }),
        getEntity: build.query<Entity, Entity>({
            queryFn: (entityRef) => wrapPromise(fetcher.getEntity(entityRef)),
            providesTags: (entity, _error, entityRef) => [
                ...itemTags("Entity", entityRef, ref => ref._links.self.href),
                ...listTags("Attribute", entity?._embedded?.attributes),
                ...listTags("Relation", entity?._embedded?.relations),
            ]
        }),
        addEntity: build.mutation<Entity, {entityRefList: EntityList, entityName: string, entityDescription: string}>({
            queryFn: ({entityRefList, entityName, entityDescription}) => wrapPromise(fetcher.addEntity(entityRefList, entityName, entityDescription)),
            invalidatesTags: (entity) => [
                { type: "Entity" },
                ...itemTags("Blueprint", entity, e => e._links.blueprint.href)
            ]
        }),
        deleteEntity: build.mutation<void, Entity>({
            queryFn: (entity) => wrapPromise(fetcher.deleteEntity(entity)),
            invalidatesTags: (_result, _error, entity) => [
                { type: "Entity" },
                ...itemTags("Blueprint", entity, e => e._links.blueprint.href)
            ]
        }),
        patchEntity: build.mutation<Entity, {entity: Entity, patch: Partial<WithoutHal<Entity>>}>({
            queryFn: ({entity, patch}) => wrapPromise(fetcher.patchEntity(entity, patch)),
            invalidatesTags: (newEntity, _err, { entity: oldEntity }) => [
                ...tags("Entity")
                    .listFor(oldEntity, e => e._links.blueprint.href)
                    .item(oldEntity)
                    .item(newEntity)
                    .toArray(),
                ...itemTags("Blueprint", newEntity, e => e._links.blueprint.href)
            ]
        }),
        getAttribute: build.query<Attribute, {entity: Entity, attributeName: string}>({
            queryFn: ({entity, attributeName}) => wrapPromise(fetcher.getAttribute(entity, attributeName)),
            providesTags: (attribute, _err, {entity}) => [
                ...itemTags("Entity", entity),
                ...itemTags("Attribute", attribute),
            ]
        }),
        addAttribute: build.mutation<Attribute, {entity: Entity, attribute: WithoutHal<Attribute>}>({
            queryFn: ({entity, attribute}) => wrapPromise(fetcher.addAttribute(entity, attribute)),
            invalidatesTags: (_entity, _err, { entity }) => [
                ...itemTags("Entity", entity),
                ...itemTags("Blueprint", entity, e => e._links.blueprint.href)
            ]
        }),
        renameAttribute: build.mutation<Attribute, {attribute: Attribute, newName: string}>({
            queryFn: ({attribute, newName}) => wrapPromise(fetcher.renameAttribute(attribute, newName)),
            invalidatesTags: (newAttribute, _err, {attribute}) => newAttribute?[
                { type: "Attribute", id: attribute._links.self.href },
                { type: "Attribute", id: newAttribute._links.self.href },
                // FIXME: invalidate the specific entity only, we need an entity link on the attribute for that
                { type: "Entity" },
                { type: "Blueprint" }
            ]:[]
        }),
        patchAttribute: build.mutation<Attribute, {attribute: Attribute, patch: Partial<WithoutHal<Attribute>>}>({
            queryFn: ({ attribute, patch }) => wrapPromise(fetcher.patchAttribute(attribute, patch)),
            invalidatesTags: (newAttribute, _err, {attribute}) => newAttribute?[
                { type: "Attribute", id: attribute._links.self.href },
                { type: "Attribute", id: newAttribute._links.self.href },
                // FIXME: invalidate the specific entity only, we need an entity link on the attribute for that
                { type: "Entity" },
                { type: "Blueprint" }
            ]:[]
        }),
        deleteAttribute: build.mutation<Entity, Attribute>({
            queryFn: (attribute) => wrapPromise(fetcher.deleteAttribute(attribute)),
            invalidatesTags: (entity, _err, attribute) => [
                { type: "Attribute", id: attribute._links.self.href },
                ...itemTags("Blueprint", entity, e => e._links.blueprint.href)
            ]
        }),
        setAllowedValuesConstraint: build.mutation<void, {attribute: Attribute, form: HalForm<AllowedValuesAttributeConstraint>, constraint: WithoutHal<AllowedValuesAttributeConstraint>}>({
            queryFn: ({form,  constraint}) => wrapPromise(fetcher.setAllowedValuesConstraint(form, constraint)),
            invalidatesTags: (_response, _err, {attribute}) => [
                ...itemTags("Attribute", attribute),
                ...itemTags("Entity", attribute, a => a._links.entity.href),
                ...itemTags("Blueprint", attribute, a => a._links.blueprint.href)
            ]
        }),
        deleteConstraint: build.mutation<void, AttributeConstraint>({
            queryFn: (constraint) => wrapPromise(fetcher.deleteConstraint(constraint)),
            invalidatesTags: () => [
                // FIXME: invalidate the specific attribute only, we need an entity link on the constraint for that
                { type: "Attribute" },
                { type: "Entity" },
                { type: "Blueprint" }
            ]
        }),
        getRelation: build.query<Relation, {entity: Entity, relationName: string}>({
            queryFn: ({entity, relationName}) => wrapPromise(fetcher.getRelation(entity, relationName)),
            providesTags: (relation, _err, {entity}) => [
                ...itemTags("Relation", relation),
            { type: "Entity", id: entity._links.self.href }
            ]
        }),
        addRelation: build.mutation<Relation, {entity: Entity, relation: WithoutHal<Relation>}>({
            queryFn: ({entity, relation}) => wrapPromise(fetcher.addRelation(entity, relation)),
            invalidatesTags: (_entity, _err, { entity }) => [
                ...itemTags("Entity", entity),
                ...itemTags("Blueprint", entity, e => e._links.blueprint.href)
            ]
        }),
        patchRelation: build.mutation<Relation, {relation: Relation, patch: Partial<WithoutHal<Relation>>}>({
            queryFn: ({relation, patch}) => wrapPromise(fetcher.patchRelation(relation, patch)),
            invalidatesTags: (newRelation, _err, {relation}) => newRelation?[
                { type: "Relation", id: relation._links.self.href },
                { type: "Relation", id: newRelation._links.self.href }
            ]:[]
        }),
        deleteRelation: build.mutation<Entity, Relation>({
            queryFn: (relation) => wrapPromise(fetcher.deleteRelation(relation)),
            invalidatesTags: (entity, _err, relation) => [
                { type: "Relation", id: relation._links.self.href },
                ...itemTags("Blueprint", entity, e => e._links.blueprint.href)
            ]
        }),
        saveSchemaLayout: build.mutation<void, { blueprint: Blueprint, flow: ReactFlowJsonObject<any, any> }>({
            queryFn: ({blueprint, flow}) => wrapPromise(fetcher.saveSchemaLayout(blueprint, flow)),
            invalidatesTags: () => [
                { type: "Schema" }
            ]
        }),
        schemaLayout: build.query<ReactFlowJsonObject<any, any>, Blueprint>({
            queryFn: (blueprint) => wrapPromise(fetcher.schemaLayout(blueprint)),
            providesTags: () => [
                { type: "Schema" }
            ]
        }),

    })
})

export const {
    useGetEntityListQuery,
    useGetEntityQuery,
    useAddEntityMutation,
    useDeleteEntityMutation,
    usePatchEntityMutation,
    useGetAttributeQuery,
    useAddAttributeMutation,
    useRenameAttributeMutation,
    usePatchAttributeMutation,
    useDeleteAttributeMutation,
    useSetAllowedValuesConstraintMutation,
    useDeleteConstraintMutation,
    useGetRelationQuery,
    useAddRelationMutation,
    usePatchRelationMutation,
    useDeleteRelationMutation,
    useSaveSchemaLayoutMutation,
    useSchemaLayoutQuery
} = datamodelApi;
