import { Box, Button, InputLabel, Typography, styled, CircularProgress } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { useCreateOrganizationMutation, useGetOrganizationListQuery } from "./api";
import { AppDispatch, AppState } from "../../store";
import { Organization } from "../../repository/models/Organization";
import { accountSetCurrentOrganization, selectCurrentUser } from "../account/accountSlice";
import BusyButton from "../../BusyButton";
import FormActions from "../../ui/FormActions";
import ValidatedTextInput from "../../hal/forms/ValidatedTextInput";
import { User } from "../../repository/models/User";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { resolveTemplateRequired } from "../../hal";
import { ServerErrorMessage } from "../../ui/ServerErrorMessage";
import { RequestStateHandler } from "../../ui/RequestStateHandler";

interface CreateOrganizationProps {
    currentUser: User|null,
    handleSetCurrentOrg: (org: Organization) => void
}

const CreateOrganization = ({ currentUser, handleSetCurrentOrg }: CreateOrganizationProps) => {
    const [ name, setName ] = useState('')
    const [createOrganization, { isLoading: createLoading, error: createError }] = useCreateOrganizationMutation()
    const navigate = useNavigate()

    const handleCreate = useCallback(async() => {
        const createOrganizationResponse = await createOrganization({ display_name: name })
        if("data" in createOrganizationResponse && createOrganizationResponse.data){
            handleSetCurrentOrg(createOrganizationResponse.data)
        }
    },[name, createOrganization, handleSetCurrentOrg])

    const { data: organizationList, isLoading, error } = useGetOrganizationListQuery(currentUser ?? skipToken);

    const halProperty = organizationList !== undefined
        ? resolveTemplateRequired(organizationList, 'default').property('display_name')
        : null

    if(isLoading || !!error){
        return <RequestStateHandler isLoading={isLoading} error={error} />
    }

    if (halProperty === null) {
        return (
            <Box maxWidth='40rem'><CircularProgress /></Box>
        );
    }

    return (
        <Box sx={{ maxWidth: '40rem' }}>
            <Box mb={2}><Typography variant='h6'>Create Organization</Typography></Box>

            <ServerErrorMessage error={createError} />

            <StyledRow>
                <InputLabel htmlFor="name">Organization name:</InputLabel>
                <ValidatedTextInput
                    halProperty={halProperty}
                    displayName="Organization name"
                    value={name}
                    autoFocus
                    id="name"
                    fullWidth
                    handleOnChange={(val) => setName(val)}
                />
            </StyledRow>

            <FormActions>
                <Button onClick={() => navigate(-1)}>Back</Button>

                <BusyButton
                    color="primary"
                    variant="contained"
                    disabled={!name}
                    busy={createLoading}
                    onClick={handleCreate}
                >
                    Create
                </BusyButton>
            </FormActions>
        </Box>
    )
}

const StyledRow = styled('div')({
    display: 'grid',
    gridTemplateColumns: '12rem 1fr',
    alignItems: 'baseline',
    marginBottom: '1rem'
})

export default connect(
    (state: AppState) => ({
        currentUser: selectCurrentUser(state)
    }),
    (dispatch: AppDispatch) => ({
        handleSetCurrentOrg: (org: Organization) => {
            dispatch(accountSetCurrentOrganization(org))
        }
    })
)(CreateOrganization);
