import { combineReducers, createAction, createReducer, createSelector, Selector } from "@reduxjs/toolkit";
import { Blueprint } from "../../repository/models/Blueprint";
import { AppState } from "../../store";
import { selectCurrentProject, selectCurrentProjectContext } from "../project/projectSlice";
import { BLUEPRINT_ROOT, LeafRoute } from "../routes";
import { getRouterUrlParams, isRouterUrlVisit } from "../routes/routeSlice";
import blueprintApi from "./api";
import { skipToken } from "@reduxjs/toolkit/query";

export const clearCurrentBlueprint = createAction("blueprint/clearCurrent");

type BlueprintContext = Readonly<Record<"org"|"project"|"blueprint", string>>;

const blueprintContextReducer = createReducer<BlueprintContext|null>(null, builder => builder
    .addCase(clearCurrentBlueprint, () => null)
    .addMatcher(isRouterUrlVisit(BLUEPRINT_ROOT.wildcard), (state, action) => {
        const match = getRouterUrlParams(BLUEPRINT_ROOT.wildcard, action);
        if(match !== null) {
            return match.params;
        }
        return state;
    })
);


export default combineReducers({
    currentContext: blueprintContextReducer,
})


function selectSlice(state: AppState) {
    return state.blueprint;
}

const selectBlueprints = createSelector(
    (state: AppState) => state,
    selectCurrentProject,
    (state, project) => {
        const data = blueprintApi.endpoints.getBlueprints.select(project ?? skipToken)(state);

        if(data.isError) {
            return [];
        }

        return data.data ?? [];
    }
);

const selectCurrentBlueprintContext = createSelector(
    selectSlice,
    selectCurrentProjectContext,
    (slice, context) => {
        if(context === null) {
            // Outside project context, we can't have a current blueprint either
            return null;
        }
        if (slice.currentContext?.org === context.org && slice.currentContext?.project === context.project) {
            return slice.currentContext;
        }
        return null;
    }
)

export const selectCurrentBlueprint: Selector<AppState, Blueprint | null>  = createSelector(
    selectCurrentBlueprintContext,
    selectBlueprints,
    (context, blueprints) => {
        if(!context) {
            return null;
        }
        return blueprints.filter(blueprint => blueprint.name === context.blueprint)[0] ?? null;
    }
)

// This is a bit of an unconventional selector: instead of just returning a data-object,
// it returns a function. This function is used to generate paths for different routes.
export const selectCurrentBlueprintRouteGenerator = createSelector(
    selectCurrentBlueprintContext,
    (context) => function generateRoute<Variables extends keyof BlueprintContext>(target: LeafRoute<string, Variables>, additionalParams: Record<Exclude<Variables, keyof BlueprintContext>, string>) {
        if(context) {
            return target.generate({
                ...context,
                ...additionalParams
            });
        }
        return null;
    }
);
