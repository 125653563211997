import React, { ReactNode } from 'react'

interface IamUserNameProps {
  firstName: string|null,
  lastName: string|null,
  emptyValue?: ReactNode,
}

export const IamUserName = ({firstName, lastName, emptyValue = <>&nbsp;&mdash;</>}: IamUserNameProps) => {
  return !firstName && !lastName ? emptyValue : !!firstName && !!lastName ? firstName+' '+lastName : firstName || lastName
}
