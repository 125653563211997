import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link as MuiLink, LinkProps as MuiLinkProps } from "@material-ui/core";

export type LinkProps = MuiLinkProps<typeof RouterLink> & {
    color?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error'
};

export default function AppLink(props: LinkProps) {
    return <MuiLink component={RouterLink} {...props} color={ props.color ?? "inherit" }/>;
}
