import React from "react";
import VisibilityIconOutlined from "@material-ui/icons/VisibilityOutlined";
import PlusIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIconOutlined from "@material-ui/icons/DeleteOutline";

interface VerbIconProps {
    verb: string
}
export const VerbIcon = (props: VerbIconProps) => {
    switch(props.verb) {
        case "read": return <VisibilityIconOutlined fontSize="inherit" color='action' />;
        case "create": return <PlusIcon fontSize="inherit" color='action' />;
        case "update": return <EditIcon fontSize='inherit' color='action' />;
        case "delete": return <DeleteIconOutlined fontSize="inherit" color='action' />;
        default: return null
    }
}
