import { FormControl, FormHelperText } from '@material-ui/core';
import React, { ForwardedRef } from 'react';
import TextInput from '../../ui/input/TextInput';
import { HalFormsProperty } from './api';
import { MATCH_ANYTHING } from './_internal';

type ValidationProps = {
    value: string
    halProperty: HalFormsProperty;
    displayName: string;
    overrideRegexMessage?: string;
    fullWidth?: boolean;
}

// we get all the properties from TextInput in order to pass them along to the wrapped TextInput
// and add it to our own, validation-specific props in ValidationProps.
export type ValidatedTextInputProps = React.ComponentProps<typeof TextInput> & ValidationProps;

export default React.forwardRef(function ValidatedTextInput(props: ValidatedTextInputProps, ref: ForwardedRef<any>) {
    const { value, halProperty, displayName, overrideRegexMessage, fullWidth, ...textInputProps } = props;

    const regexMessage = overrideRegexMessage ?? halProperty.prompt ?? null;
    const lengthMessage = `${displayName} must be between ${halProperty.minLength} and ${halProperty.maxLength} characters long`;

    if (regexMessage === null && halProperty.regex !== MATCH_ANYTHING) {
        console.warn("No message available describing the regex, disabling frontend regex validation.");
    }

    const validation = (value === "") ? null
            : (regexMessage !== null && !value.match(halProperty.regex)) ? regexMessage
            : (value.length < halProperty.minLength || value.length > halProperty.maxLength) ? lengthMessage
            : null;

    

    return (
        <FormControl fullWidth={fullWidth} ref={ref} error={!!validation}>
            <TextInput
                {...textInputProps}
                disabled={props.disabled || !halProperty.enabled}
                value={value}
                fullWidth={fullWidth}
                error={!!validation}
            />
            <FormHelperText>{validation}</FormHelperText>
        </FormControl>
    );

})
