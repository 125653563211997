import { Box, Button, Collapse, Dialog, DialogContent, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useMemo, useState } from 'react';
import {
  Node,
  ReactFlowProvider
} from 'reactflow';
import 'reactflow/dist/style.css';
import { useSearchParams } from 'react-router-dom';

import { Blueprint } from '../../../repository/models/Blueprint';
import { useGetEntityListQuery } from '../api';
import { LayoutFlow } from './LayoutFlow';
import { getEdges, getNodes } from './helpers';
import { EntityNode } from './types';

export const DataModelDiagram = ({ blueprint }: {blueprint: Blueprint}) => {
  const classes = useStyles();
  const [opened, setOpened] = useState(false)

  return (
    <Box marginBottom={2}>
      <Box position='relative'>
        <Button
          className={classes.collapseButton}
          variant='text'
          size='small'
          onClick={() => setOpened(active => !active)}
        >
          {opened ? 'Hide schema' : 'Show schema'}
        </Button>
      </Box>

      <Collapse in={opened} appear={opened} unmountOnExit mountOnEnter>
        <CollapseContent blueprint={blueprint} />
      </Collapse>
    </Box>
  );
}

const CollapseContent = ({blueprint}: {blueprint: Blueprint}) => {
  const {data} = useGetEntityListQuery(blueprint)
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const selectedEntity = searchParams.get("entity");
  const [opened, setOpened] = useState(false)

  const {initNodes, initEdges} = useMemo(() => {
    if(data?._embedded?.entities?.length){
      const nodes = getNodes(data?._embedded?.entities ?? [])

      const initEdges = getEdges(nodes, selectedEntity)

      const initNodes: Node<EntityNode>[] = nodes.map(node => ({
        ...node,
        data: { ...node.data, nodes, edges: initEdges }
      }))
      return {initNodes, initEdges}
    }

    return {initNodes: null, initEdges: null}
  }, [data?._embedded?.entities, selectedEntity]);

  return (
    <>
      <div className={classes.reactFlow}>
        <ReactFlowProvider>
          {!!initNodes?.length ? (
            <LayoutFlow
              blueprint={blueprint}
              initNodes={initNodes}
              initEdges={initEdges}
              isFullScreenMode={false}
              onFullScreenModeToggle={() => setOpened(true)} />
          ) : null}
        </ReactFlowProvider>
      </div>

      <Dialog fullScreen open={opened} scroll="paper">
          <DialogContent>
            <div className={classes.reactFlow}>
              <ReactFlowProvider>
                {!!initNodes?.length ? (
                  <LayoutFlow
                    blueprint={blueprint}
                    initNodes={initNodes}
                    initEdges={initEdges}
                    isFullScreenMode={true}
                    onFullScreenModeToggle={() => setOpened(false)} />
                ) : null}
              </ReactFlowProvider>
            </div>
          </DialogContent>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reactFlow: {
      height: theme.spacing(90),
      width: '100%',
      '& .react-flow__attribution': {
        display: 'none'
      }
    },
    collapseButton: {
      margin: '0 auto',
      display: 'block',
      backgroundColor: theme.palette.common.white,
      position: 'static',
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
      '&::after': {
        position: 'absolute',
        content: '" "',
        height: '1px',
        backgroundColor: theme.palette.divider,
        top: '50%',
        left: 0,
        right: 0,
        zIndex: -1
      }
    }
  })
);
