import { skipToken } from "@reduxjs/toolkit/query/react";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Check, Remove, DeleteForever } from '@material-ui/icons'
import { Box, IconButton, Button, Dialog, DialogContentText } from "@material-ui/core";
import { Typography, DialogActions, DialogTitle } from "@material-ui/core";

import { Organization } from "../../repository/models/Organization";
import { IamRealm } from "../../repository/models/IamRealm";
import { useDeleteRealmAttributeMutation, useGetRealmAttributesQuery } from "./api";
import { resolveTemplate } from "../../hal";
import { IamTable } from "../../ui/table/IamTable";
import { TableHeader } from "../../ui/typography/TableHeader";
import { Row } from "../../ui/table/Row";
import { Cell } from "../../ui/table/Cell";
import { IAM_REALM_CREATE_ATTRIBUTE } from "../routes";
import { IamAttribute } from "../../repository/models/IamAttribute";
import BusyButton from "../../BusyButton";
import RemapPalette from "../../ui/theme/RemapPalette";
import { ServerErrorMessage } from "../../ui/ServerErrorMessage";

interface IamRealmUsersProps {
    org: Organization | null,
    realm?: IamRealm
}

export const IamRealmAttributes = ({ org, realm }: IamRealmUsersProps) => {
    const { data: attributeList, isLoading, error } = useGetRealmAttributesQuery(realm ?? skipToken);
    const [deleteAttribute, { isLoading: isDeleteLoading, error: deleteError }] = useDeleteRealmAttributeMutation();

    const [selectedAttribute, setSelectedAttribute] = useState<IamAttribute | null>(null)

    const attributes = attributeList?._embedded?.attributes ?? []

    const attributeTeplate = useMemo(() => {
        if(!!attributeList){
            return resolveTemplate(attributeList!, 'default')
        }
        return null
    },[attributeList])

    const handleRemove = () => {
        if(!!selectedAttribute){
            deleteAttribute(selectedAttribute).then(response => {
                if('data' in response){
                    setSelectedAttribute(null)
                }
            })
        }
    }

    return (
        <>
            {!!attributeTeplate  && (
                <Box marginBottom={2} display='flex' justifyContent='flex-end'>
                    <Button color='primary' variant='contained' component={Link} to={IAM_REALM_CREATE_ATTRIBUTE.generate({org: org?.name!, realm: realm?.id!})}>Create attribute</Button>
                </Box>
            )}

            <IamTable
                isLoading={isLoading || !org}
                error={error}
                noRecords={attributes.length === 0}
                noRecordsText='No attributes.'
                headers={(
                    <>
                        <TableHeader>Name</TableHeader>
                        <TableHeader width={20}>Type</TableHeader>
                        <TableHeader width={15} isContentCentered>Multivalue</TableHeader>
                        <TableHeader width={10}> </TableHeader>
                    </>
                )}
            >
                {(attributes ?? []).map(attribute => {
                    const isTemplate = !!resolveTemplate(attribute, 'delete')

                    return (
                        <Row key={attribute._links.self.href} borders="horizontal">
                            <Cell>{attribute?.name}</Cell>
                            <Cell width={20}>{attribute?.type}</Cell>
                            <Cell width={15} isContentCentered>
                                {!!attribute?.multivalue ? (
                                    <Check fontSize='inherit' />
                                ) : (
                                    <Remove fontSize='inherit' />
                                )}</Cell>
                            <Cell width={10}>
                                <IconButton
                                    disabled={!isTemplate}
                                    size='small'
                                    onClick={() => setSelectedAttribute(attribute)}
                                ><DeleteForever /></IconButton>
                            </Cell>
                        </Row>
                    )}
                )}
            </IamTable>

            <Dialog open={selectedAttribute !== null} onClose={() => setSelectedAttribute(null)}>
                <DialogTitle>Delete attribute {selectedAttribute?.name}</DialogTitle>
                <DialogContentText component='div'>
                    <Typography>Are you sure you want to delete attribute <em>{selectedAttribute?.name}</em>?</Typography>
                    <Typography>Any policies that are still using this attribute will always deny access.</Typography>
                </DialogContentText>

                {!!deleteError ? (
                    <Box marginTop={2} marginBottom={2}>
                        <ServerErrorMessage error={deleteError} />
                    </Box>
                ) : null}
                <DialogActions>
                    <Button color="secondary" onClick={() => setSelectedAttribute(null)}>Cancel</Button>
                    <RemapPalette from="danger" to="primary">
                        <BusyButton
                            color='primary'
                            busy={isDeleteLoading}
                            variant="contained"
                            disableElevation
                            onClick={handleRemove}
                        >Delete</BusyButton>
                    </RemapPalette>
                </DialogActions>
            </Dialog>
        </>
    )
}
