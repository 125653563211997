import { RouteObject } from "react-router-dom";
import { RouteImpl } from "./RouteImpl";
import { ChildrenRoutesOption, RootRoute } from "./types";

export class RootRouteImpl<AncillaryData = unknown> extends RouteImpl<"", never, AncillaryData> implements RootRoute<AncillaryData> {
    protected override rootRoute: RootRoute;
    public constructor(data: AncillaryData) {
        super("", null!, data);
        this.rootRoute = this;
    }
    public createRouteObjects(): RouteObject[] {
        return this.routes({ self: true, children: ChildrenRoutesOption.ALL })
            .flatMap(route => [
                {
                    path: route.pattern,
                }
            ]);
    }
}
