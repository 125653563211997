import { Typography, createStyles, makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';

interface PageTitleProps {
    children: ReactNode
}

export const PageTitle = ({ children }: PageTitleProps) => {
    const { wrapper, text } = useStyles()
    return (
        <Typography className={wrapper} component='h3'>
            <span className={text}>{children}</span>
        </Typography>
    )
}

const useStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            marginBottom: '1rem',
        },
        text : {
            fontWeight: 500,
            fontSize: '1.5rem',
        }
    }),
);

