import { Dialog, DialogActions, DialogTitle, Button, DialogContentText, DialogContent  } from "@material-ui/core";
import React from "react";

import BusyButton from "../../../../BusyButton";
import { resolveTemplate } from "../../../../hal";
import { useLeaveRealmGroupMutation } from "../../api";
import { RequestStateHandler } from "../../../../ui/RequestStateHandler";
import { IamUser, IamUserGroup } from "../../../../repository/models/IamUser";
import { IamUserName } from "../../components/IamUserName";

interface ConfirmationDialogProps {
    group: IamUserGroup,
    user: IamUser,
    opened: boolean,
    onClose: () => void
}

export const ConfirmationDialog = ({ group, user, opened, onClose }: ConfirmationDialogProps) => {
    const [leaveRealmGroup, {isLoading, error}] = useLeaveRealmGroupMutation()

    const memberTemplate = resolveTemplate(group, 'leave-group');

    const handleClick = async () => {
        await leaveRealmGroup(group).unwrap()
        onClose()
    }

    return (
        <Dialog open={opened} maxWidth='lg' onClose={onClose}>
            <DialogTitle>Leave group</DialogTitle>
           
            <DialogContent>
                <DialogContentText>Are you sure you want to remove user <b><IamUserName firstName={user.first_name} lastName={user.last_name} emptyValue={<></>} /></b> from group <b>{group.name}</b>?</DialogContentText>
                {!!error ? (
                    <RequestStateHandler isLoading={false} error={error} />
                ) : null}
            </DialogContent>
         
            <DialogActions>
                <Button color="secondary" onClick={onClose}>Cancel</Button>
                <BusyButton
                    color="primary"
                    busy={isLoading}
                    variant="contained"
                    disableElevation
                    disabled={!memberTemplate}
                    onClick={handleClick}
                >Leave</BusyButton>
            </DialogActions>
        </Dialog>
    )
}
