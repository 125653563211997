import React, { ReactNode, useCallback, useEffect, useReducer } from "react";
import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import RemapPalette from "../theme/RemapPalette";
import BusyButton from "../../BusyButton";
import { SerializedError } from "@reduxjs/toolkit";
import { ServerErrorMessage } from "../ServerErrorMessage";

export interface DeleteButtonProps {
    onDeleteConfirm: () => void;
    onDeleteCancel: () => void;
    icon?: ButtonProps["startIcon"];
    variant: ButtonProps["variant"];

    isLoading: boolean;
    error: SerializedError | undefined;

    dialogTitle: ReactNode;
    children: ReactNode;
}

interface DialogState {
    userRequested: boolean;
    isLoading: boolean;
    hasError: boolean;
}

const initialState: DialogState = {
    userRequested: false,
    isLoading: false,
    hasError: false
}

enum DialogAction {
    OPEN,
    CANCEL,
    TOGGLE_LOADING,
    TOGGLE_ERROR
}

function reducer(state: DialogState, action: DialogAction): DialogState {
    switch(action) {
        case DialogAction.OPEN:
            return {
                ...state,
                userRequested: true
            }
        case DialogAction.CANCEL:
            return initialState;
        case DialogAction.TOGGLE_LOADING:
            return {
                ...state,
                isLoading: !state.isLoading,
            };
        case DialogAction.TOGGLE_ERROR:
            return {
                ...state,
                hasError: !state.hasError,
            }
    }
}

export default function DeleteButton({onDeleteCancel, ...props}: DeleteButtonProps) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const cancel = useCallback(() => {
        dispatch(DialogAction.CANCEL);
        onDeleteCancel();
    }, [dispatch, onDeleteCancel]);

    useEffect(() => {
        if(props.isLoading !== state.isLoading) {
            dispatch(DialogAction.TOGGLE_LOADING);
        }
        if(!!props.error !== state.hasError) {
            dispatch(DialogAction.TOGGLE_ERROR);
        }
    }, [props.isLoading, props.error, state.isLoading, state.hasError, dispatch]);

    const open = state.userRequested || state.isLoading || state.hasError;

    return <RemapPalette from="danger" to="primary">
        <Dialog open={open} onClose={cancel}>
            <DialogTitle>{props.dialogTitle}</DialogTitle>
            <DialogContent>
                {props.children}
                <ServerErrorMessage error={props.error} />
            </DialogContent>
            <DialogActions>
                <Button disabled={props.isLoading} onClick={cancel}>Cancel</Button>
                <BusyButton busy={props.isLoading} onClick={props.onDeleteConfirm}
                    variant="contained"
                    color="primary">
                    Delete
                </BusyButton>
            </DialogActions>
        </Dialog>
        <Button
            color="primary"
            variant={props.variant}
            startIcon={props.icon}
            onClick={() => dispatch(DialogAction.OPEN)}
        >
            Delete
        </Button>
    </RemapPalette>
}
