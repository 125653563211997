import { styled } from "@material-ui/core";
import React from "react";


import Link from '../../ui/Link';
import { theme } from "../../theme";
import { Row, RowProps } from "./Row";

interface RowLinkProps extends RowProps{
    link: string,
}

export const RowLink = ({ link, borders, ...props }: RowLinkProps) => {
    return (
        <StyledLink to={link}>
            <Row borders={borders ??"horizontal"} {...props} />
        </StyledLink>
    );
}

const StyledLink = styled(Link)({
    display: "block",
    backgroundColor: 'transparent',
    transition: "background-color 200ms ease-in",
    '&:hover': {
        backgroundColor: theme.palette.grey[100],
        transition: "background-color 200ms ease-out"
    }
})
