import React, { useState } from "react";
import { Box, Button } from "@material-ui/core";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { Organization } from "../../../repository/models/Organization";
import { useCancelOrganizationInviteMutation, useGetOrganizationInvitesQuery } from "../api";
import { InviteDialog } from "./components/InviteDialog";
import { MembersTable } from "./components/MembersTable";
import { InvitesTable } from "./components/InvitesTable";
import { OrganizationInvite } from "../../../repository/models/Invite";
import { toast } from "react-toastify";
import { AppState } from "../../../store";
import { selectCurrentOrganization } from "../../account/accountSlice";
import { connect } from "react-redux";
import { PageTitle } from "../../../ui/typography/PageTitle";
import { RequestStateHandler } from "../../../ui/RequestStateHandler";

interface MembersTabProps {
    organization: Organization | null,
}

const MembersTab = ({ organization }: MembersTabProps) => {
    const [ opened, setOpened ] = useState(false)

    const {data: inviteList, isLoading, error} = useGetOrganizationInvitesQuery(organization ?? skipToken)
    const [cancelOrganizationInvite, {isLoading: cancelLoading, error: cancelError}] = useCancelOrganizationInviteMutation()

    if(!organization) {
        return null;
    }

    const invitations = inviteList?._embedded?.invitations;

    const handleCancel = (invite: OrganizationInvite) => {
        cancelOrganizationInvite({org: organization, invite}).then(response => {
            if("data" in response){
                toast.success('Invitation was canceled', { toastId: 'cancel-invitation', closeButton: false })
            }
        })
    }

    return (
        <div>
            {inviteList && <Box mb={2}>
                <Button variant='contained' color='primary' onClick={() => setOpened(true)}>Invite user</Button>

                <InviteDialog
                    opened={opened}
                    inviteList={inviteList}
                    onClose={() => setOpened(false)}
                />
            </Box>
            }

            <MembersTable organization={organization} />

            <PageTitle>Invites</PageTitle>
            {isLoading || !!error ? (
                <RequestStateHandler isLoading={isLoading} error={error} />
            ) : (
                <InvitesTable invites={invitations ?? []} loading={cancelLoading} error={cancelError} onCancel={handleCancel} />
            )}
        </div>

    );
}

export default connect((state: AppState) => ({
    organization: selectCurrentOrganization(state)
}))(MembersTab);
