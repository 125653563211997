import { skipToken } from "@reduxjs/toolkit/query";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { useMatch } from 'react-router-dom';

import { Blueprint } from "../../repository/models/Blueprint";
import { AppState } from "../../store";
import Link from "../../ui/Link";
import { selectCurrentBlueprint } from "../blueprint/blueprintSlice";
import { useGetEntityListQuery } from "../datamodel/api";
import { BLUEPRINT_EVENTHANDLERS, EVENTHANDLERS_NEW } from "../routes";
import AutoBreadcrumbs from "../routes/AutoBreadcrumbs";
import { EventHandlersWrapper } from "./components/EventHandlersWrapper";
import { EventHandlerForm } from "./components/EventHandlerForm";
import { paramsToRecord } from "../routes/helpers";
import IDataFetcher from "../../repository/IDataFetcher";
import { EventHandlerCreationDto } from "../../repository/models/EventHandlerDto";
import { useAddEventHandlerMutation } from "./api";
import { RequestStateHandler } from "../../ui/RequestStateHandler";

interface EventHandlerCreateProps {
    currentBlueprint: Blueprint;
    fetcher: IDataFetcher,
}

function EventHandlerCreate(props: EventHandlerCreateProps) {
    const { data: entityList, isLoading, error } = useGetEntityListQuery(props.currentBlueprint ?? skipToken);
    const entities = entityList?._embedded?.entities;

    const routeMatchNew = useMatch({ path: EVENTHANDLERS_NEW.pattern })!;

    const [addEventHandler, {isLoading: addLoading, error: addError}] = useAddEventHandlerMutation();

    const handleSave = useCallback(async (data: EventHandlerCreationDto) => {
        await addEventHandler({blueprint: props.currentBlueprint!, eventHandler: data})
    }, [props.currentBlueprint, addEventHandler]);

    if(isLoading || !!error) {
        return <RequestStateHandler isLoading={isLoading} error={error} />
    }

    if(!!entities) {
        const parentUrl = BLUEPRINT_EVENTHANDLERS.generate(paramsToRecord(routeMatchNew!.params)!);
        return (
            <>
                <AutoBreadcrumbs collapseDefaults>
                    <Link to={parentUrl}>Webhooks</Link>
                    <span>Create New Webhook</span>
                </AutoBreadcrumbs>

                <EventHandlersWrapper>
                    <EventHandlerForm
                        entities={entities}
                        parentUrl={parentUrl}
                        loading={addLoading}
                        error={addError}
                        onSave={handleSave}
                    />
                </EventHandlersWrapper>
            </>
        );
    }

    return null

}

export default connect((state: AppState) => ({
    currentBlueprint: selectCurrentBlueprint(state)!,
}))(EventHandlerCreate);
