import React, { useCallback } from "react";
import { connect } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { BLUEPRINT_PERMISSIONS, PERMISSIONS_EDIT } from "../routes";
import { Blueprint } from "../../repository/models/Blueprint";
import { PolicyCreationDto } from "../../repository/models/PolicyDto";
import { AppState } from "../../store";
import { selectCurrentBlueprint } from "../blueprint/blueprintSlice";
import { useGetPolicyQuery, useUpdatePolicyMutation } from "./api";
import { PolicyModelForm } from "./PolicyModelForm";
import Link from "../../ui/Link";
import AutoBreadcrumbs from "../routes/AutoBreadcrumbs";
import { paramsToRecord } from "../routes/helpers";
import { RequestStateHandler } from "../../ui/RequestStateHandler";

type PolicyModelProps = {
    currentBlueprint: Blueprint,
}

function PolicyModelEdit(props: PolicyModelProps) {
    const routeMatchEdit = useMatch({ path: PERMISSIONS_EDIT.pattern })!;

    const navigate = useNavigate();

    const { data: policy, isLoading, error } = useGetPolicyQuery(
        { blueprint: props.currentBlueprint, id: routeMatchEdit.params.policy! }
    );

    const [updatePolicy, { isLoading: isSaving, error: updateError }] = useUpdatePolicyMutation();

    const parentUrl = BLUEPRINT_PERMISSIONS.generate(paramsToRecord(routeMatchEdit!.params)!) + '?entity='+policy?.entity;

    const backToOverview = useCallback(() => {
        navigate(parentUrl);
    }, [navigate, parentUrl]);

    const onSave = useCallback(async (data: PolicyCreationDto) => {
        await updatePolicy({policy: policy!, update: data})
        backToOverview();
    }, [updatePolicy, policy, backToOverview]);

    if(isLoading || !!error){
        return <RequestStateHandler isLoading={isLoading} error={error} />
    }

    if (!policy) {
        return null;
    }


    return <>
        <AutoBreadcrumbs collapseDefaults>
            <Link to={parentUrl}>
                Permissions for <strong>{policy.entity}</strong>
            </Link>
            <>Edit Policy</>
        </AutoBreadcrumbs>
        <PolicyModelForm
            currentBlueprint={props.currentBlueprint}
            entityName={policy.entity}
            policy={policy}
            isSaving={isSaving}
            error={updateError}
            onSave={onSave}
            onCancel={backToOverview}
            onDelete={backToOverview}
        />
    </>

}

export default connect((state: AppState) => ({
    currentBlueprint: selectCurrentBlueprint(state)!,
}))(PolicyModelEdit);
