import { Chip, createStyles, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Typography, Link as MuiLink } from "@material-ui/core";
import React, { ForwardedRef, ReactNode } from "react";
import { useMatch, useLocation } from "react-router-dom";
import BusinessIcon from '@material-ui/icons/Business';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import WidgetsIcon from '@material-ui/icons/Widgets';
import ExtensionIcon from '@material-ui/icons/Extension';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import IamIcon from '@material-ui/icons/People';
import { connect, useSelector } from "react-redux";

import { selectCurrentBlueprint, selectCurrentBlueprintRouteGenerator } from "./features/blueprint/blueprintSlice";
import { AppState } from "./store";
import { StyledNavLink } from "./ui/styles/StyledNavLink";
import { BLUEPRINT_ROOT, PROJECT_BLUEPRINTS, BLUEPRINT_MODEL, BLUEPRINT_PERMISSIONS, BLUEPRINT_EVENTHANDLERS, ORGANIZATION_ROOT, ORGANIZATION_DASHBOARD, PROJECT_INSTANCES, PERMISSIONS_ROOT, EVENTHANDLERS_ROOT, IAM_ROOT, INSTANCES_ROOT, Route, ORGANIZATION_MEMBERS, ORGANIZATION_SETTINGS, PROJECT_RELEASES, RELEASE_SHOW, IAM_REALM, IAM_REALM_ATTRIBUTES, IAM_REALM_CLIENTS, IAM_REALM_GROUPS, IAM_REALM_OVERVIEW, IAM_REALM_SSO, IAM_REALM_USERS, IAM_REALM_USER, IAM_REALM_GROUP, IAM_REALM_CREATE_USER, RELEASE_CREATE, IAM_REALM_CREATE_ATTRIBUTE, IAM_REALM_CREATE_GROUP, IAM_REALM_EDIT_USER, IAM_REALM_EDIT_GROUP, DATAMODEL_ROOT } from "./features/routes";
import { Blueprint } from "./repository/models/Blueprint";
import { HighlightedComponent, blueprintSelectLocation, releasesCreateLocation, datamodelAddAttributeLocation, permissionAddPolicyLocation, applicationCreateLocation, applicationDeployLocation, applicationVisitLocation, iamCreateUserLocation, datamodelAddEntityLocation, projectSelectLocation } from "./features/tour";
import { selectCurrentProject, selectCurrentProjectRouteGenerator } from "./features/project/projectSlice";
import { HighlightedComponentProps } from "./features/tour/HighlightedComponent";
import { documentationUrl } from "./documentation";

interface NavItemProps {
    readonly to: string | null;
    readonly activeRoutes?: ReadonlyArray<Route>;
    readonly title: string;
    readonly icon?: ReactNode;
    readonly children?: ReactNode;
    readonly className?: string;
}
const NavItem = React.forwardRef(({to, title, icon = null, activeRoutes = [], children, className}: NavItemProps, ref: ForwardedRef<any>) => {
    const path = useLocation().pathname;
    const matchesTo = useMatch({ path: to ?? ""});
    const matchesExtra = activeRoutes.some(route => route.match(path));

    const match = matchesExtra || matchesTo !== null;

    return (
        <ListItem
            ref={ref}
            component={to ? StyledNavLink : undefined!}
            to={to!}
            selected={to === null ? false : match}
            disabled={to === null}
            className={className}
        >
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText primary={<Typography variant='body2'>{title}</Typography>} />
            {children ?? null}
        </ListItem>
    );
});

function HightlightedNavItem(props: NavItemProps & Omit<HighlightedComponentProps, "children">) {
    const path = useLocation().pathname;
    const matchesTo = useMatch({ path: props.to ?? ""});
    const matchesExtra = props.activeRoutes?.some(route => route.match(path));

    const match = matchesExtra || matchesTo !== null;

    return <HighlightedComponent
        location={props.location}
        disabled={props.disabled || props.to === null || match}
        markVisitedOnClick={props.markVisitedOnClick}
        tip={props.tip}
    >
        <NavItem
            to={props.to}
            title={props.title}
            icon={props.icon}
            activeRoutes={props.activeRoutes}
            children={props.children}
            className={props.className}
        />
    </HighlightedComponent>
}

interface BlueprintsNavItemProps {
    readonly blueprint: Blueprint | null;
}

function _BlueprintsNavItem({ blueprint }: BlueprintsNavItemProps) {
    const hasProjectContext = useSelector(selectCurrentProject) !== null;
    const projectPathGenerator = useSelector(selectCurrentProjectRouteGenerator);
    const blueprintPathGenerator = useSelector(selectCurrentBlueprintRouteGenerator);

    return <>
        <HightlightedNavItem
            location={[blueprintSelectLocation, releasesCreateLocation]}
            disabled={!hasProjectContext}
            title="Blueprints"
            icon={<MenuBookIcon />}
            to={blueprintPathGenerator(BLUEPRINT_ROOT, {}) ?? projectPathGenerator(PROJECT_BLUEPRINTS, {})}
            activeRoutes={[
                BLUEPRINT_ROOT,
                PROJECT_BLUEPRINTS,
                PROJECT_RELEASES,
                RELEASE_SHOW,
                RELEASE_CREATE
            ]}
        >
                {blueprint ? <Chip color="primary" size="small" label={blueprint.name} /> : null}
        </HightlightedNavItem>

        <List component="div">
            <HightlightedNavItem
                location={[datamodelAddEntityLocation, datamodelAddAttributeLocation]}
                title="Data model"
                icon={<AccountTreeIcon />}
                to={blueprintPathGenerator(BLUEPRINT_MODEL, {})}
                activeRoutes={[
                    BLUEPRINT_MODEL,
                    DATAMODEL_ROOT.wildcard
                ]}
            />
            <HightlightedNavItem
                location={[permissionAddPolicyLocation]}
                title="Permissions"
                icon={<VerifiedUserIcon />}
                to={blueprintPathGenerator(BLUEPRINT_PERMISSIONS, {})}
                activeRoutes={[
                    PERMISSIONS_ROOT.wildcard
                ]}
            />
            <NavItem
                title="Automations"
                icon={<ExtensionIcon />}
                to={blueprintPathGenerator(BLUEPRINT_EVENTHANDLERS, {})}
                activeRoutes={[
                    EVENTHANDLERS_ROOT.wildcard
                ]}
            />
        </List>
    </>
}

const BlueprintsNavItem = connect((state: AppState) => ({
    blueprint: selectCurrentBlueprint(state),
}))(_BlueprintsNavItem);

export default function NavLayout() {
    const routerPath = useLocation().pathname;
    const projectPathGenerator = useSelector(selectCurrentProjectRouteGenerator);

    return <Grid container direction="column" wrap="nowrap" justifyContent="space-between">
        <Grid item >
        <List component="nav" aria-labelledby="nested-list-subheader">
                <HightlightedNavItem
                    location={projectSelectLocation}
                    title="Organization"
                    icon={<BusinessIcon />}
                    to={ORGANIZATION_ROOT.navigate(routerPath, ORGANIZATION_DASHBOARD)}
                    activeRoutes={[
                ORGANIZATION_MEMBERS,
                ORGANIZATION_SETTINGS
                    ]}
                />
            {/* <NavItem title="Project dashboard" icon={<HomeIcon />} to={PROJECT_ROOT.navigate(routerPath, PROJECT_DASHBOARD)} /> */}
            <BlueprintsNavItem />
            {/* <NavItem title="Releases" icon={<BookmarksIcon />} to={PROJECT_ROOT.navigate(routerPath, PROJECT_RELEASES)} /> */}
                <HightlightedNavItem
                    location={[applicationCreateLocation, applicationDeployLocation, applicationVisitLocation]}
                    title="Applications"
                    icon={<WidgetsIcon />}
                    to={projectPathGenerator(PROJECT_INSTANCES, {})}
                    activeRoutes={[
                    INSTANCES_ROOT.wildcard
                    ]}
                />
                <HightlightedNavItem
                    location={iamCreateUserLocation}
                    title="IAM"
                    icon={<IamIcon />}
                    to={ORGANIZATION_ROOT.navigate(routerPath, IAM_ROOT)}
                    activeRoutes={[
                        IAM_REALM, IAM_REALM_ATTRIBUTES, IAM_REALM_CLIENTS, IAM_REALM_GROUPS, IAM_REALM_OVERVIEW, IAM_REALM_SSO, IAM_REALM_USERS, IAM_ROOT, IAM_REALM_USER, IAM_REALM_CREATE_USER, IAM_REALM_GROUP, IAM_REALM_CREATE_ATTRIBUTE, IAM_REALM_CREATE_GROUP, IAM_REALM_EDIT_USER, IAM_REALM_EDIT_GROUP
                    ]}
                />
        </List>
        </Grid>
        <NavLinks />
    </Grid>;
}


function NavLinks() {
    const classes = useNavLinkStyles();
    return <Grid item className={classes.root}>
        <MuiLink href={documentationUrl} target="_blank" rel="noopener" className={classes.link}>Documentation</MuiLink>
        &nbsp;&bull;&nbsp;
        <MuiLink href="https://contentgrid.slack.com" target="_blank" rel="noopener" className={classes.link}>Slack</MuiLink>
    </Grid>
}

const useNavLinkStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        position: "sticky",
        bottom: 0,
        margin: theme.spacing(1),
        color: theme.palette.grey[500],
        ...theme.typography.body2
    },
    link: {
        color: theme.palette.grey[500],
    }
}));
