import React from 'react'
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

import { Deployment } from "../../../../../repository/models/Deployment";
import { ApplicationDeploymentIcon } from '../../../components/ApplicationDeploymentIcon';
import { deploymentStatusText } from '../../../helpers';

export const DeploymentsList = (props: {deployments: Deployment[]}) => <>{props.deployments.map((deployment) => (
  <ListItem key={deployment.created} disableGutters>
      <ListItemIcon>
        <ApplicationDeploymentIcon status={deployment.status} />
      </ListItemIcon>
      <ListItemText
          primary={'Started at ' + new Date(deployment.created).toLocaleString()}
          secondary={deploymentStatusText(deployment.status)}
      />
  </ListItem>
))}</>
