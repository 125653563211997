import React, { ReactNode } from "react";

import { Box, CircularProgress, Typography } from "@material-ui/core";

interface TextLoaderProps {
    children: ReactNode,
    isLoading: boolean,
}

export const TextLoader = ({ children, isLoading }: TextLoaderProps) => {
    if(!isLoading){
        return (
            <Typography component='div'>{children}</Typography>
        )
    }

    return (
        <Box sx={{ display: 'inline-flex', justifyContent: 'center', minWidth: '170px' }}>
            <CircularProgress />
        </Box>
    )
}
