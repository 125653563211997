import { Box, Typography } from "@material-ui/core";
import React, { ReactChild } from "react";

interface ActionProps {
    description: string;
    children: ReactChild
}

export default function Action(props: ActionProps) {
    return <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography>{props.description}</Typography>
        {props.children}
    </Box>
}
