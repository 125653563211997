import React from "react";
import { ReactNode } from "react"
import { connect } from "react-redux";
import { User } from "../../repository/models/User";
import { AppState } from "../../store";
import { selectCurrentUser } from "../account/accountSlice";

type IfAuthenticatedProps = {
    currentUser: User|null,
    children: ReactNode
}
function IfAuthenticated({ children, currentUser }: IfAuthenticatedProps) {
    if(currentUser) {
        return <>{children}</>
    }
    return null;
}

export default connect((state: AppState) => ({
    currentUser: selectCurrentUser(state),
}))(IfAuthenticated);
