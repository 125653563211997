import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import { Link as RouterLink } from "react-router-dom";

import { connect } from "react-redux";
import { AppState } from "../../store";
import { Project } from "../../repository/models/Project";
import { selectCurrentProject } from "../project/projectSlice";
import { useGetReleasesForProjectQuery } from "./api";
import AutoBreadcrumbs from "../routes/AutoBreadcrumbs";
import { Row } from "../../ui/table/Row";
import { ReleasesRow } from "./components/ReleasesRow";
import { TableHeader } from "../../ui/typography/TableHeader";
import { RequestStateHandler } from "../../ui/RequestStateHandler";
import { Alert } from "@material-ui/lab";
import { useGetBlueprintOverviewsQuery } from "../blueprint/api";
import { useVisitedTourLocation } from "../tour/hooks";
import { releasesCreateLocation } from "../tour";
import { RELEASE_CREATE_WITHOUT_SELECTED_BLUEPRINT } from "../routes";
import { Organization } from "../../repository/models/Organization";
import { selectCurrentOrganization } from "../account/accountSlice";

type ReleasesProps = {
    project: Project|null,
    organization: Organization|null
}

const compareRecency = (a: { created: string }, b: { created: string }) => new Date(b.created).getTime() - new Date(a.created).getTime();

function Releases ({ project, organization }: ReleasesProps) {
    return (
        <>
            <AutoBreadcrumbs>Releases</AutoBreadcrumbs>

            <ReleasesTable
                project={project}
                organization={organization}
            />
        </>
    );
}

export default connect((state: AppState) => ({
    project: selectCurrentProject(state),
    organization: selectCurrentOrganization(state)
}))(Releases)

type ReleasesTableProps = {
    project: Project | null,
    organization: Organization|null
}

export const ReleasesTable = ({project, organization}: ReleasesTableProps) => {
    const {data: releases, isLoading, error}  = useGetReleasesForProjectQuery(project ?? skipToken)
    const {data: blueprints, isLoading: blueprintsLoading, error: blueprintsError} = useGetBlueprintOverviewsQuery(project ?? skipToken)

    useVisitedTourLocation(releasesCreateLocation, () => releases ? releases.length > 0 : false, [releases]);

    const haveChanges = !!blueprints?.find(item => item.unreleased_changes !== 0)

    if(isLoading || blueprintsLoading || !!error || !!blueprintsError){
        return (
            <RequestStateHandler isLoading={isLoading || blueprintsLoading} error={error || blueprintsError} />
        )
    }

    if(!releases?.length) {
        const content = haveChanges
            ? <Typography variant='body2'>There are no releases yet. You can create a release using button <em>Create Release</em> below</Typography>
            : <Typography variant='body2'>There are no releases yet. Make some changes in the <em>Data model</em>, then come back to create a release.</Typography>;
        return (
            <>
                <Box marginBottom={2}>
                    <Alert severity="info">{content}</Alert>
                </Box>

                <CreateReleaseButton project={project} organization={organization} />
            </>
        )
    }

    return (
        <>
            <CreateReleaseButton project={project} organization={organization} />

            <Row>
                <TableHeader width={10}>Label</TableHeader>
                <TableHeader>Message</TableHeader>
                <TableHeader>Creator</TableHeader>
                <TableHeader width={20}>Created</TableHeader>
            </Row>

            {releases
                .slice()
                .sort(compareRecency)
                .map((release, index) =>
                    <ReleasesRow
                        key={release.label}
                        release={release}
                        project={project}
                        isNotFirstDescendant={index !== 0}
                    />
                )}
        </>
    )
}

const CreateReleaseButton = ({project, organization}: {project: Project | null, organization: Organization|null}) => {
    if(organization && project){
        return (
            <Box marginBottom={2}>
                <Button
                    variant='contained'
                    color='primary'
                    component={RouterLink}
                    size="small"
                    to={RELEASE_CREATE_WITHOUT_SELECTED_BLUEPRINT.generate({ org: organization.name, project: project.name })}
                >
                    Create Release
                </Button>
            </Box>
        )
    }

    return null;
}
