import { IconButton, Dialog, DialogTitle, CircularProgress, DialogContent } from "@material-ui/core";
import React, { Suspense } from "react";
import CloseIcon from '@material-ui/icons/Close';
import { useGetOpenApiSpecForReleaseQuery, useGetReleasesForProjectQuery } from "./api";
import { skipToken } from "@reduxjs/toolkit/query";
import { connect } from "react-redux";
import { selectCurrentProject } from "../project/projectSlice";
import { AppState } from "../../store";
import { RELEASE_SHOW, RELEASE_SHOW_OPENAPI } from "../routes";
import { NavLink, useLocation } from "react-router-dom";
import { Project } from "../../repository/models/Project";

type OpenApiDialogProps = {
    project: Project|null,
}

const LoadableOpenApiDialog = React.lazy(() => import('./LoadableOpenApiDialog'));

function OpenApiDialog({ project }: OpenApiDialogProps) {
    const url = useLocation();
    const match = RELEASE_SHOW_OPENAPI.match(url.pathname)!;
    const {data: releases} = useGetReleasesForProjectQuery(project ?? skipToken);

    const release = releases?.filter(pub => pub.label === match.params.release)[0];

    const {data: openApiSpec, isLoading} = useGetOpenApiSpecForReleaseQuery(release ?? skipToken);


    return <Dialog fullScreen open={release !== null} scroll="paper">
        <DialogTitle>
            <IconButton
                color="inherit"
                aria-label="close"
                component={NavLink}
                to={RELEASE_SHOW.generate(match.params)}
            >
            <CloseIcon/></IconButton>OpenAPI Specification for Release {release?.label} </DialogTitle>
        <DialogContent>
            <Suspense fallback={<CircularProgress />}>
                {isLoading ? <CircularProgress /> : <LoadableOpenApiDialog spec={openApiSpec!} />}
            </Suspense>
        </DialogContent>
    </Dialog>
}


export default connect((state: AppState) => ({
    project: selectCurrentProject(state)
}))(OpenApiDialog)
