import { createLocation } from "./TourLocation";

export const organizationJoinLocation = createLocation("organization/join");
export const projectSelectLocation = createLocation("project/select");

export const blueprintSelectLocation = createLocation("blueprint/select");
export const blueprintReleaseLocation = createLocation("blueprint/release");

export const datamodelAddEntityLocation = createLocation("data-model/add-entity");
export const datamodelAddAttributeLocation = createLocation("data-model/add-attribute");
export const permissionAddPolicyLocation = createLocation("permission-model/policy/add")
export const webhooksNavigationLocation = createLocation("webhooks/nav");

export const releasesCreateLocation = createLocation("releases/create");

export const applicationCreateLocation = createLocation("applications/create");
export const applicationDeployLocation = createLocation("applications/deploy");
export const applicationVisitLocation = createLocation("applications/visit");

export const iamCreateUserLocation = createLocation("iam/user/create");
