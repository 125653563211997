import React from "react";

import { BLUEPRINT_ROOT, PROJECT_BLUEPRINTS, PROJECT_RELEASES, PROJECT_ROOT } from "../routes";
import AutoBreadcrumbs from "../routes/AutoBreadcrumbs";
import LinkTabs from "../../ui/tabs/LinkTabs";
import LinkTab from "../../ui/tabs/LinkTab";
import { Outlet } from "react-router-dom";

export default function Blueprints() {
    return (<>
        <AutoBreadcrumbs>
            <>Blueprints</>
        </AutoBreadcrumbs>

        <LinkTabs indicatorColor="primary">
            <LinkTab label="Blueprints" from={PROJECT_ROOT} to={PROJECT_BLUEPRINTS} activeRoutes={[
                BLUEPRINT_ROOT
            ]} />
            <LinkTab label="Releases" from={PROJECT_ROOT} to={PROJECT_RELEASES} />
        </LinkTabs>
        <Outlet />
    </>);
}
