import { Box, CircularProgress } from '@material-ui/core'
import React from 'react'

export const ProgressIndicator = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }} marginBottom={2}>
      <CircularProgress />
    </Box>
  )
}
