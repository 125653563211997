import React from "react";
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { Box, Button, IconButton, styled } from "@material-ui/core";

import TextInput from "./TextInput";

interface TextInputListProps {
    kindTitle: string,
    list: readonly string[],
    onChange?: (newList: readonly string[]) => void,
}

export const TextInputList = ({
    kindTitle,
    list,
    onChange,
}: TextInputListProps) => {
    return (
        <StyledWrapper marginLeft={2}>
            <Box display='flex' flexDirection='column' width='100%'>
                {list.map((item, index) => {
                    return (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center' }} mb={2}>
                            <TextInput
                                disabled={!onChange}
                                fullWidth
                                autoFocus
                                value={item}
                                handleOnChange={(value) => onChange && onChange(list.map((val, idx) => idx === index?value: val))}
                            />

                            {onChange ?
                                <StyledButton color='secondary' onClick={() => onChange(list.filter((_value, i) => i !== index))}><RemoveIcon /></StyledButton>
                                : null}
                        </Box>
                    )
                })}
            </Box>

            {onChange ?
                <StyledAddButton
                    variant='text'
                    color='primary'
                    disableRipple
                    onClick={() => onChange([...list.filter(item => !!item), ''])}
                >
                    <AddIcon fontSize='small' />&nbsp;Add {kindTitle}
                </StyledAddButton>
                : null}
        </StyledWrapper>
    );
}

const StyledWrapper = styled(Box)(() => ({
    flex: '1 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    maxWidth: '40rem'
}))

const StyledButton = styled(IconButton)(() => ({
    padding: 0,
    marginLeft: '1rem',
}))

const StyledAddButton = styled(Button)(({theme}) => ({
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(5),
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark
    }
}))
