import React from "react";
import { Token } from "../../repository/models/Policy";

type TokenDisplayProps = {
    token: Token,
    entityName?: string
}

export default function TokenDisplay({token, entityName}: TokenDisplayProps) {
    if (token.type !== 'constant') {
        if (token.type === 'entity' && entityName !== undefined) {
            return <span>{token.toString().replace(/^entity/, entityName)}</span>
        }
        return <span>{token.toString()}</span>;
    }
    let color = token.subType === 'string' ? '#117733' //green
            : token.subType === 'boolean' ? '#332288' //blue
            : token.subType === 'number' ? '#aa4499' //purple?
            : 'black';
    let style = 'normal';
    if (token.subType === 'string' && token.value[0] === '') {
        style = 'italic';
    }
    return <span style={{color: color, fontStyle: style}}>{token.toString()}</span>
}