import React, { useMemo, useState } from 'react'
import { Alert } from '@material-ui/lab';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import { Blueprint } from '../../../repository/models/Blueprint';
import { useRecommendedFeatureActionMutation, useGetBlueprintFeaturesQuery } from '../../blueprint/api';
import { resolveTemplate } from '../../../hal';
import BusyButton from '../../../BusyButton';
import { ServerErrorMessage } from '../../../ui/ServerErrorMessage';

type FeaturesProps = {
    blueprint: Blueprint
}

export const Features = (props: FeaturesProps) => {
    const { data: features } = useGetBlueprintFeaturesQuery(props.blueprint);
    const [recommendedFeatureAction, {error}] = useRecommendedFeatureActionMutation()
    const [loading, setLoading] = useState(false)
    const classes = useStyles();

    const recommendedFeatures = useMemo(() => {
        return features?.filter(item => {
            return !!resolveTemplate(item, 'recommended')  
        })
    },[features])

    const handleApply = async () => {
        const featureList = recommendedFeatures
        if(!!featureList?.length){
            setLoading(true)
            for(let item of featureList){
                await recommendedFeatureAction(item).unwrap()
            }
        }
        setLoading(false)
    }

    if (!recommendedFeatures?.length) {
        return null
    }

    return (
        <Box marginBottom={2}>
            <ServerErrorMessage error={error} />

            <Alert severity='info'>
                <Box display='grid' alignItems='flex-start' gridTemplateColumns='1fr auto' gridColumnGap={16}>
                    <Box>
                        <Typography variant='body2'>There are {recommendedFeatures.length} enhancements ready to apply on your application. However, they change the API in a non backwards-compatible way:</Typography>
                        <ul className={classes.list}>
                            {recommendedFeatures.map(item => {
                                return (
                                    <li key={item.name}>{item.description}</li>
                                )
                            })}
                        </ul>
                    </Box>
                    
                    <BusyButton busy={loading} color='primary' size='small' variant='outlined' onClick={handleApply}>Apply</BusyButton>
                </Box>
            </Alert>
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            margin: 0,
            paddingLeft: theme.spacing(3),
        }
    })
);
