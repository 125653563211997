import React from 'react'
import ClockIcon from '@material-ui/icons/WatchLater';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { theme } from '../../../theme';
import { DeploymentStatus } from '../../../repository/models/Deployment';

export const ApplicationDeploymentIcon = ({status, fontSize = 'default'}: {status: DeploymentStatus, fontSize?: "small" | "default" }) => {
    if(status.failed){
        return <ErrorIcon fontSize={fontSize } htmlColor={theme.palette.error.main}/>
    }

    if(status.finished){
        return <CheckCircleIcon fontSize={fontSize } htmlColor={theme.palette.success.main} />
    }

    return <ClockIcon fontSize={fontSize } htmlColor={theme.palette.primary.main} />
}
