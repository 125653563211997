import React, { ReactNode } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import cx from 'classnames';

interface ControlsWrapperProps {
    children: ReactNode,
    isInlineEditing?: boolean,
}

export const ControlsWrapper = ({
    children,
    isInlineEditing,
}: ControlsWrapperProps) => {
    const classes = useStyles();
    const wrapperClasses = cx(classes.wrapper, isInlineEditing ? classes.inline : null)
    return (
        <div className={wrapperClasses}>
            {children}
        </div>
    )
}

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        wrapper: {
            display: 'flex',
            flexWrap: 'nowrap',
            '& .MuiInputBase-root': {
                fontSize: theme.typography.body2.fontSize,
                position: 'relative',
                height: theme.spacing(4),
                zIndex: 1,
                '&:not(:last-child)': {
                    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`
                }
            },
            '& .MuiFormControl-root:not(:last-child) .MuiInputBase-root': {
                borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`
            },
            '& button': {
                minWidth: theme.spacing(4),
                padding: 0,
                border: '1px solid',
                borderRadius: 0,
                height: theme.spacing(4),
                width: theme.spacing(4),
                '& .MuiSvgIcon-root': {
                    fontSize: theme.typography.body2.fontSize,
                },
                '&:last-child': {
                    borderTopRightRadius: theme.shape.borderRadius,
                    borderBottomRightRadius: theme.shape.borderRadius
                }
            },
        },
        inline: {
            '& .MuiInputBase-root': {
                padding: '0 0.25rem',
                marginLeft: '-5px',
                height: theme.spacing(3),
            },
            '& button': {
                minWidth: theme.spacing(3),
                height: theme.spacing(3),
                width: theme.spacing(3),
            }
        },
    })}
);
