
export class HalError extends Error {
    public constructor(
        message: string
    ) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = HalError.name;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, HalError);
        }
    }
}
