import { matchRoutes, matchPath, RouteMatch } from "react-router-dom";
import { BaseRoute, ChildBaseRoute, PathMatchRequiredVariables, RootRoute, Route, WildcardOnce, WildcardRoute } from "./types";

export interface MatchOptions {
    readonly exact?: boolean;
}

export abstract class BaseRouteImpl<Pattern extends string, Variables extends string = never, AncillaryData = unknown> implements BaseRoute<Pattern, Variables, AncillaryData> {
    public constructor(public readonly pattern: Pattern, protected readonly rootRoute: RootRoute, public readonly data: AncillaryData) {
    }

    public get wildcard(): WildcardRoute<Pattern, Variables, AncillaryData> {
        return new WildcardRouteImpl(this);
    }

    public match(path: string | null | undefined, { exact = true }: MatchOptions = {}): PathMatchRequiredVariables<Variables> | null {
        if (path === null || path === undefined) {
            return null;
        }
        const matches = matchRoutes(this.rootRoute.createRouteObjects(), path);
        if(!matches) {
            return null;
        }
        const matchFilter: (match: RouteMatch<string>) => boolean = exact?
            (match) => match.route.path === this.pattern:
            (match) => match.route.path?.startsWith(this.pattern) ?? false;
        const matchForThisPath = matches.filter(matchFilter)[0] ?? null;
        if(matchForThisPath === null) {
            return null;
        }
        return {
            params: matchForThisPath.params as PathMatchRequiredVariables<Variables>["params"],
            pathname: matchForThisPath.pathname,
            pathnameBase: matchForThisPath.pathnameBase,
            pattern: {
                path: matchForThisPath.route.path!,
                caseSensitive: matchForThisPath.route.caseSensitive,
                end: this.pattern === matchForThisPath.route.path
            }
        };
    }

    public navigate<NewPattern extends string, NewVariables extends Variables>(path: string | null | undefined, newRoute: Route<NewPattern, NewVariables>): string | null {
        const match = this.wildcard.match(path);
        if (!match) {
            return null;
        }
        const oldVars = Object.keys(match.params);
        const newVars = Object.keys(matchPath(newRoute.pattern, newRoute.pattern)?.params ?? {});

        if(!newVars.every(v => oldVars.includes(v))) {
            console.warn("Requested navigation ["+this+" => "+newRoute+"] is invalid: more variables in "+newRoute+" than available in "+this);
            return null;
        }

        return newRoute.generate(match.params);
    }

    public toString(): string {
        return "Route{pattern="+this.pattern+"}";
    }
}

function wildcardOnce<Pattern extends string>(pattern: Pattern): WildcardOnce<Pattern> {
    if(pattern.endsWith("/*")) {
        return pattern as WildcardOnce<Pattern>;
    } else {
        return `${pattern}/*` as WildcardOnce<Pattern>;
    }
}

export class WildcardRouteImpl<Pattern extends string, Variables extends string = never, AncillaryData = unknown> implements WildcardRoute<Pattern, Variables, AncillaryData> {

    public constructor(private readonly originalRoute: BaseRouteImpl<Pattern, Variables, AncillaryData>) {
    }

    get pattern(): WildcardOnce<Pattern> {
        return wildcardOnce(this.originalRoute.pattern);
    }

    get wildcard(): WildcardRoute<WildcardOnce<Pattern>, Variables, AncillaryData> {
        return this as unknown as WildcardRoute<WildcardOnce<Pattern>, Variables, AncillaryData>;
    }

    get data(): AncillaryData {
        return this.data;
    }

    public match(path: string | null | undefined, _opts?: MatchOptions): PathMatchRequiredVariables<Variables> | null {
        return this.originalRoute.match(path, { exact: false });
    }

    public navigate<NewPattern extends string, NewVariables extends Variables>(path: string | null | undefined, newRoute: Route<NewPattern, NewVariables>): string | null {
        return this.originalRoute.navigate(path, newRoute);
    }

    public relativePatternFor<ChildPattern extends string>(route: ChildBaseRoute<Pattern, Variables, ChildPattern>): string {
        return route.pattern.substring(this.pattern.length-1);
    }
}
