import { Box, Button, createStyles, Divider, IconButton, ListItemIcon, ListItemText, makeStyles, MenuItem, MenuList, PropTypes, styled, Theme, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React, { useCallback } from "react";
import { User } from "../../repository/models/User";
import { Organization } from "../../repository/models/Organization";
import { connect } from "react-redux";
import { selectCurrentOrganization, selectCurrentUser, selectOrganizations, accountSetCurrentOrganization } from "./accountSlice";
import { AppDispatch, AppState } from "../../store";
import { useAuth } from "react-oidc-context";
import { ORGANIZATION_ROOT, ORGANIZATION_SETTINGS } from "../routes";
import { useLocation, Link } from "react-router-dom";
import ArrowMenu from "../../ArrowMenu";

const useSelectOrgListItemStyles = makeStyles(() =>
    createStyles({
        selectOrg: {
            paddingTop: 0,
            paddingBottom: 0,
            '& .MuiListItemIcon-root': {
                minWidth: "35px",
            },
            '& .MuiListItemText-inset': {
                paddingLeft: "35px",
            }
        }
    })
);

interface SelectOrgListItemProps {
    readonly org: Organization;
    readonly currentOrg: Organization | null;
    readonly selectOrg: (org: Organization) => void;
}

const SelectOrgListItem = ({org, currentOrg, selectOrg}: SelectOrgListItemProps) => {
    const classes = useSelectOrgListItemStyles();
    const onClick = useCallback(() => selectOrg(org), [selectOrg, org]);

    let selected = org === currentOrg;
    let maybeIcon = selected
        ? <ListItemIcon><Check /></ListItemIcon>
        : null;
    return <MenuItem dense onClick={onClick} className={classes.selectOrg}>
        {maybeIcon}
        <ListItemText inset={!selected}>{org.display_name}</ListItemText>
    </MenuItem>
}

type AccountMenuProps = {
    color?: PropTypes.Color,
    currentOrg: Organization|null,
    currentUser: User|null,
    orgs: readonly Organization[] | null,
    handleSetCurrentOrg: (org: Organization) => void,
}

function AccountMenu({currentOrg, currentUser, orgs, handleSetCurrentOrg, color}: AccountMenuProps) {
    const [menuAnchor, setMenuAnchor] = React.useState<Element|null>(null);
    const open = Boolean(menuAnchor);
    const anchorRef = React.useRef(null);

    const auth = useAuth();

    const handleOpen = useCallback((event: React.MouseEvent) => {
        setMenuAnchor(event.currentTarget);
    }, [setMenuAnchor]);
    const handleClose = useCallback(() => {
        setMenuAnchor(null);
    }, [setMenuAnchor]);
    const selectOrg = useCallback((org: Organization) => {
        handleClose();
        handleSetCurrentOrg(org);
    }, [handleClose, handleSetCurrentOrg])

    const classes = useStyles();

    const orgSettingsHref = ORGANIZATION_ROOT.navigate(useLocation().pathname, ORGANIZATION_SETTINGS);

    const OrgSettingsLink = React.useMemo(() => {
        return React.forwardRef((props: any, ref: any) => {
            if (orgSettingsHref) {
                return <Link to={orgSettingsHref} ref={ref} {...props}>{props.children}</Link>
            }
            return null;
        })
    }, [orgSettingsHref]);

    if (!auth.isAuthenticated || !currentUser) {
        return <Button color={color} onClick={() => void auth.signinRedirect({
            redirect_uri: window.location.href
        })}>Login</Button>;
    }

    return <>
        {currentUser ? (
            <Box
                className={classes.button}
                component={Button}
                onClick={handleOpen}
            >
                <Typography variant='subtitle2' component='span'>{currentUser.display_name}</Typography>
                <Typography variant='body2' component='span'>{currentOrg?.display_name}</Typography>
            </Box>
        ) : null}

        <IconButton size='small' color={color} aria-label="account menu" onClick={handleOpen}>
            <AccountCircleIcon fontSize="large" ref={anchorRef} />
        </IconButton>
        <ArrowMenu open={open} anchorRef={anchorRef} placement='top-end' handleClose={handleClose}>
            <StyledMenuList autoFocusItem={open} id="menu-list-grow" dense>
                <MenuItem className={classes.accountDisplay} disabled>
                    <div className={classes.accountName}>
                        <div className={classes.display_name}>{currentUser.display_name}</div>
                        <div className={classes.accountEmail}>{currentUser.email}</div>
                    </div>
                    <div className={classes.accountAvatar}>
                        <AccountCircleIcon fontSize="large" />
                    </div>
                </MenuItem>
                {!!orgs && orgs.length > 0 ? <Divider /> : null}
                {!!orgs && orgs.length > 0 ? <MenuItem className={classes.listHeader} disabled>My Organizations</MenuItem> : null}
                {!!orgs && orgs.map((org, i) => <SelectOrgListItem key={i} org={org} currentOrg={currentOrg} selectOrg={selectOrg} />)}
                <Divider />
                <MenuItem component={OrgSettingsLink}><ListItemText>Organization Settings</ListItemText></MenuItem>
                <MenuItem disabled onClick={handleClose}><ListItemText>Account Settings</ListItemText></MenuItem>
                <Divider />
                <MenuItem onClick={() => void auth.signoutRedirect()}><ListItemText>Logout</ListItemText></MenuItem>
            </StyledMenuList>
        </ArrowMenu>
    </>;
}

const StyledMenuList = styled(MenuList)({
    maxHeight: 'calc(100vh - 80px)',
    overflow: 'hidden auto'
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        accountDisplay: {
            '&.MuiListItem-root.Mui-disabled': {
                opacity: 1
            },
            display: "flex",
            alignItems: "center",
        },
        listHeader: {
            '&.MuiListItem-root.Mui-disabled': {
                opacity: 1
            },
            fontWeight: "bold",
        },
        accountName: {
            flexGrow: 1,
        },
        display_name: {
        },
        accountEmail: {
            fontSize: "small",
            color: "#666"
        },
        accountAvatar: {
            lineHeight: 1,
            paddingLeft: theme.spacing(1),
        },
        button: {
            marginRight: 0,
            color: theme.palette.common.white,
            textTransform: 'unset',
            '& .MuiButton-label': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end'
            }
        },
    })
);

export default connect((state: AppState) => ({
    currentUser: selectCurrentUser(state),
    orgs: selectOrganizations(state),
    currentOrg: selectCurrentOrganization(state),
}),
    (dispatch: AppDispatch) => ({
        handleSetCurrentOrg: (org: Organization) => {
            dispatch(accountSetCurrentOrganization(org))
        }
    })
)(AccountMenu);
