import React from "react";
import { Box, Link, Typography } from "@material-ui/core";

export const IamRealmClients = () => {
    return (
        <Box padding={1}>
            <Box marginBottom={2}>
                <Typography align='center' gutterBottom>Clients are applications and services that access your ContentGrid applications.</Typography>
            </Box>

            <Typography align='center'>Client management is not yet available in the ContentGrid Console. Please contact <Link href='mailto:support@contentgrid.com?subject=ContentGrid IAM Client'>support@contentgrid.com</Link> if you need to set up an OIDC client or service account.</Typography>
        </Box>
    )
}
