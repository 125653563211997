import { Box, Button, CardContent, CircularProgress, Collapse, Grid, Typography, } from "@material-ui/core";
import React, { ForwardedRef, useCallback, useState } from "react";
import { connect } from "react-redux";
import AddIcon from '@material-ui/icons/Add';
import { NavLink } from "react-router-dom";

import { accountSetCurrentOrganization, selectInvites, selectOrganizations } from "./features/account/accountSlice";
import { Organization } from "./repository/models/Organization";
import { AppDispatch, AppState } from "./store";
import GridCard from "./ui/GridCard";
import { CREATE_ORGANIZATION } from "./features/routes";
import { useAcceptUserInviteMutation, useRejectUserInviteMutation } from "./features/organization/api";
import { UserInvite } from "./repository/models/Invite";
import { ModifiableList } from "./ui/ModifiableList";
import { CollapseSwitcher } from "./ui/CollapseSwitcher";
import { StyledCard } from "./ui/styles/StyledCard";
import { HighlightedComponent, organizationJoinLocation, useTourLocation } from "./features/tour";
import { HighlightedDot } from "./features/tour/HighlightedComponent";
import BusyButton from "./BusyButton";
import { ServerErrorMessage } from "./ui/ServerErrorMessage";

const rejectStatus = 'REJECT'

type RootDashboardProps = {
    organizations: readonly Organization[] | null,
    invites: readonly UserInvite[] | null,
    handleSetCurrentOrg: (org: Organization) => void,
}

function RootDashboard({ organizations, invites, handleSetCurrentOrg }: RootDashboardProps) {
    const rejectedInviteList = (invites ?? []).filter(item => item.closed_response === rejectStatus)
    const openInviteList = (invites ?? []).filter(item => item.closed_at === null)

    const [ opened, setOpened ] = useState(false)

    return (
        <>
            <WelcomeMessage />
            <Box mt={1} mb={2}>
                <HighlightedComponent location={organizationJoinLocation}>
                    <Button
                        component={NavLink}
                        to={CREATE_ORGANIZATION.generate({})}
                        color="primary"
                        variant="contained"
                        startIcon={<AddIcon />}
                    >
                        Create Organization
                    </Button>
                </HighlightedComponent>
            </Box>

            <Box mb={2}>
                {organizations === null ? (
                    <CircularProgress />
                ) : null}

                <Grid container spacing={1}>
                    {openInviteList.map(invite => <InviteCard key={invite.organization} invite={invite} />)}

                    {(organizations ?? []).map((org: Organization) =>
                        <HighlightedComponent key={org.name}  location={organizationJoinLocation}>
                            <OrgCard org={org} handleSetCurrentOrg={handleSetCurrentOrg} />
                        </HighlightedComponent>
                    )}
                </Grid>
            </Box>

            {rejectedInviteList.length > 0 && (
                <ModifiableList title={'Rejected invitations (' + rejectedInviteList.length + ')'}>

                    <CollapseSwitcher opened={opened} onClick={() => setOpened(active => !active)} />

                    <Collapse in={opened} appear={opened}>
                    <Grid container spacing={1}>
                        {rejectedInviteList.map(invite => <InviteCard key={invite.organization} isRejected invite={invite} />)}
                    </Grid>
                    </Collapse>
                </ModifiableList>
            )}
        </>
    );
}

type InviteCardProps = {
    invite: UserInvite,
    isRejected?: boolean,
}

function InviteCard({invite, isRejected}: InviteCardProps) {
    const [rejectUserInvite, {isLoading:rejectLoading, error: rejectError}] = useRejectUserInviteMutation()
    const [acceptUserInvite, {isLoading:acceptLoading, error: acceptError}] = useAcceptUserInviteMutation()

    const handleReject = () => {
        rejectUserInvite(invite)
    }

    const handleAccept = () => {
        acceptUserInvite(invite)
    }

    return (
        <GridCard
            typeText="Invited to organization"
            primaryText={invite.organization}
        >
            <Box sx={{ marginTop: '1rem' }}>
                <Typography color='textSecondary'>Invited by</Typography>
                <Typography>{invite.invited_by_display_name}</Typography>
                {isRejected && invite.closed_at !== null && <Typography variant="caption">Rejected on {new Date(invite.closed_at).toLocaleString()}</Typography>}
            </Box>

            {!!rejectError || !!acceptError ? (
                <Box marginTop={2}>
                    <ServerErrorMessage error={rejectError || acceptError} />
                </Box>
            ) : null}

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                {!isRejected && (
                    <Box mr={2}>
                        <BusyButton busy={rejectLoading} disabled={acceptLoading} variant='contained' onClick={handleReject}>Reject</BusyButton>
                    </Box>
                )}

                <HighlightedComponent location={organizationJoinLocation} disabled={isRejected}>
                    <BusyButton busy={acceptLoading} variant='contained' color='primary' onClick={handleAccept}>Accept</BusyButton>
                </HighlightedComponent>
            </Box>
        </GridCard>
    )
}

type OrgCardProps = {
    org: Organization,
    handleSetCurrentOrg: (org: Organization) => void,
}

const OrgCard = React.forwardRef(function ({org, handleSetCurrentOrg}: OrgCardProps, ref: ForwardedRef<any>) {
    const onClick = useCallback(() => handleSetCurrentOrg(org), [handleSetCurrentOrg, org]);
    return <GridCard
        ref={ref}
        onClick={onClick}
        typeText="Organization"
        primaryText={org.display_name}
    />;
})

const WelcomeMessage = () => {
    const [showWelcome] = useTourLocation(organizationJoinLocation);

    if(showWelcome){
        return (
            <StyledCard>
                <CardContent>
                    <Box mb={2}>
                        <Typography variant='h4'>Welcome to the <Typography variant='h4' component='span' color='primary'>Content</Typography>Grid platform!</Typography>
                    </Box>

                    <Typography>
                        We are currently in private beta. If you have any questions or need help, reach out to us.
                    </Typography>
                    <Typography gutterBottom>
                        To get started, create an organization, or accept an invitation to one.
                    </Typography>
                    <Typography>
                        For a small tour through all features, follow the <HighlightedDot style={{ position: "relative", display: "inline-flex"}} />.
                    </Typography>
                </CardContent>
            </StyledCard>
        )
    }

    return <></>
}

export default connect((state: AppState) => ({
    organizations: selectOrganizations(state),
    invites: selectInvites(state),
}),
    (dispatch: AppDispatch) => ({
        handleSetCurrentOrg: (org: Organization) => {
            dispatch(accountSetCurrentOrganization(org))
        }
    })
)(RootDashboard);
