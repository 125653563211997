import { EventHandlerDto } from "../../repository/models/EventHandlerDto"
import { EventHandler, EventTypes } from "./types"

export const someEvents: EventTypes = {
  create: false,
  update: false,
  content: false,
  delete: false
}

export const allEvents: EventTypes = {
  create: true,
  update: true,
  content: true,
  delete: true,
}

export const contentEvents: EventTypes = {
  create: false,
  update: false,
  content: true,
  delete: false
}

export const radioButtons: { value: string, label: string }[] = [
  { value: "all", label: "everything" },
  { value: "content", label: "content" },
  { value: "some", label: "the following events" }
]

export const eventList = ['create', 'update', 'content', 'delete'];

export const notifyValue = (types: EventTypes) => {
  if(types.content && types.create && types.update && types.delete) {
      return "all";
  }
  if(types.content && !types.create && !types.update && !types.delete) {
      return "content";
  }

  return "some";
}

export const getEventHandlers = (eventHandlersData: EventHandlerDto[] | undefined): EventHandler[] | [] | null => {
  if(!!eventHandlersData){
      const newList: EventHandler[] = eventHandlersData.map(item => {
          return {
              id: item.id,
              name: item.description,
              webhook_url: item.endpoint,
              events: {
                  create: item.triggers!.includes('create'),
                  delete: item.triggers!.includes('delete'),
                  update: item.triggers!.includes('update'),
                  content: item.triggers!.includes('content'),
              },
              entityName: item.entity,
          }
      })
      return newList
  }
  return null
}

export const getEventHandlerDto = (eventHandlerData: Partial<EventHandlerDto>): Partial<EventHandlerDto> => {
  return {
      // id: eventHandlerData.id,
      description: eventHandlerData.description,
      endpoint: eventHandlerData.endpoint,
      create: eventHandlerData.triggers!.includes('create'),
      delete: eventHandlerData.triggers!.includes('delete'),
      update: eventHandlerData.triggers!.includes('update'),
      content: eventHandlerData.triggers!.includes('content'),
      entity: eventHandlerData.entity,
      _links: eventHandlerData._links
  }
}


