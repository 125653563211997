import { Box, Button, Typography } from "@material-ui/core";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { NavLink, useParams } from "react-router-dom";
import React, { useMemo, useState } from "react";
import { DeleteForever } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";

import { IamRealm } from "../../../repository/models/IamRealm";
import { useGetRealmGroupByIdQuery, useGetRealmGroupMembersQuery } from "../api";
import { RequestStateHandler } from "../../../ui/RequestStateHandler";
import { createErrorMessage } from "../helpers";
import { Overview } from "../../../ui/Overview";
import { PageTitle } from "../../../ui/typography/PageTitle";
import { MembersTable } from "./components/MembersTable";
import { MembersDialog } from "./components/MembersDialog";
import { IAM_REALM_GROUPS } from "../../routes";
import { Organization } from "../../../repository/models/Organization";
import BackLink from "../components/BackLink";
import { resolveTemplate } from "../../../hal";
import RemapPalette from "../../../ui/theme/RemapPalette";
import Actions from "../../../ui/action/Actions";
import Action from "../../../ui/action/Action";
import { DeleteGroupDialog } from "./components/DeleteGroupDialog";
import { AttributesTable } from "./components/AttributesTable";
import { IAM_REALM_EDIT_GROUP } from "../../routes";
import { ServerErrorMessage } from "../../../ui/ServerErrorMessage";

interface IamRealmGroupProps {
    realm?: IamRealm,
    org: Organization,
}

export const IamRealmGroup = ({ realm, org }: IamRealmGroupProps) => {
    const { group: groupId } = useParams();
    const groupQueryParam = (realm === undefined || groupId === undefined)
        ? skipToken
        : { realm, groupId };
    const { data: group, isLoading, error } = useGetRealmGroupByIdQuery(groupQueryParam);

    const {data: memberList, isLoading: isMemebersLoading, error: membersError} = useGetRealmGroupMembersQuery(group!)

    const groupDeleteTemplate = useMemo(() => {
        if(!!group){
            return resolveTemplate(group!, 'delete')
        }
        return null
    },[group])

    const [deleteDialogOpened, setDeleteDialogOpened] = useState(false)
    const [opened, setOpened] = useState(false)

    const groupTemplate = useMemo(() => {
        if(!!group){
            return resolveTemplate(group, 'default')
        }
        return null
    },[group])

    if (isLoading || !!error) {
        return <RequestStateHandler isLoading={isLoading} error={error} />
    }

    if (group === undefined) {
        return <ServerErrorMessage error={createErrorMessage('Group not found!')} />
    }

    return (
        <>
            <Box>
                <Box marginBottom={2}>
                    <BackLink to={IAM_REALM_GROUPS.generate({ org: org.name, realm: realm?.id! })}>
                        Back to list
                    </BackLink>
                </Box>

                <Box display='flex' justifyContent='space-between' alignItems='flex-start'>
                    <PageTitle>{group!?.name}</PageTitle>

                    {!!groupTemplate ? (
                        <Button
                            variant='contained'
                            color='primary'
                            component={NavLink}
                            to={IAM_REALM_EDIT_GROUP.generate({org: org?.name!, realm: realm?.id!, group: groupId!})}
                        >
                            <Edit fontSize='inherit' />&nbsp; Edit group
                        </Button>
                    ) : null}
                </Box>

                <Overview>
                    <Box display='flex' alignItems='center' justifyContent='space-between' marginBottom={2}>
                        <Typography>Attributes</Typography>
                    </Box>
                    
                    <AttributesTable attributes={group?.attributes ?? {}} />
                </Overview>

                <Overview>
                    <Box display='flex' alignItems='center' justifyContent='space-between' marginBottom={2}>
                        <Typography>Members</Typography>

                        <Button color='primary' variant='contained' onClick={() => setOpened(true)}>
                            Add members
                        </Button>
                    </Box>
                    <MembersTable list={memberList!} isLoading={isMemebersLoading} error={membersError!} group={group} />
                </Overview>

                {!!groupDeleteTemplate ? (
                    <RemapPalette from="danger" to="primary">
                        <Actions title="Delete this group">
                            <Action
                                description="You can delete the current group. This action cannot be undone."
                            >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<DeleteForever />}
                                    onClick={() => setDeleteDialogOpened(true)}
                                >
                                    Delete
                                </Button>
                            </Action>
                        </Actions>
                    </RemapPalette>
                ) : null}
            </Box>

            <DeleteGroupDialog
                opened={deleteDialogOpened}
                group={group}
                memberList={memberList ?? null}
                org={org}
                realm={realm}
                onClose={() => setDeleteDialogOpened(false)}
            />

            <MembersDialog
                opened={opened}
                realm={realm}
                memberList={memberList ?? null}
                onClose={() => setOpened(false)}
            />
        </>
    )
}