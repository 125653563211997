import { Typography, Grid, Box, styled } from "@material-ui/core"
import React from "react"

import { ExpressionDto, PolicyDto, Verb } from "../../repository/models/PolicyDto";
import { CardLink, CardLinkProps } from "../../ui/CardLink";
import { capitalize } from "../../helpers";
import { Card } from "../../ui/Card";
import PolicyCondition from "./PolicyCondition";
import { VerbIcon } from "./components/VerbIcon";

interface PolicyModelMinimizedProps {
    policy: PolicyDto,
    to?: string,
}

export const PolicyModelMinimized = (props: PolicyModelMinimizedProps) => {
    const title = props.policy.requires_authentication ? 'Authenticated' : 'Public'
    const text = !!props.policy.verbs.length ? <><i>{title}</i>&nbsp;users can&nbsp;</> : 'Policy Disabled'

    return (
        <Wrapper link={props.to} minHeight={false}>
            <Grid container alignItems='center' wrap='nowrap'>

                    <Typography variant='body2' noWrap >
                        {text}
                    </Typography>


                <VerbIcons verbs={props.policy.verbs} />
            </Grid>

            {!!props.policy.conditions.length ? (
                <Conditions conditions={props.policy.conditions} entity={props.policy.entity} />
            ) : null}
        </Wrapper>
    )
}

interface ConditionsProps {
    conditions: ExpressionDto[],
    entity: string,
}

const Conditions = ({ conditions, entity }: ConditionsProps) => {
    return (
        <StyledGrid container wrap='nowrap'>
            <Box marginRight={1} paddingRight={1} borderColor={'divider'} border={1} borderLeft={0} borderTop={0} borderBottom={0}>
                <Typography variant='body2'>when</Typography>
            </Box>

            <div>
                {conditions.map((condition, i) => <PolicyCondition key={i} condition={condition} entityName={entity} />)}
            </div>
        </StyledGrid>
    )
}

const StyledGrid = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(1)
}))

interface VerbIconsProps {
    verbs: Verb[]
}

const VerbIcons = ({ verbs }: VerbIconsProps) => {
    return (
        <>
            {verbs.map((verb, index) => {
                const text = capitalize(verb)

                return (
                    <Box display='flex' alignItems='center' fontSize='20px' marginRight={1} key={verb}>
                        <VerbIcon verb={verb} />
                        <Typography variant='body2' component='span' noWrap>{text}{index === verbs.length-1 ? '' : ','}</Typography>
                    </Box>
                )
            })}
        </>
    )
}

const Wrapper = (props: Partial<CardLinkProps>) => {
    if(!!props.link){
        return (
            <CardLink {...props} link={props.link}>{props.children}</CardLink>
        )
    }

    return (
        <Card>{props.children}</Card>
    )
}
