import { styled, CardActionArea } from "@material-ui/core"
import React, { ReactNode } from "react"
import cx from 'classnames'

import { StyledCard } from "./styles/StyledCard"

interface CardProps {
    minHeight?: boolean,
    children: ReactNode,
    onClick?: () => void
    disabled?: boolean
}

export const Card = ({ minHeight = true, children, onClick, disabled }: CardProps) => {
    const classes = cx({ 'is-active': !!onClick, 'is-disabled': disabled })
    const contentClasses = cx({ 'min-height': minHeight })

    return (
        <StyledWrapper className={classes}>
            {(!onClick || disabled) ? (
                <StyledContent>
                    {children}
                </StyledContent>
            ) : (
                <CardActionArea>
                    <StyledContent className={contentClasses} onClick={onClick}>
                        {children}
                    </StyledContent>
                </CardActionArea>
            )}
        </StyledWrapper>
    )
}

const StyledWrapper = styled(StyledCard)({
    '&.is-active': {
        '&:hover': {
            backgroundColor: 'none',
            '&::before' : {
                opacity: '1',
                transition: 'opacity 100ms ease-out'
            },
            '&::after' : {
                opacity: '0',
                transition: 'opacity 100ms ease-out'
            }
        }
    },
    '&.is-disabled': {
        '&::before': {
            visibility: 'hidden'
        },
        '&::after': {
            visibility: 'hidden'
        },
        border: '1px solid rgba(0, 0, 0, 0.12)'
    }
})

const StyledContent = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    padding: '0.5rem 1rem',
    flexDirection: 'column',
    width: '100%',
    '&.min-height': {
        minHeight: '6rem',
        padding: '1rem'
    }
})
