import React, { ReactNode, useCallback, useState } from "react";
import { Typography, Dialog } from "@material-ui/core";

import { Attribute, Entity, Relation } from "../../repository/models/Entity";
import AttributeModelAdd from "./AttributeModelAdd";
import RelationModel from "./RelationModel";
import AttributeModelMinimized from "./AttributeModelMinimized";
import RelationModelMinimized from "./RelationModelMinimized";
import { useDeleteEntityMutation, useGetEntityQuery, usePatchEntityMutation } from "./api";
import { ModifiableList } from "../../ui/ ModifiableList";
import { useVisitedTourLocation } from "../tour/hooks";
import { datamodelAddAttributeLocation } from "../tour";
import { ServerErrorMessage } from "../../ui/ServerErrorMessage";
import Actions from "../../ui/action/Actions";
import Action from "../../ui/action/Action";
import RemapPalette from "../../ui/theme/RemapPalette";
import { resolveTemplate } from "../../hal";
import ValidatedTextInput from "../../hal/forms/ValidatedTextInput";
import { RequestStateHandler } from "../../ui/RequestStateHandler";
import DeleteButton from "../../ui/button/DeleteButton";
import PartiallyEditableTitleInput from "../../ui/input/PartiallyEditableTitleInput";
import EditableDescriptionInput from "../../ui/input/EditableDescriptionInput";
import { useLocation, useNavigate } from "react-router-dom";
import { BLUEPRINT_MODEL, DATAMODEL_ATTRIBUTE } from "../routes";
import { paramsToRecord } from "../routes/helpers";

const fakeAttribute = {
    name: 'id',
    type: 'FAKE_ID',
    indexed: true,
    unique: true,
    required: true
}

type DataEntityModelProps = {
    entity: Entity,
    onSaveEntity: (entity: Entity) => void,
    onDeleteEntity: (entity: Entity) => void,
}

enum Property { Empty, Attribute, Relation }
type PropertyValue = { property: Property.Attribute, value?: Attribute }
                   | { property: Property.Relation, value?: Relation }
                   | { property: Property.Empty }



export default function DataEntityModel(props: DataEntityModelProps) {
    const {data: entity, isLoading: entityLoading, error: entityError} = useGetEntityQuery(props.entity);
    const location = useLocation();
    const navigate = useNavigate();
    var routeMatch = BLUEPRINT_MODEL.match(location.pathname);

    const [propInDialog, setPropInDialog] = React.useState<PropertyValue>({property: Property.Empty });


    const close = useCallback(() => {
        setPropInDialog({ property: Property.Empty });
    }, [setPropInDialog]);

    if(entityLoading || !!entityError){
        return <RequestStateHandler isLoading={entityLoading} error={entityError} />
    }

    if(!entity){
        return null
    }

    function editAttribute(attribute: Attribute) {
        navigate(DATAMODEL_ATTRIBUTE.generate({
            ...paramsToRecord(routeMatch!.params)!,
            entity: entity!.name,
            attribute: attribute.name
        }));
    }

    return (
        <>
            <Dialog open={propInDialog.property !== Property.Empty} onClose={close} maxWidth={false}>
                { (propInDialog.property !== Property.Empty)
                    ? (propInDialog.property === Property.Attribute)
                        ? <AttributeModelAdd
                            entity={entity}
                            onClose={close}
                            onSave={(attribute, configure) => {
                                if(configure) {
                                    editAttribute(attribute);
                                }
                                close();
                            }}
                             />
                        : <RelationModel relation={propInDialog.value} entity={entity}
                            close={close} />
                    : null
                }
            </Dialog>

            <EntityModel
                entity={entity}
                onSaveEntity={props.onSaveEntity}
                onDelete={() => props.onDeleteEntity(entity)}
            >

                <Attributes
                    attributes={entity._embedded?.attributes ?? []}
                    onAdd={() => setPropInDialog({ property: Property.Attribute })}
                    onEdit={editAttribute}
                />

                <Relations
                    relations={entity._embedded?.relations ?? []}
                    onAdd={() => setPropInDialog({ property: Property.Relation })}
                    onEdit={(value) => setPropInDialog({ property: Property.Relation, value: value })}
                />
            </EntityModel>
        </>
    );
}

interface AttributesProps {
    attributes: Attribute[],
    onAdd: () => void,
    onEdit: (value: Attribute) => void,
}

const Attributes = ({ attributes, onAdd, onEdit }: AttributesProps) => {
    useVisitedTourLocation(datamodelAddAttributeLocation, () => attributes.length > 0);

    return (
        <ModifiableList
            title="Attributes"
            buttonText="Add Attribute"
            onButtonClick={onAdd}
        >
            <AttributeModelMinimized attribute={fakeAttribute as Attribute} />

            {attributes.map((attr) =>
                <AttributeModelMinimized
                    attribute={attr}
                    key={attr._links.self.href}
                    onClick={() => onEdit(attr)}
                />
            )}
        </ModifiableList>
    )
}

interface RelationsProps {
    relations: Relation[],
    onAdd: () => void,
    onEdit: (value?: Relation) => void,
}

const Relations = ({ relations, onAdd, onEdit }: RelationsProps) => {
    return (
        <ModifiableList
            title="Relations"
            buttonText="Add Relation"
            onButtonClick={onAdd}
        >
            {relations.map((relation) =>
                <RelationModelMinimized
                    relation={relation}
                    key={relation._links.self.href}
                    onClick={() => onEdit(relation)}
                />
            )}
        </ModifiableList>
    )
}


interface EntityModelProps {
    entity: Entity,
    children: ReactNode,
    onSaveEntity: (entity: Entity) => void,
    onDelete: () => void,
}

const EntityModel = ({
    entity,
    children,
    onSaveEntity,
    onDelete,
}: EntityModelProps) => {
    const canDelete = entity._templates.delete !== undefined;
    const [deleteEntity, { isLoading, error, reset }] = useDeleteEntityMutation();

    return (
        <ModifiableList customTitle={(
            <EntityTitle entity={entity} onSaveEntity={onSaveEntity} />
        )}>
            {children}

            {canDelete && (<RemapPalette from="danger" to="primary">
                <Actions title="Delete this entity">
                    <Action
                        description="You can delete the current entity. This action cannot be undone."
                    >
                        <DeleteButton
                            key={entity._links.self.href} // This is to ensure that the dialog gets re-initialized when the entity changes
                            variant="contained"
                            onDeleteConfirm={async () => {
                                await deleteEntity(entity).unwrap();
                                onDelete();
                            }}
                            onDeleteCancel={reset}
                            isLoading={isLoading}
                            error={error}
                            dialogTitle={<>Delete entity {entity.name}</>}
                            >
                            <Typography>Are you sure you want to delete entity {entity.name}?</Typography>
                            <Typography>This entity has {entity._embedded?.attributes?.length ?? 0} attributes
                                and {entity._embedded?.relations?.length ?? 0} relations which will be deleted as well.</Typography>
                            </DeleteButton>
                    </Action>
                </Actions>
            </RemapPalette>)}
        </ModifiableList>
    )
}

interface EntityTitleProps {
    entity: Entity,
    onSaveEntity: (value: Entity) => void,
}

const EntityTitle = ({ entity, onSaveEntity }: EntityTitleProps) => {
    const [edited, setEdited] = useState(false)
    const [patchEntity, {isLoading, error, reset}] = usePatchEntityMutation();

    const handleClose = useCallback(() => {
        reset();
        setEdited(false);
    }, [setEdited, reset])

    const handleRename = useCallback(async (name: string) => {
        const response = await patchEntity({ entity, patch: { name } }).unwrap();
        onSaveEntity(response);
    }, [entity, patchEntity, onSaveEntity])

    const nameProperty = resolveTemplate(entity, "default")?.property('name');

    return (
        <>
            <ServerErrorMessage error={error} />
            <PartiallyEditableTitleInput
                prefix="Entity"
                editActionButtonText="Rename"
                editing={edited && !!nameProperty}
                value={entity.name}
                onEditRequest={() => setEdited(true)}
                onEditCancel={handleClose}
                onEditSave={handleRename}
                editIsSaving={isLoading}
                TextInput={({ value, onChange, onEnterPressed, className }) =>

                    <ValidatedTextInput
                        className={className}
                        placeholder="my-entity"
                        displayName="Entity name"
                        halProperty={nameProperty!}
                        value={value}
                        short={true}
                        autoFocus
                        error={!!error}
                        handleOnChange={onChange}
                        handleOnKeyDown={onEnterPressed ? (key => key === "Enter" && onEnterPressed()) : undefined}
                    />

                }
                variant='h5'
            />

            <EntityDescription entity={entity} />
        </>
    )
}

const EntityDescription = ({entity}: {entity: Entity}) => {
    const [description, setDescription] = useState<string>(entity.description ?? '');
    const [editDescription, setEditDescription] = useState(false);
    const [patchEntity, {isLoading, error}] = usePatchEntityMutation();

    const handleClose = () => {
        setDescription(entity.description ?? '');
        setEditDescription(false);
    }

    const handlePatchEntity = useCallback(() => {
        patchEntity({ entity, patch: { description: description }}).then(response => {
            if("data" in response && response.data) {
                setEditDescription(false);
            }
        })
    }, [entity, patchEntity, setEditDescription, description])

    return <EditableDescriptionInput
        originalValue={entity.description ?? ""}
        value={description}
        onChange={setDescription}
        onEditRequest={() => setEditDescription(true)}
        onEditCancel={handleClose}
        onEditSave={handlePatchEntity}
        editIsSaving={isLoading}
        editSaveError={error}
        editing={editDescription}
    />;
}
