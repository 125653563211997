import { HalError } from "./errors";
import { TypedLink } from "./typedLinks";

export interface HalResourceObject<T> {
    readonly _links: {
        readonly self: HalLink<T>
    };
}

export interface HalLink<Target = unknown> {
    readonly href: TypedLink<Target>;
    readonly templated?: boolean;
}

export type WithoutHal<E> = Omit<E, "_links" | "_templates">;

export function templateLink<T>(link: TypedLink<T>, parameters: Record<string, string>): TypedLink<T> {
    return link.replaceAll(/\{([a-zA-Z0-9]+)\}/g, (_match, name) => {
        if(name in parameters) {
            return parameters[name]!;
        }
        throw new HalError(`Can not template link ${link}: missing parameter ${name}`);
    })
    // request param template parameters look like `/endpoint{?someparam,otherparam}` and are assumed to be optional
    .replaceAll(/\{\?([a-zA-Z0-9,]+)\}/g, (_match, params) => {
        let kvPairs: string[] = params.split(",").map((name: string) => {
            if (name in parameters) {
                return name + "=" + parameters[name];
            } else {
                return null
            }
        }).filter((kvPair: string|null) => kvPair !== null);

        return "?" + kvPairs.join("&");
    }) as TypedLink<T>
}
