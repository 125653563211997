import { Box, styled, Button } from "@material-ui/core";
import React, { useState } from "react";
import { Alert } from "@material-ui/lab";
import { SerializedError } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";
import MuiLink from '@material-ui/core/Link';

import { RequestStateHandler } from "../../../../ui/RequestStateHandler";
import { Row } from "../../../../ui/table/Row";
import { TableHeader } from "../../../../ui/typography/TableHeader";
import { Cell } from "../../../../ui/table/Cell";
import { IamUser, IamUserGroup, IamUserGroupList } from "../../../../repository/models/IamUser";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { IAM_REALM_GROUP } from "../../../routes";
import { IamRealm } from "../../../../repository/models/IamRealm";
import { Organization } from "../../../../repository/models/Organization";

interface GroupsTableProps {
    list: IamUserGroupList,
    isLoading: boolean,
    error: SerializedError,
    user: IamUser,
    realm?: IamRealm,
    org: Organization | null,
}

export const GroupsTable = ({list, isLoading, error, user, realm, org}: GroupsTableProps) => {
    const [selectedGroup, setSelectedGroup] = useState<IamUserGroup | null>(null)
    const groups = list?._embedded?.groups ?? [];

    const handleLeave = (group: IamUserGroup) => {
        setSelectedGroup(group)
    } 

    if (isLoading || !!error) {
        return <RequestStateHandler isLoading={isLoading} error={error} />
    }

    if(!groups.length){
        return <Alert severity='info'>Not a member of any group.</Alert>
    }

    return (
        <>
            <Box display='flex' flexDirection='column'>
                <Row borders='horizontal'>
                    <TableHeader>Name</TableHeader>
                </Row>
                {groups.map((item: IamUserGroup) => (
                    <StyledRow
                        display='flex'
                        alignItems='center'
                        key={item.groupId}
                    >
                        <Cell><MuiLink component={Link} to={IAM_REALM_GROUP.generate({org: org?.name!, realm: realm?.id!, group: item.groupId})}>{item.name}</MuiLink></Cell>
                        <Cell width={15}><StyledButton color='primary' variant='text' onClick={() => handleLeave(item)}>Leave</StyledButton></Cell>
                    </StyledRow>
                ))}
            </Box>

            {!!selectedGroup ? <ConfirmationDialog opened={selectedGroup !== null} user={user} group={selectedGroup} onClose={() => setSelectedGroup(null)} /> : null}
        </>
    )
}

const StyledRow = styled(Box)(({theme}) => ({
    '&:not(:last-child)': {
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.divider,
    }
}))

const StyledButton = styled(Button)(({theme}) => ({
    textTransform: 'capitalize',
    fontWeight: 'inherit',
    fontSize: theme.typography.caption.fontSize
}))

