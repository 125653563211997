import React from "react";
import { Box, Link, Typography } from "@material-ui/core";

export const IamRealmSSO = () => {
    return (
        <Box padding={1}>
            <Box marginBottom={2}>
                <Typography align='center'>An OIDC Identity Provider can be used to provide SSO for your ContentGrid application users.</Typography>
            </Box>
            
            <Typography align='center'>Identity Provider management is not yet available in the ContentGrid Console. Please contact <Link href='mailto:support@contentgrid.com?subject=ContentGrid SSO provider'>support@contentgrid.com</Link> if you need to configure an OIDC Identity Provider.</Typography>
        </Box>
    )
}
