import React, { ReactNode } from "react";
import { Box, Paper, styled, Typography } from "@material-ui/core";

interface OverviewProps {
    title?: string,
    button?: ReactNode,
    children: ReactNode,
}

export const Overview = ({ title, button, children }: OverviewProps) => {

    return (
        <>
            <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom={1}>
                <Typography component="h1" variant="h6">{title}</Typography>
                {button}
            </Box>

            <StyledPaper variant="outlined" >
                <Box padding='1em' display="flex" flexDirection="column">
                   {children}
                </Box>
            </StyledPaper>
        </>
    )
}

export const StyledPaper = styled(Paper)(({theme}) => ({
    marginBottom: theme.spacing(4)
}));

export const OverviewRow = styled(Box)(({theme}) => ({
    display: "flex",
    padding: theme.spacing(2, 0),
    borderBottom: "solid 1px " + theme.palette.divider,
    '&:last-child': {
        borderBottom: "none"
    },
    '& > div': {
        minWidth: '150px',
        padding: theme.spacing(0, 1)
    }
}));

interface OverviewItemProps {
    name?: string,
    grow?: number,
    basis?: number,
    children?: React.ReactNode,
    icon?: React.ReactNode,
}

export const OverviewItem = (props: OverviewItemProps) => (
    <Box
        flexGrow={props.grow ?? 0}
        flexBasis={props.basis ?? 0}
        display='flex'
        flexDirection='column'
    >
        <Box display='flex' alignItems='center' fontWeight={600}>
            {!!props.icon ? <Box display='inline-flex' marginRight={0.5}>{props.icon}</Box> : null}
            {props.name}:
        </Box>
        {props.children}
    </Box>
);

interface OverviewSubItemProps {
    label: string,
    children: ReactNode,
}

export const OverviewSubItem = (props: OverviewSubItemProps) => (
    <OverviewSubItemDiv>
        <Typography variant='body2'><b>{props.label}:</b></Typography>

        {props.children}
    </OverviewSubItemDiv>

);
const OverviewSubItemDiv = styled('div')(({theme}) => ({
    display: 'flex',
    gap: theme.spacing(.5),
    '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
    }
}))
