import React, { useCallback, useEffect, useState } from "react";
import DataEntityModel from "./DataEntityModel";
import { Attribute, Entity, EntityList } from "../../repository/models/Entity";
import { useSearchParams } from "react-router-dom";
import { Blueprint } from "../../repository/models/Blueprint";
import AddEntityButton from "./AddEntityButton";
import { connect } from "react-redux";
import { AppState } from "../../store";
import { selectCurrentBlueprint } from "../blueprint/blueprintSlice";
import { useAddAttributeMutation, useAddEntityMutation, useAddRelationMutation, useGetEntityListQuery } from "./api";
import { skipToken } from "@reduxjs/toolkit/query";
import AutoBreadcrumbs from "../routes/AutoBreadcrumbs";
import EntitySelector from "../../ui/EntitySelector";
import { EntityListItem } from "../../ui/EntityListItem";
import { Button, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { datamodelAddEntityLocation, HighlightedComponent } from "../tour";
import { useVisitedTourLocation } from "../tour/hooks";
import { WithoutHal } from "../../hal";
import { toast } from "react-toastify";
import ChangesReminder from "../blueprint/components/ChangesReminder";
import { RequestStateHandler } from "../../ui/RequestStateHandler";
import { DataModelDiagram } from "./DataModelDiagram/DataModelDiagram";

type DataModelProps = {
    currentBlueprint: Blueprint|null,
}


function DataModel(props: DataModelProps) {
    const { data: entityRefList, isLoading, error } = useGetEntityListQuery(props.currentBlueprint ?? skipToken);
    const [searchParams, setSearchParams] = useSearchParams();
    const searchParamEntity = searchParams.get("entity");
    const entityRefs = entityRefList?._embedded?.entities;
    const selectedEntity = entityRefs?.find(ref => ref.name === searchParamEntity);

    const [pending, setPending] = useState(false)

    const selectEntity = React.useCallback((entityName: string, options?: { replace?: boolean }) => setSearchParams({entity: entityName}, options), [setSearchParams]);

    const addEntityCallback = useCallback((entity: Entity) => {
        selectEntity(entity.name);
    }, [selectEntity]);

    const deleteEntityCallback = useCallback((entity: Entity) => {
        const firstEntity = entityRefs?.find(ref => ref._links.self.href !== entity._links.self.href);
        if(firstEntity) {
            selectEntity(firstEntity.name)
        } else {
            setSearchParams({});
        }
    }, [entityRefs, selectEntity, setSearchParams]);

    useEffect(() => {
        if(!entityRefs) {
            return;
        }
        // If there are no entities (yet), don't select any
        if (entityRefs.length === 0) {
            return;
        }

        // If we're loading the page without a specific entity selected in the url, just select
        // the first entity and replace the history entity so it doesn't count as navigation.
        if (!searchParamEntity) {
            selectEntity(entityRefs[0]!.name, { replace: true })
            return;
        }
    }, [isLoading, entityRefs, searchParamEntity, selectEntity]);

    useVisitedTourLocation(datamodelAddEntityLocation, () => entityRefs ? entityRefs.length > 0 : false);

    if (isLoading || !entityRefs || props.currentBlueprint == null) {
        return null;
    }

    return <>
        <AutoBreadcrumbs>
            <>Data model</>
        </AutoBreadcrumbs>

        {isLoading || !!error ? (
            <RequestStateHandler isLoading={isLoading} error={error} />
        ) : (
            <>
                {!pending ? <ChangesReminder /> : null}

                {!!entityRefs?.length ? (
                    <DataModelDiagram blueprint={props.currentBlueprint} />
                ) : null}

                <EntitySelector
                    entities={entityRefs ?? null}
                    selectedEntity={entityRefs?.filter(e => e.name === searchParamEntity)[0] ?? null}
                    EntityComponent={EntityListItem}
                    actions={
                        <HighlightedComponent location={datamodelAddEntityLocation}>
                            <AddEntityButton entityRefList={entityRefList} callback={addEntityCallback} />
                        </HighlightedComponent>
                    }
                >
                    {selectedEntity
                        ? <DataEntityModel
                            // Ensure that the whole tree gets recreated when switching between entities
                            key={selectedEntity._links.self.href}
                            entity={selectedEntity}
                            onSaveEntity={addEntityCallback}
                            onDeleteEntity={deleteEntityCallback}
                            />
                        : entityRefs.length === 0 ? <DataModelEmpty onPending={setPending} entityRefList={entityRefList} />
                        : null
                    }
                </EntitySelector>
            </>
        )}
    </>;
}

interface DataModelEmptyProps {
    entityRefList: EntityList,
    onPending: (pending: boolean) => void
}

function DataModelEmpty({entityRefList, onPending}: DataModelEmptyProps) {
    const [addEntity] = useAddEntityMutation();
    const [addAttribute] = useAddAttributeMutation();
    const [addRelation] = useAddRelationMutation();

    async function setupExampleDatamodel() {
        const supplierEntity = await createEntityWithAttributes("supplier", [
            {
                name: "name",
                description: '',
                type: "STRING",
                natural_id: false,
                required: true,
                indexed: true,
                unique: false,
            },
            {
                name: "telephone",
                description: '',
                type: "STRING",
                natural_id: false,
                required: true,
                indexed: false,
                unique: false,
            },
            {
                name: "bank_account",
                description: '',
                type: "STRING",
                natural_id: false,
                required: false,
                indexed: true,
                unique: true,
            }

        ]);
        const invoiceEntity = await createEntityWithAttributes("invoice", [
            {
                name: "received",
                description: '',
                type: "DATETIME",
                natural_id: false,
                required: true,
                indexed: false,
                unique: false,
            },
            {
                name: "document",
                description: '',
                type: "CONTENT",
                natural_id: false,
                required: false,
                indexed: false,
                unique: false,
            },
            {
                name: "pay_before",
                description: '',
                type: "DATETIME",
                natural_id: false,
                required: true,
                indexed: true,
                unique: false,
            },
            {
                name: "total_amount",
                description: '',
                type: "DOUBLE",
                natural_id: false,
                required: true,
                indexed: false,
                unique: false,
            }
        ]);

        await addRelation({entity: invoiceEntity, relation: {
            name: "supplier",
            description: "",
            source: invoiceEntity.name,
            target: supplierEntity.name,
            required: false,
            many_source_per_target: true,
            many_target_per_source: false,
        }}).unwrap();

    }

    async function createEntityWithAttributes(entityName: string, attributes: ReadonlyArray<WithoutHal<Attribute>>) {
        const entity = await addEntity({entityRefList, entityName, entityDescription: ''}).unwrap();

        for(const attribute of attributes) {
            await addAttribute({entity, attribute}).unwrap();
        }

        return entity;
    }

    return <>
        <Alert severity="info">
            <AlertTitle>You have not created a data model yet</AlertTitle>

            <Typography variant="body2">In the data model, you model your business by creating <i>entities</i>.</Typography>
            <Typography variant="body2">An entity can contain attributes and relations to other entities.</Typography>

            <Typography variant="body2">Add an entity to get started.</Typography>
            <br />

            <AlertTitle>A small data model example</AlertTitle>

            <Typography variant="body2">Our company, ACME, wants to keep track of all invoices that it receives from its suppliers.</Typography>
            <Typography variant="body2">We want to store when invoices were received, when they have to be paid, the amount we have to pay, and which supplier we have to pay.</Typography>
            <Typography variant="body2">For our suppliers, we want to know their name, telephone and bank account number.</Typography>
            <br />
            <Button color="default" variant="outlined" size="small" onClick={() => {
                const promise = setupExampleDatamodel()
                onPending(true)
                toast.promise(promise, {
                    pending: "Creating example data model",
                    success: "Example data model set up",
                    error: "Something went wrong while setting up the data model."
                }).catch(console.error.bind(console))
                promise.finally(() => onPending(false))
            }}>Set up example datamodel</Button>
        </Alert>
    </>
}

export default connect((state: AppState) => ({
    currentBlueprint: selectCurrentBlueprint(state),
}))(DataModel);
