import { styled, Button, Box, ButtonGroup, LinearProgress } from "@material-ui/core";
import React, { useState, useCallback, useRef, useEffect } from "react";
import { Blueprint } from "../../repository/models/Blueprint";
import { ExpressionDto } from "../../repository/models/PolicyDto";
import PolicyCondition from "./PolicyCondition";
import ArrowMenu from "../../ArrowMenu";
import PolicyConditionEdit from "./PolicyConditionEdit";
import { Check, Close } from "@material-ui/icons";
import usePolicySuggestion from "./hooks/usePolicySuggestion";
import { PolicyConditionSuggestionDescription } from "../../repository/models/PolicyConditionSuggestion";

export type PolicyConditionEditTriggerProps = {
    condition: ExpressionDto,
    onChange: (expression: ExpressionDto) => void,
    blueprint: Blueprint,
    entityName: string,
    isNew?: boolean,
}

export default function PolicyConditionEditTrigger({condition, onChange, blueprint, entityName, isNew = false}: PolicyConditionEditTriggerProps) {
    const buttonRef = useRef<any>();
    const [localDescription, setLocalDescription] = useState<PolicyConditionSuggestionDescription | undefined>(undefined);
    const [open, setOpen] = useState(false);
    const { isLoading, isFetching, condition: newCondition, description, properties, completion} = usePolicySuggestion({
        blueprint: blueprint,
        entity: entityName,
        condition: condition,
        description: localDescription
    }, {
        skip: !(open || isNew)
    });
    const [newPolicy, setNewPolicy] = useState(isNew)

    useEffect(() => {
        setLocalDescription(description)
    }, [description, setLocalDescription]);


    const openEdit = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleClose = useCallback(() => {
        setNewPolicy(false);
        setOpen(false);
    }, [setOpen]);

    const handleSave = useCallback(async () => {
        const { condition } = await completion();

        if(!condition) {
            return;
        }
        onChange(condition);
        handleClose()
    }, [onChange, completion, handleClose]);

    return <>
        <StyledButton variant='outlined' disableRipple ref={buttonRef} onClick={openEdit}>
            <PolicyCondition condition={condition} entityName={entityName} />
        </StyledButton>
        <ArrowMenu
            open={(open || newPolicy) && !isLoading && !!properties && !!localDescription}
            anchorRef={buttonRef}
            useBackdrop="invisible"
            handleClose={handleSave}
        >
            {isFetching ? <LinearProgress variant="indeterminate" /> : null}
            <Box display="flex" padding={1} alignItems="baseline">
                <StyledBox>
                    {properties && localDescription ?
                        <PolicyConditionEdit
                            properties={properties}
                            description={localDescription!}
                            onChange={setLocalDescription}
                        />
                        : null}
                    <Box marginLeft={1} clone>
                        <ButtonGroup size="small" orientation="vertical">
                            <Button color="primary" onClick={handleSave} disabled={!newCondition}><Check /></Button>
                            <Button onClick={handleClose}><Close /></Button>
                        </ButtonGroup>
                    </Box>
                </StyledBox>
            </Box>
        </ArrowMenu>
    </>
}

const StyledButton = styled(Button)(({theme}) => ({
    textTransform: 'none',
    padding: theme.spacing(0.5),
    minWidth: 'auto',
    lineHeight: 1.25,
    'font-weight': theme.typography.fontWeightRegular,
    '&.is-active': {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light
    }
}))

const StyledBox = styled(Box)(({theme}) => ({
        display: "flex",
        alignItems: "center",
        height: "100%",
        gap: theme.spacing(1)
}));
