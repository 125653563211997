import { HalResourceObject, TypedLink } from "../../hal";
import { RTKQueryTag } from "./tags";


type HalResolvable = HalResourceObject<any> | TypedLink<any>;

interface TagsBuilder<T extends string> {
    list(): ListTagsBuilder<T>;
    listFor(resource: HalResolvable): ListTagsBuilder<T>;
    listFor<U>(resource: U, convertor: (u: U) => string): ListTagsBuilder<T>;
    item(resource: HalResolvable | undefined): TagsBuilder<T>;
    item<U>(resource: U | undefined, convertor: (u: U) => string): TagsBuilder<T>;
    toArray(): Array<RTKQueryTag<T>>
}

interface ListTagsBuilder<T extends string> extends TagsBuilder<T> {
    withItems(resources: ReadonlyArray<HalResolvable> | undefined): ListTagsBuilder<T>;
    withItems<U>(resources: ReadonlyArray<U> | undefined, convertor: (u: U) => string): ListTagsBuilder<T>;
}

function resolveHal(resource: HalResolvable): string {
    if(typeof resource === "string") {
        return resource;
    }
    return resource._links.self.href;
}

class TagsBuilderImpl<T extends string> implements ListTagsBuilder<T> {
    public constructor(private readonly type: T, private readonly resourceIds: string[] = []) {

    }

    withItems(resources: readonly HalResolvable[] | undefined): ListTagsBuilder<T>;
    withItems<U>(resources: readonly U[] | undefined, convertor: (u: U) => string): ListTagsBuilder<T>;
    withItems(resources: readonly any[] | undefined, convertor: (a: any) => string = resolveHal): ListTagsBuilder<T> {
        if(resources === undefined || resources.length === 0) {
            return this;
        }

        return new TagsBuilderImpl(this.type, this.resourceIds.concat(resources.map(convertor)));
    }

    list(): ListTagsBuilder<T> {
        return new TagsBuilderImpl(this.type, this.resourceIds.concat("LIST"));
    }

    listFor(resource: HalResolvable): ListTagsBuilder<T>;
    listFor<U>(resource: U, convertor: (u: U) => string): ListTagsBuilder<T>;
    listFor(resource: any, convertor: (a: any) => string = resolveHal): ListTagsBuilder<T> {
        return new TagsBuilderImpl(this.type, this.resourceIds.concat("LIST-" + convertor(resource)))
    }
    item(resource: HalResolvable| undefined): TagsBuilder<T>;
    item<U>(resource: U | undefined, convertor: (u: U) => string): TagsBuilder<T>;
    item(resource: any | undefined, convertor: (a: any) => string = resolveHal): TagsBuilder<T> {
        if(resource === undefined) {
            return this;
        }
        return new TagsBuilderImpl(this.type, this.resourceIds.concat(convertor(resource)));
    }

    toArray(): Array<RTKQueryTag<T>> {
        return this.resourceIds.map(id => ({ type: this.type, id }));
    }

}

export default function tags<T extends string>(type: T): TagsBuilder<T> {
    return new TagsBuilderImpl(type, []);
}
