import { Box, Button, Typography, styled, } from "@material-ui/core";
import React, { useEffect, useMemo, useReducer } from 'react'
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useNavigate, useParams } from "react-router-dom";

import { IamRealm } from "../../../repository/models/IamRealm";
import { RForm, resolveTemplate } from "../../../hal";
import { useEditRealmGroupMutation, useGetRealmGroupByIdQuery } from "../api";
import { inheritHalProperty } from "../../../hal/forms/react";
import ValidatedTextInput from "../../../hal/forms/ValidatedTextInput";
import { IAM_REALM_GROUP, IAM_REALM_GROUPS } from "../../routes";
import { Organization } from "../../../repository/models/Organization";
import BusyButton from "../../../BusyButton";
import { ServerErrorMessage } from "../../../ui/ServerErrorMessage";
import { PageTitle } from "../../../ui/typography/PageTitle";
import IamGroupAttributesForm from "./components/AttributesForm/AttributesForm";
import { RequestStateHandler } from "../../../ui/RequestStateHandler";

interface IamRealmEditGroupProps {
    realm?: IamRealm,
    org: Organization | null,
}

const defaultState = {
    name: '',
    attributes: {}
}

export const IamRealmEditGroup = ({ realm, org }: IamRealmEditGroupProps) => {
    const { group: groupId } = useParams()
    const groupQueryParam = (realm === undefined || groupId === undefined)
        ? skipToken
        : { realm, groupId };
    const { data: group, isLoading, isSuccess, error: groupError } = useGetRealmGroupByIdQuery(groupQueryParam);

    const [editGroup, {error, isLoading: editLoading}] = useEditRealmGroupMutation();
    const navigate = useNavigate()

    useEffect(() => {
        if(isSuccess){
            setFormState({
                name: group?.name!,
                attributes: group?.attributes!,
            })
        }
    }, [isSuccess, group])

    const groupTemplate = useMemo(() => {
        if(!!group){
            return resolveTemplate(group!, 'edit')
        }
        return null
    },[group])

    const [formState, setFormState] = useReducer(
        (state: typeof defaultState, action: Partial<typeof defaultState>) => {
        return {...state, ...action}
    }, defaultState)

    const handleSubmit = async () => {
        await editGroup({templateGroup: group!, group: formState!}).unwrap()
        navigate(IAM_REALM_GROUP.generate({org: org?.name!, realm: realm?.id!, group: groupId!}))
    }

    return (
        <>
            <PageTitle>Edit group</PageTitle>
            {!!error ? (
                <ServerErrorMessage error={error} />
            ) : null}
            <Box marginTop={2}>
                {isLoading || !!groupError ? (
                    <RequestStateHandler isLoading={isLoading} error={groupError} />
                ) : groupTemplate !== null ? (
                    <RForm template={groupTemplate}>
                        <StyledWrapper>
                            <Typography variant='body2' noWrap component='label' htmlFor='name'>Name</Typography>
                            <HalValidatedTextInput
                                displayName="Name"
                                name='name'
                                id='name'
                                fullWidth
                                autoFocus
                                value={formState.name}
                                handleOnChange={(val) => setFormState({name: val})}
                            />
                        </StyledWrapper>
                        <StyledWrapper>
                            <Typography variant='body2' noWrap component='label' htmlFor='name'>Attributes</Typography>
                            <IamGroupAttributesForm
                                template={groupTemplate}
                                name="attributes"
                                value={formState.attributes}
                                onChange={(value) => setFormState({ attributes: value })}
                            />
                        </StyledWrapper>

                        <Box display='flex' justifyContent='flex-end'>
                            <Box marginRight={2}>
                                <Button
                                    variant='contained'
                                    onClick={() => navigate(IAM_REALM_GROUPS.generate({org:org?.name!, realm: realm?.id!}))}
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <BusyButton
                                busy={editLoading}
                                variant='contained'
                                color='primary'
                                type='submit'
                                onClick={handleSubmit}
                            >Save</BusyButton>
                        </Box>
                    </RForm>
                ) : null}
            </Box>
        </>
    )
}

const HalValidatedTextInput = inheritHalProperty(ValidatedTextInput);

const StyledWrapper = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& label.MuiTypography-root': {
        width: theme.spacing(16)
    }
}))
