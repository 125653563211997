import { Theme, createStyles, makeStyles } from "@material-ui/core";
import React, { ReactNode } from "react";
import cx from "classnames"

export interface RowProps {
    children: ReactNode,
    borders?: 'all' | 'horizontal' | 'none',
    isNotFirstDescendant?: boolean,
    onClick?: () => void,
}

export const Row = ({
    children,
    borders = 'none',
    isNotFirstDescendant = true,
    onClick,
}: RowProps) => {
    const { row, allBorders, horizontalBorders, isNotFirst } = useStyles()
    const classes = cx(row , {
        [allBorders]: borders === "all",
        [horizontalBorders]: borders === "horizontal",
        [isNotFirst]: isNotFirstDescendant && borders !== 'none',
    })

    return <div className={classes} onClick={onClick}>{children}</div>
}


const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        row: {
            display: 'flex',
            alignItems: 'center',
        },
        allBorders: {
            border: `1px solid ${_theme.palette.grey[300]}`,
        },
        horizontalBorders: {
            borderTop: `1px solid ${_theme.palette.grey[300]}`,
            borderBottom: `1px solid ${_theme.palette.grey[300]}`,
        },
        isNotFirst: {
            borderTopWidth: '0px'
        },
    }),
);
