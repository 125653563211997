import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React, { useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { connect } from "react-redux";
import { User } from "../../repository/models/User";
import { AppState } from "../../store";
import { selectCurrentUser } from "../account/accountSlice";
import { selectIsExpired } from "./authenticationSlice";

type ReloginDialogProps = {
    currentUser: User|null,
    isExpired: boolean
};

function ReloginDialog(props: ReloginDialogProps) {
    const auth = useAuth();
    const signOut = useCallback(async () => {
        await auth.removeUser();
    }, [auth]);
    const signIn = useCallback(() => {
        auth.signinPopup();
    }, [auth]);
    return <Dialog open={!!props.currentUser && props.isExpired}>
        <DialogTitle>Log in to continue</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Your session has been terminated due to inactivity.
                To continue to use the application, you must log in again.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={signOut}>Stay signed out</Button>
            <Button onClick={signIn} color="primary">Sign in</Button>
        </DialogActions>
    </Dialog>

}

export default connect((state: AppState) => ({
    currentUser: selectCurrentUser(state),
    isExpired: selectIsExpired(state)
}))(ReloginDialog);
