import { UserManagerEvents } from "oidc-client-ts";
import React from "react";
import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { connect } from "react-redux";
import { AppDispatch } from "../../store";
import { authenticationRenewError, authenticationSignIn, authenticationSignOut, authenticationTokenExpired} from "./authenticationSlice";
import ReloginDialog from "./ReloginDialog";

export default function AuthenticationManagement() {
    return <>
        <AuthenticationEvents
        />
        <BoundAuthenticationEvents />
        <ReloginDialog />
    </>
    ;
}


type EventBaseName<T extends string> = T extends `add${infer U}` ? U : never;
type EventPropName<T extends string> = T extends `add${infer U}` ? `on${U}` : never;
type EventAdderName<T extends string> = T extends `on${infer U}` ? `add${U}` : never;

type AuthenticationEventsProps = {
    [k in EventPropName<keyof UserManagerEvents>]?: Parameters<UserManagerEvents[EventAdderName<k>]>[0]
};

type EventNames = EventBaseName<keyof UserManagerEvents>[];

function allProperties(o: object|null): readonly string[] {
    if(!o) {
        return [];
    }
    return Object.getOwnPropertyNames(o).concat(allProperties(Object.getPrototypeOf(o)));
}

function AuthenticationEvents(props: AuthenticationEventsProps) {
    const auth = useAuth();
    const eventNames: EventNames = [];
    for(const k of allProperties(auth.events)) {
        if(k.startsWith("add")) {
            eventNames.push(k.substring(3) as EventBaseName<keyof UserManagerEvents>);
        }
    }
    eventNames.sort();
    for(const eventName of eventNames) {
        /* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps  */
        // Don't worry, we know what we are doing here.
        // `eventNames` is not dependent on input props (only on the UserManagerEvents API) and
        // is sorted to be in a consistent state, so the same useEffect is always linked to the same event.
        const eventHandler: Function | undefined = (props as any)[`on${eventName}`];
        useEffect(() => {
            if(!eventHandler) {
                return;
            }
            return (auth.events as any)["add"+eventName](eventHandler);
        }, [auth.events, eventHandler]);

        /* eslint-enable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps   */
    }
    return null;
}

const BoundAuthenticationEvents = connect(
    null,
    (dispatch: AppDispatch) => ({
        onUserLoaded: () => void dispatch(authenticationSignIn()),
        onUserUnloaded: () => void dispatch(authenticationSignOut()),
        onUserSignedIn: () => void dispatch(authenticationSignIn()),
        onUserSignedOut: () => void dispatch(authenticationSignOut()),
        onAccessTokenExpired: () => void dispatch(authenticationTokenExpired()),
        onSilentRenewError: () => void dispatch(authenticationRenewError()),
    })
)(AuthenticationEvents)
