
import React, { useRef, useState } from "react";
import { Button, Menu, MenuList, Typography } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";

import { Row } from "../../../../../../ui/table/Row";
import { Cell } from "../../../../../../ui/table/Cell";

interface AddAttributeRowProps {
    attributes: string[];
    onChange: (name: string) => void;
}

export const AddAttributeRow = ({attributes, onChange}: AddAttributeRowProps) => {
    const [opened, setOpened] = useState(false);

    const handleClick = () => [
        setOpened(active => !active)
    ]

    const buttonRef = useRef(null)

    const handleSelect = (name: string) => {
        onChange(name)
        setOpened(false)
    }

    return (
        <>
            <Row>
                <Cell isLarge>
                    <Button ref={buttonRef} disabled={!attributes.length} size='small' variant='outlined' color='primary' onClick={handleClick}>Add attribute</Button> 
                </Cell>
            </Row>
    
            <Menu
                anchorEl={buttonRef.current}
                open={opened}
                onClose={() => setOpened(false)}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                getContentAnchorEl={null}
            >
                <MenuList>
                    {attributes.map(attrName => {
                        return <MenuItem key={attrName} value={attrName} onClick={() => handleSelect(attrName)}>
                                <Typography variant='body2'>{attrName}</Typography>
                            </MenuItem>
                    })}
                </MenuList>
            </Menu>
        </>
    )
}
