import { Alert } from '@material-ui/lab'
import { SerializedError } from '@reduxjs/toolkit'
import React from 'react'
import { Typography, styled } from '@material-ui/core'

interface ServerErrorMessageProps {
  error?: SerializedError,
  textView?: boolean,
  className?: string
}

export const ServerErrorMessage = ({ error, textView, className }: ServerErrorMessageProps) => {
  if(!error){
    return null
  }

  if(textView){
    return <Typography className={className} variant='body2' color='error'>Server Error: {error?.message}</Typography>
  }

  return (
    <StyledAlert severity='error'>Server error: {error?.message}</StyledAlert>
  )
}

const StyledAlert = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}))
