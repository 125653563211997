import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as Router } from "react-router-dom";
import { AuthProvider } from "react-oidc-context";
import { CssBaseline, ThemeProvider } from '@material-ui/core';

import { createReduxHistory } from './features/routes/routeSlice';
import App from './App';
import store from "./store/store";
import fetcher from './repository';
import AuthenticationManagement from './features/authentication/AuthenticationManagement';
import { theme } from './theme';

const history = createReduxHistory(store);

function loginCallback() {
    history.replace(history.location.pathname)
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<Provider store={store} >
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router history={history} >
            <AuthProvider
                onSigninCallback={loginCallback}
                {...window.config.oidc}
            >
                <AuthenticationManagement />
                <App fetcher={fetcher} />
            </AuthProvider>
        </Router>
    </ThemeProvider>
</Provider>);
