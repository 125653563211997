import { Typography, FormControlLabel, Checkbox as UICheckbox, Select as UISelect, createStyles, makeStyles, Theme, DialogContent, DialogTitle, DialogActions, Button, Dialog, InputLabel } from "@material-ui/core"
import { skipToken } from "@reduxjs/toolkit/query"
import React, { useCallback } from "react"
import BusyButton from "../../BusyButton"
import { useAddRelationMutation, useDeleteRelationMutation, useGetRelationQuery, usePatchRelationMutation } from "./api"
import { Entity, Relation } from "../../repository/models/Entity"
import { buildTemplate, HalForm, resolveTemplate, RForm, withHalOptions, withHalProperty, WithoutHal } from "../../hal"
import { toast } from "react-toastify"
import RemapPalette from "../../ui/theme/RemapPalette"
import ValidatedTextInput from "../../hal/forms/ValidatedTextInput"
import { inheritHalProperty } from "../../hal/forms/react"
import { ServerErrorMessage } from "../../ui/ServerErrorMessage"
import { Box } from "@material-ui/core"
import { Textarea } from "../../ui/Textarea"

type RelationModelProps = {
    entity: Entity,
    relation?: Relation,
    close: () => void,
}

enum Acts {
    RENAME,
    SET_SOURCE_CARDINALITY,
    SET_TARGET_CARDINALITY,
    SET_TARGET,
    SET_REQUIRED,
    SET_DESCRIPTION
}
type Action = {
    type: Acts,
    data?: any
}
type State = {
    relation: WithoutHal<Relation>,
    nameEdited?: boolean,
}
const Checkbox = withHalProperty(UICheckbox);
const Select = withHalOptions(withHalProperty(UISelect), ({value, prompt}) => <option value={value} disabled={!value}>{prompt}</option>, true);

function reducer(state: State, action: Action): State {
    const update = (rel: Partial<WithoutHal<Relation>>) => {
        return {
        ...state,  relation: { ...state.relation, ...rel }
    }};

    switch(action.type) {
        case Acts.RENAME:                 return {...update({name: action.data}), nameEdited: true};
        case Acts.SET_SOURCE_CARDINALITY: return update({ many_source_per_target: action.data });
        case Acts.SET_TARGET_CARDINALITY: return update({ many_target_per_source: action.data, required: state.relation.required && !action.data }); // "required" gets disabled when you make a -to-many relation
        case Acts.SET_TARGET:
            if(state.nameEdited){
                return update({target: action.data});
            }
            return update({target: action.data, name: action.data});
        case Acts.SET_REQUIRED:           return update({ required: action.data });
        case Acts.SET_DESCRIPTION:        return update({description: action.data});
    }
}

const HalValidatedTextInput = inheritHalProperty(ValidatedTextInput);

export default function RelationModel(props: RelationModelProps) {
    const classes = useStyles();

    const relationHalForm = props.relation === undefined ? resolveTemplate(props.entity, "addRelation") :
        (resolveTemplate(props.relation, "default") ?? createDisabledRelationTemplate(props.relation));

    const relation: WithoutHal<Relation> = props.relation
            || { name: "", many_source_per_target: false, many_target_per_source: false, required: false, target: "", source: props.entity.name, description: ""};

    const relationQuery = props.relation ? { entity: props.entity, relationName: props.relation.name, relationDescription: props.relation.description } : null;
    const { data: originalRelation, isLoading: originalRelationIsLoading } = useGetRelationQuery(relationQuery ?? skipToken);

    const [addRelation, { isLoading: addRelationIsLoading, error: addRelationError }] = useAddRelationMutation();
    const [patchRelation, { isLoading: patchRelationIsLoading, error: patchError }] = usePatchRelationMutation();
    const [deleteRelation, { isLoading: deleteRelationIsLoading, error: deleteError }] = useDeleteRelationMutation();

    const showBusy = addRelationIsLoading || deleteRelationIsLoading || originalRelationIsLoading || patchRelationIsLoading;

    const isNew = props.relation === undefined;

    const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);

    const handleClose = () => {
        props.close();
        toast.success('Changes saved successfully', {
            toastId: 'relation-changes',
        })
    }

    const save = async () => {
        if (isNew) {
            await addRelation({ entity: props.entity, relation: state.relation }).unwrap();
        } else {
            await patchRelation({ relation: props.relation!, patch: { name: state.relation.name, description: state.relation.description }}).unwrap();
        }
        handleClose();
    }

    const [state, dispatch] = React.useReducer(reducer, { relation: relation });
    const act = useCallback((type: Acts, data?: any) => {
        return dispatch({type: type, data: data});
    },[dispatch])

    const _delete = async () => {
        if (originalRelation) {
            await deleteRelation(originalRelation).unwrap();
            props.close();
        }
    }

    const target = state.relation.target
    const targetPerSource = state.relation.many_target_per_source
    const sourcePerTarget = state.relation.many_source_per_target

    const sourceCheckboxText = `Multiple ${props.entity.name}s per ${!!target ? target : 'target'}`
    const targetCheckboxText = `Multiple ${!!target ? target : 'target'}s per ${props.entity.name}`
    const helpText = (sourcePerTarget ? `Many ${props.entity.name}s` : `One ${props.entity.name}`) + " → " + (targetPerSource ? `Many ${target ?? 'target'}s` : `One ${target ?? 'target'}`)

    return <>
        <DialogTitle>{props.relation
            ? <span>Editing Relation <em>{props.relation.name}</em></span>
            : <span>Add New Relation</span>
        }</DialogTitle>
        <DialogContent>
            {(patchError || addRelationError || deleteError) && <ServerErrorMessage error={patchError || addRelationError || deleteError} />}
            <div className={classes.relationEdit}>
                <RForm template={relationHalForm}>
                    <Typography className={classes.relationLabel}>From: </Typography>
                    <Typography>{props.entity.name}</Typography>
                    <div>
                        <FormControlLabel
                            disabled={!relationHalForm?.property('many_source_per_target').enabled}
                            label={sourceCheckboxText}
                            control={
                                <Checkbox
                                    name="many_source_per_target"
                                    checked={state.relation.many_source_per_target}
                                    onChange={(e) => act(Acts.SET_SOURCE_CARDINALITY, e.target.checked)}
                                    color="primary"
                                />
                            }
                        />
                    </div>
                    <Typography className={classes.relationLabel}>To: </Typography>
                    {props.relation ? (
                        <Typography>{target}</Typography>
                    ) : (
                        <Select
                            name="target"
                            native
                            value={target}
                            onChange={(e) => act(Acts.SET_TARGET, e.target.value)}
                        />
                    )}
                    <div>
                        <FormControlLabel
                            disabled={!relationHalForm?.property('many_target_per_source').enabled}
                            label={targetCheckboxText}
                            control={
                                <Checkbox
                                    name="many_target_per_source"
                                    checked={state.relation.many_target_per_source}
                                    onChange={(e) => act(Acts.SET_TARGET_CARDINALITY, e.target.checked)}
                                    color="primary"
                                />
                            }
                        />
                    </div>
                    <Typography className={classes.relationLabel}>Name: </Typography>
                    <div className={classes.relationName}>
                        <HalValidatedTextInput
                            displayName="Relation name"
                            name="name"
                            value={state.relation.name}
                            handleOnChange={(value) => { act(Acts.RENAME, value)}}
                        />
                    </div>
                    <div>
                        <FormControlLabel
                            label="Relationship is required"
                            disabled={!relationHalForm?.property('required').enabled || state.relation.many_target_per_source}
                            control={
                                <Checkbox
                                    name="required"
                                    checked={state.relation.required}
                                    onChange={(e) => act(Acts.SET_REQUIRED, e.target.checked)}
                                    color="primary"
                                />
                            }
                        />
                    </div>
                    {!!target && (
                        <div className={classes.cardinalitySummary}>{helpText}</div>
                    )}

                    <Box marginTop={1} width='100%' gridColumn='1/4'>
                        <Box marginBottom={1}>
                            <InputLabel className={classes.textareaLabel} color='primary' htmlFor="relation-description">Description (optional):</InputLabel>
                        </Box>
                        <Textarea
                            id='relation-description'
                            value={state.relation.description}
                            handleOnChange={(v) => act(Acts.SET_DESCRIPTION, v)}
                        />
                    </Box>
                </RForm>
            </div>
            <Dialog open={confirmOpen}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>Are you sure you want to delete relation <em>{props.relation?.name}</em>?</DialogContent>
                <DialogActions>
                    <Button disabled={showBusy} autoFocus onClick={() => setConfirmOpen(false)}>Cancel</Button>
                    <RemapPalette from="danger" to="secondary">
                        <BusyButton busy={showBusy} disabled={!originalRelation} color="secondary" onClick={() => _delete()}>Delete</BusyButton>
                    </RemapPalette>
                </DialogActions>
            </Dialog>
        </DialogContent>
        <DialogActions>
            <RemapPalette from="danger" to="secondary">
                {(originalRelation?._templates?.delete)
                    ? <Button className={classes.delete} onClick={() => setConfirmOpen(true)} color="secondary">Delete</Button>
                    : null}
            </RemapPalette>
            <Button disabled={showBusy} onClick={() => props.close()}>Cancel</Button>
            <BusyButton
                busy={showBusy}
                variant="contained"
                disableElevation
                color="primary"
                disabled={!target || (relation?.description === state.relation.description && relation?.name === state.relation.name)}
                onClick={save}
            >
                Save
            </BusyButton>
        </DialogActions>
    </>
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        relationEdit: {
            padding: "1em",
            margin: theme.spacing(1, 0),
            display: "grid",
            gridTemplateColumns: "min-content max-content max-content",
            gridGap: "0 1rem",
            alignItems: "baseline",
            '& + .MuiPaper-root': {
                margin: theme.spacing(2, 0, 1, 0)
            }
        },
        relationLabel: {
            marginRight: theme.spacing(2)
        },
        relationName: {
            width: '250px',
            display: 'flex',
            alignItems: 'baseline',
        },
        cardinalitySummary: {
            gridColumn: "1 / 4",
            textAlign: "center",
            marginTop: ".5em",
        },
        delete: {
            marginRight: "auto"
        },
        textareaLabel: {
            fontSize: theme.typography.body1.fontSize,
            color: theme.palette.text.primary
        }
    }));

function createDisabledRelationTemplate(relation: Relation): HalForm<unknown> {
    let builder = buildTemplate<unknown>("PATCH", relation._links.self.href);

    builder = builder.addProperty("target", property => property.withEnabled(false).addOption(relation.target));

    return builder;
}
