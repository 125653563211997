import { Backdrop, ClickAwayListener, makeStyles, Paper, Popper, PopperPlacementType, Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import React, { useCallback } from "react";

type ArrowMenuProps = {
    open: boolean,
    anchorRef?: React.MutableRefObject<Element|null>,
    placement?: PopperPlacementType;
    useBackdrop?: boolean|"invisible";
    anchorFun?: (() => Element)|null,
    handleClose: () => void,
    children: any,
}

export default function ArrowMenu({
    anchorRef,
    open,
    useBackdrop = false,
    children,
    placement = 'bottom',
    anchorFun,
    handleClose,
}: ArrowMenuProps) {
    const [arrowRef, setArrowRef] = React.useState<HTMLElement|null>(null);
    const classes = useStyles();

    let anchorEl: React.ComponentProps<typeof Popper>["anchorEl"] = anchorRef !== undefined ? anchorRef.current : anchorFun!;


    const backdropOnClick = useCallback((event: React.UIEvent) => {
        // We only want to trigger a close when clicked on the backdrop, not when clicked on something *inside* the backdrop
        if(event.target === event.currentTarget) {
            handleClose();
        }
    }, [handleClose]);

    let contentsElement = <Paper className={classes.menu} elevation={0}>
        {children}
    </Paper>;

    // When not using a backdrop, wrap the contents with a clickaway listener to dismiss the menu
    if(!useBackdrop) {
        contentsElement = <ClickAwayListener onClickAway={handleClose}>{contentsElement}</ClickAwayListener>
    }

    const popperElement = (
        <Popper className={classes.popper} open={open} anchorEl={anchorEl} role={undefined} disablePortal placement={placement}
            modifiers={{ arrow: { enabled: true, element: arrowRef }, }}>
            <>
                <div className={classes.arrow} ref={setArrowRef}></div>
                {contentsElement}
            </>
        </Popper>
    );

    if(!useBackdrop) {
        return popperElement;
    } else {
        return <>
            <Backdrop
                className={classes.backdrop}
                open={open}
                invisible={useBackdrop === "invisible"}
                onClick={backdropOnClick}
            />
            {popperElement}
        </>
    }

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        arrow: {
            position: 'absolute',
            width: '18px',
            height: '9px',
            zIndex: 1, /* due to the position: absolute that popper places, this is relative to the .menu */
            '&::before': {
                content: '""',
                display: 'block',
                width: 0,
                height: 0,
                borderStyle: 'solid',
                borderWidth: '0 9px 9px 9px',
                borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
            },
        },
        menu: {
            position: 'relative',
            top: '9px'
        },
        popper: {
            filter: 'drop-shadow(0 1px 2px rgba(0,0,0,.4))',
            zIndex: theme.zIndex.appBar+1,
        },
        backdrop: {
            zIndex: theme.zIndex.appBar
        }
    })
);
