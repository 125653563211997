import { HalLink } from "../../hal";
import { Blueprint } from "./Blueprint";
import { Entity } from "./Entity";
import { ExpressionDto, PolicyDto, TokenDto, TokenDtoRoot } from "./PolicyDto";


export default class Policy {
    constructor(
        public id: string,
        public entity: string,
        public verbs: Verb[],
        public requires_authentication: boolean,
        public conditions: Expression[],
        public _links: {
            self: HalLink,
            entity: HalLink<Entity>,
            ['policy-condition-suggestions']: HalLink,
            blueprint: HalLink<Blueprint>
        }
    ) {}
    static fromDto(policy: PolicyDto): Policy {
        return new this(
            policy.id,
            policy.entity,
            policy.verbs,
            policy.requires_authentication,
            policy.conditions.map(p => Expression.fromDto(p)),
            policy._links,
        );
    }
}

export type Verb = 'read'|'create'|'update'|'delete'

export type TokenType = 'user'|'entity'|'constant'
export type TokenSubType = 'boolean'|'string'|'number'|null

export class Token {
    constructor(
        public type: TokenType,
        public subType: TokenSubType,
        public value: string[]
    ){}

    static fromDto(token: TokenDto): Token {
        if (token.type.includes(':')) {
            let [type, subType] = token.type.split(':');
            return new this(type as TokenType, subType as TokenSubType ?? null, token.value);
        }
        return new this(token.type as TokenType, null, token.value);
    }

    toDto(): TokenDto {
        let type = ((this.subType === null) ? this.type : this.type + ':' + this.subType) as TokenDtoRoot;
        return {
            type: type,
            value: this.value
        }
    }

    toString(): string {
        if (this.type === 'constant') {
            if (this.subType === 'string') {
                if (this.value[0] === '') {
                    return '(empty string)';
                }
                return `"${this.value[0]}"`
            }
            return this.value[0]!;
        }
        return `${this.type}.${this.value.join('.')}`
    }
}

export type Operator = 'equals'|'not_equals'|'greater_than'|'greater_than_or_equals'|'less_than'|'less_than_or_equals'|'contains'|'in';

export class Expression {
    constructor(public readonly left: Token,
        public readonly oper: Operator,
        public readonly right: Token
    ) { }

    static fromDto(expression: ExpressionDto): Expression {
        return new this(Token.fromDto(expression.left), expression.oper, Token.fromDto(expression.right));
    }

    withLeft(token: Token): Expression {
        return new Expression(token, this.oper, this.right);
    }

    withRight(token: Token): Expression {
        return new Expression(this.left, this.oper, token);
    }

    withOperator(op: Operator): Expression {
        return new Expression(this.left, op, this.right);
    }

    toDto(): ExpressionDto {
        return {
            left: this.left.toDto(),
            oper: this.oper,
            right: this.right.toDto(),
        }
    }

    shortOperator(): string {
        switch (this.oper) {
            case 'equals': return '='
            case 'not_equals': return '≠'
            case 'greater_than': return '>'
            case 'greater_than_or_equals': return '≥'
            case 'less_than': return '<'
            case 'less_than_or_equals': return '≤'
            case 'contains': return 'contains'
            case 'in': return 'in'
        }
    }
}
