import React, { useCallback } from "react";
import { connect } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { BLUEPRINT_PERMISSIONS, PERMISSIONS_NEW } from "../routes";
import { Blueprint } from "../../repository/models/Blueprint";
import { PolicyCreationDto } from "../../repository/models/PolicyDto";
import { AppState } from "../../store";
import { selectCurrentBlueprint } from "../blueprint/blueprintSlice";
import { useAddPolicyMutation } from "./api";
import { PolicyModelForm } from "./PolicyModelForm";
import Link from "../../ui/Link";
import AutoBreadcrumbs from "../routes/AutoBreadcrumbs";
import { paramsToRecord } from "../routes/helpers";

type PolicyModelProps = {
    currentBlueprint: Blueprint,
}

function PolicyModelCreate(props: PolicyModelProps) {
    const routeMatchNew = useMatch({ path: PERMISSIONS_NEW.pattern })!;

    const navigate = useNavigate();

    const entityName = routeMatchNew.params.entity;

    const parentUrl = BLUEPRINT_PERMISSIONS.generate(paramsToRecord(routeMatchNew!.params)!)+"?entity="+entityName;


    const backToOverview = useCallback(() => {
        navigate(parentUrl);
    }, [navigate, parentUrl]);

    const [addPolicy, { isLoading: isSaving, error }] = useAddPolicyMutation();

    const onSave = useCallback(async (data: PolicyCreationDto) => {
        await addPolicy({blueprint: props.currentBlueprint!, policy: data})
        backToOverview();
    }, [props.currentBlueprint, addPolicy, backToOverview]);

    if (!entityName) {
        return null;
    }

    return <>
        <AutoBreadcrumbs collapseDefaults>
            <Link to={parentUrl}>
                Permissions for <strong>{entityName}</strong>
            </Link>
            <>New Policy</>
        </AutoBreadcrumbs>
        <PolicyModelForm
            currentBlueprint={props.currentBlueprint}
            entityName={entityName}
            policy={null}
            isSaving={isSaving}
            error={error}
            onSave={onSave}
            onCancel={backToOverview}
        />
    </>

}


export default connect((state: AppState) => ({
    currentBlueprint: selectCurrentBlueprint(state)!,
}))(PolicyModelCreate);
