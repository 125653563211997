import React, { ReactNode } from 'react'
import { Box, Checkbox as UICheckbox, FormControlLabel, Typography, makeStyles, createStyles } from "@material-ui/core"
import { withHalProperty } from '../hal';

const Checkbox = withHalProperty(UICheckbox);

interface CheckboxIconLabelProps {
    name: string,
    label?: string,
    checked: boolean,
    children: ReactNode,
    disabled?: boolean,
    onChange: (value: boolean) => void
}

export const CheckboxIconLabel = ({
    name, label, checked, children, disabled, onChange
}: CheckboxIconLabelProps) => {
    const classes = useStyles();
    return (
        <FormControlLabel
            disabled={disabled}
            control={
                <Checkbox
                    disabled={disabled}
                    name={name}
                    checked={checked}
                    color='primary'
                    onChange={(e) => onChange(e.target.checked)}
                />
            }
            label={
                <Box className={classes.controlLabel}>
                    {children}
                    {label && <Typography component='span' noWrap>{label}</Typography>}
                </Box>
            }
        />
    )
}

const useStyles = makeStyles(theme => createStyles({
    controlLabel: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: theme.spacing(1)
    }
}))
