import { combineWatchers, createWatcher } from "@xenit/redux-utils";
import { AppDispatch } from "../../store";
import { clearCurrentBlueprint } from "./blueprintSlice";
import { selectCurrentProject } from "../project/projectSlice";
import blueprintsApi from "./api";


const currentProjectWatcher = createWatcher(selectCurrentProject, (project, _old, {dispatch} : {dispatch: AppDispatch}) => {
    if(project !== null) {
        dispatch(blueprintsApi.endpoints.getBlueprints.initiate(project));
    } else {
        dispatch(clearCurrentBlueprint());
    }
})

export default combineWatchers(currentProjectWatcher);
