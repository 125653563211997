import { createWatcher, combineWatchers } from "@xenit/redux-utils";
import { selectVisitedLocations, reloadVisitedTourLocations } from "./tourSlice";

export default combineWatchers(
    createWatcher(selectVisitedLocations, (visitedLocations) => {
        localStorage.setItem("tour.visitedLocations", JSON.stringify(visitedLocations));
    }),
    (store) => {
        function dispatchReload() {
            store.dispatch(reloadVisitedTourLocations());
        }
        window.addEventListener("storage", dispatchReload);
        return () => window.removeEventListener("storage", dispatchReload);
    }
);
