import { theme } from "../../../theme";

export const largeLetterSize = 8.2;
export const smallLetterSize = 5.87;
export const boxPaddings = theme.spacing(2);
export const iconsBox = theme.spacing(6);
export const minWidth = theme.spacing(30);
export const bordersHeight = 2;
export const headerHeight = theme.spacing(4);
export const attrBodySpacing = theme.spacing(2);
export const relationMiddle = theme.spacing(2);
export const relationMarginBottom = theme.spacing(1);
export const attrMarginBottom = theme.spacing(1);
export const attrItemHeight = theme.spacing(3);
export const relItemHeight = theme.spacing(4);
export const relMarginBottom = theme.spacing(1);
