import { Box, Typography } from "@material-ui/core"
import React from "react";

import { Deployment } from "../../../../../../repository/models/Deployment";
import { ApplicationDeploymentIcon } from "../../../../components/ApplicationDeploymentIcon";
import { deploymentStatusText } from "../../../../helpers";

interface LatestDeploymentProps {
    deployment: Deployment | null,
}

export const LatestDeployment = ({
    deployment
}: LatestDeploymentProps) => {
    if (deployment === null) {
        return (
            <Typography variant='body2'>Nothing has been deployed yet!</Typography>
        )
    }

    return (
        <Box display='flex'>
            <ApplicationDeploymentIcon status={deployment.status} fontSize="small" />
            <Box marginLeft={1}>
                <Typography variant='body2'>Started at {new Date(deployment.created).toLocaleString()}</Typography>
                <Typography variant='body2' color='textSecondary'>{deploymentStatusText(deployment.status)}</Typography>
            </Box>

        </Box>
    )
}
