import { combineReducers, createAsyncThunk, createReducer, createSelector, Selector, SerializedError } from "@reduxjs/toolkit";
import { Organization } from "../../repository/models/Organization";
import { User } from "../../repository/models/User";
import { AppState } from "../../store";
import { AppAsyncThunkConfig } from "../../store";
import { AppThunkAction } from "../../store/store";
import { authenticationSignOut } from "../authentication/authenticationSlice";
import { replaceRouterVariable, ORGANIZATION_ROOT, selectRouterPath, replace } from "../routes";
import { UserInvite } from "../../repository/models/Invite";
import organizationApi from "../organization/api";
import { skipToken } from "@reduxjs/toolkit/query";


export const accountGetCurrentUser = createAsyncThunk<User, void, AppAsyncThunkConfig>("account/currentUser", async (_unused: void, {extra}) => {
    return await extra.dataFetcher.getUser();
});

// This is not a redux-utils asyncReducer, because we don't want
// to clear the user data while fetching.
// Re-fetching user data will happen when an expired session is refreshed,
// and we don't want a temporary empty state to be triggered there, because it
// causes a re-fetch of orgs, projects, etc. This results in pretty much all components
// being recreated, and a loss of local state in the component, resulting in a discarding of all unsaved changes.
const currentUserReducer = createReducer<{
    data: User|undefined,
    isLoading: boolean,
    requestError: SerializedError|undefined,
    requestId: string|null
}>({
    data: undefined,
    isLoading: false,
    requestError: undefined,
    requestId: null
}, builder =>
    builder.addCase(accountGetCurrentUser.pending, (state, action) => ({
        data: state.data,
        isLoading: true,
        requestError: undefined,
        requestId: action.meta.requestId
    }))
        .addCase(accountGetCurrentUser.fulfilled, (state, action) => state.requestId === action.meta.requestId ? ({
            data: action.payload,
            isLoading: false,
            requestError: undefined,
            requestId: null
        }) : state)
        .addCase(accountGetCurrentUser.rejected, (state, action) => state.requestId === action.meta.requestId ? ({
            data: undefined,
            isLoading: false,
            requestError: action.error,
            requestId: null
        }) : state)
        .addCase(authenticationSignOut, () => ({
            data: undefined,
            isLoading: false,
            requestError: undefined,
            requestId: null
        }))
);

export function accountSetCurrentOrganization(org: Organization): AppThunkAction<void> {
    return (dispatch) => {
        dispatch(replaceRouterVariable({
            route: ORGANIZATION_ROOT,
            params: {
                org: org.name,
            }
        }));
    };
}

export function accountSetCurrentOrganizationWithoutHistory(org: Organization): AppThunkAction<void> {
    return (dispatch) => {
        dispatch(replaceRouterVariable({
            route: ORGANIZATION_ROOT,
            params: {
                org: org.name
            },
            operation: replace,
        }))
    }
}

export default combineReducers({
    currentUser: currentUserReducer,
});


function selectSlice(state: AppState) {
    return state.account;
}

export const selectCurrentUser = createSelector(
    selectSlice,
    accountState => accountState.currentUser.data ?? null
);

export const selectCurrentUserLoading = createSelector(
    selectSlice,
    accountState => !accountState.currentUser.data && accountState.currentUser.isLoading
)

export const selectOrganizations: Selector<AppState, readonly Organization[] | null> = createSelector(
    (state: AppState) => state,
    selectCurrentUser,
    (state, user) => {
        const data = organizationApi.endpoints.getOrganizationList.select(user ?? skipToken)(state);

        if(data.isLoading) {
            return null;
        }

        return data.data?._embedded?.organizations ?? [];
    }
);

export const selectInvites: Selector<AppState, readonly UserInvite[] | null> = createSelector(
    (state: AppState) => state,
    selectCurrentUser,
    (state, user) =>  {
        const data = organizationApi.endpoints.getUserInvites.select(user ?? skipToken)(state);

        if(data.isLoading) {
            return null;
        }

        return data.data ?? [];
    }
);

export const selectCurrentOrganization: Selector<AppState, Organization | null> = createSelector(
    selectRouterPath,
    selectOrganizations,
    (path, orgs) => {
        const match = ORGANIZATION_ROOT.wildcard.match(path);
        if(!match || !orgs) {
            return null;
        }
        return orgs.filter(org => org.name === match.params.org)[0] ?? null
    }
);
